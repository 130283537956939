import Service from "../Service";
const resource = "/HmnAgreementMovemt/";

export default {
  /* pagination(dtr, requestID) {
    return Service.post(resource + "pagination", dtr, {
      params: { requestID: requestID },
    });
  }, */
  save(obj, requestID) {
    return Service.post(resource + "save", obj, {
      params: { requestID: requestID },
    });
  },

};
