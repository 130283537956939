<template>
  <div>
    <s-toolbar @save="saveVeh()" @close="close()" save close label="Vehículo" />
    <v-card>
      <v-card-title class="primary--text ">Datos Generales</v-card-title>
      <v-card-text>
        <v-container grid-list-xs class="ma-0 pa-0">
          <v-row>
            <v-col cols="4" md="2" class="s-col-form" v-if="typeArea == 2">
              <s-select-definition add :def="1198" v-model="item.TypeVehicleBrand" label="Tipo Veh." />
            </v-col>
            <v-col cols="4" md="2" class="s-col-form">
              <s-select-definition add :def="1131" v-model="item.TypeVehicle" label="Tipo" />
            </v-col>
            <v-col cols="4" md="2" class="s-col-form">
              <s-text v-model="item.VehPlate" label="Placa" @input="validateInput" />
            </v-col>
            <v-col cols="4" md="2" class="s-col-form">
              <s-select-definition add :def="1133" v-model="item.TypeColor" label="Color" />
            </v-col>
            <v-col cols="4" lg="2" class="s-col-form">
              <s-text v-model="item.VehMileage" label="Kilometraje" type="tel" />
            </v-col>
            <v-col cols="4" md="2" class="s-col-form">
              <s-select-definition :def="1136" v-model="item.VehStatus" label="Estado" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" lg="2" class="s-col-form">
              <s-select-definition add :def="1115" v-model="item.TypeBrand" label="Marca" />
            </v-col>
            <v-col cols="4" lg="2" class="s-col-form">
              <s-select-definition add :def="1132" v-model="item.TypeModel" label="Modelo" />
            </v-col>
            <v-col cols="6" lg="2" class="s-col-form">
              <s-text v-model="item.VehMotor" label="Motor" />
            </v-col>
            <v-col cols="6" lg="2" class="s-col-form">
              <s-text v-model="item.VehSerie" label="Serie" />
            </v-col>
            <v-col cols="6" lg="2" class="s-col-form">
              <s-text v-model="item.RegistrationConstancy" label="Constancia de Inscripción N°" />
            </v-col>
            
            <v-col cols="4" md="1" class="s-col-form">
              <s-text label="Año Fabr" type="tel" number v-model="item.VehFabricationYear" :max="4" />
            </v-col>
            <v-col cols="4" md="1" class="s-col-form">
              <s-text v-model="item.VehSeatCapacity" type="tel" number label="Asientos" :max="2" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-title class="primary--text">
        <v-badge :color="colorEnsuredr(item)" dot> SOAT </v-badge>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-xs class="ma-0 pa-0">
          <v-row>
            <v-col cols="4" md="4" lg="3" class="s-col-form">
              <s-select-definition add :def="1134" v-model="item.TypeEnsuredr" label="Aseguradora" clearable />
            </v-col>
            <v-col cols="4" md="3" lg="2" class="s-col-form">
              <s-date label="Inicio" v-model="item.VehEnsuredInit" nullable />
            </v-col>
            <v-col cols="4" md="3" lg="2" class="s-col-form">
              <s-date label="Fin" v-model="item.VehEnsuredEnd" nullable />
            </v-col>
            <v-col cols="4" md="2" lg="2" class="s-col-form">
              <s-text v-model="item.VehEnsuredCost" decimal type="tel" label="Precio S/" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-title class="primary--text">
        <v-badge :color="colorTechnicalReview(item)" dot>
          Revisión Técnica
        </v-badge>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-xs class="ma-0 pa-0">
          <v-row>
            <v-col cols="4" md="2" class="s-col-form">
              <s-date label="Inicio" v-model="item.VehTechnicalReviewInit" nullable />
            </v-col>
            <v-col cols="4" md="2" class="s-col-form">
              <s-date label="Fin" v-model="item.VehTechnicalReviewEnd" nullable />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-title class="primary--text">
        <v-badge :color="colorPolicy(item)" dot> Póliza </v-badge>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-xs class="ma-0 pa-0">
          <v-row>
            <v-col cols="4" md="5" lg="3" class="s-col-form">
              <s-text v-model="item.VehPolicy" label="N° Poliza" />
            </v-col>
            <v-col cols="4" md="3" lg="2" class="s-col-form">
              <s-date label="Inicio" v-model="item.VehPolicyInit" nullable />
            </v-col>
            <v-col cols="4" md="3" lg="2" class="s-col-form">
              <s-date label="Fin" v-model="item.VehPolicyEnd" nullable />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-title class="primary--text">
        <v-badge :color="colorGPS(item)" dot>
          GPS
        </v-badge></v-card-title
      >
      <v-card-text>
        <v-container grid-list-xs class="ma-0 pa-0">
          <v-row>
            <v-col cols="6" md="4" lg="3" class="s-col-form">
              <s-select-definition add :def="1135" v-model="item.TypeGpsProvider" label="Proveedor" clearable />
            </v-col>
            <v-col cols="6" md="4" lg="3" class="s-col-form">
              <s-text v-model="item.VehGpsNumber" label="Número" />
            </v-col>
            <v-col cols="4" md="2" lg="2" class="s-col-form">
              <s-date label="Inicio" v-model="item.VehGpsInit" nullable />
            </v-col>
            <v-col cols="4" md="2" lg="2" class="s-col-form">
              <s-date label="Fin" v-model="item.VehGpsEnd" nullable />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>


      <v-card-title class="primary--text" v-if="typeArea==2">
        <v-badge  dot>
          Datos de Transportista
        </v-badge></v-card-title
      >
      <v-card-text v-if="typeArea==2">
        <v-container grid-list-xs class="ma-0 pa-0">
          <v-row>
            <v-col cols="4" md="4" lg="3" class="s-col-form">
              <s-text v-model="item.VehRuc" number label="Ruc" />
            </v-col>
            <v-col cols="8" md="8" lg="9" class="s-col-form">
              <s-text v-model="item.VehBusinessName" label="Razon Social" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

    </v-card>
  </div>
</template>

<script>
import _sVehicle from "@/services/Management/VehicleService";

export default {
  props: {
    value: {
      required: false,
      default: -1,
    },
    areID:{
      default:0,
      type: Number
    },
    typeArea: {type: Number, default: 1}
  },
  data() {
    return {
      verificationDay: "",
      item: this.defaultVehicle(),
    };
  },
  created() {
    this.verificationDay = this.moment()
      .add(15, "days")
      .format(this.$const.FormatDateMoment);
    this.initialize();
  },
  watch: {
    value() {
      this.initialize();
    },
  },
  computed: {},
  methods: {
    initialize() {
      if (this.value > 0) {
        _sVehicle.get({ VehID: this.value }, this.$fun.getUserID()).then((r) => {    
          this.item = r.data
          console.log("🚀 ~ file: VehicleEdit.vue ~ line 193 ~ _sVehicle.get ~ this.item",  this.item)
          this.returnObject();
        });
      } else this.item = this.defaultVehicle();
    },
    defaultVehicle() {
      return {
        VehID: -1,       
        TypeVehicle: 1,
        VehPlate: "",
        VehMotor: "",
        VehSerie: "",
        //TypeBrand: 1,
        //TypeModel: 1,
        //TypeColor: 1,
        VehFabricationYear: 0,
        VehSeatCapacity: 0,
        TypeEnsuredr: null,
        VehEnsuredInit: null,
        VehEnsuredEnd: null,
        VehEnsuredCost: 0.0,
        VehPolicy: null,
        VehPolicyInit: null,
        VehPolicyEnd: null,
        VehTechnicalReviewInit: null,
        VehTechnicalReviewEnd: null,
        TypeGpsProvider: null,
        VehGpsNumber: null,
        VehGpsInit: null,
        VehGpsEnd: null,
        VehMileage: 0,
        VehStatus: 1,
        SecStatus: 1,
      };
    },
    returnObject() {
      this.$emit("returnObject", this.item);
    },
    close() {
      this.$emit("close");
      this.$emit("input", -1);
      this.item = this.defaultVehicle();
    },
    colorEnsuredr(item) {
      let color = "grey";
      if (item.VehEnsuredEnd != "" && item.VehEnsuredEnd != null) {
        if (this.verificationDay >= item.VehEnsuredEnd) color = "warning";
        else color = "success";
      }

      return color;
    },
    colorPolicy(item) {
      let color = "grey";
      if (item.VehPolicyEnd != "" && item.VehPolicyEnd != null) {
        if (this.verificationDay >= item.VehPolicyEnd) color = "warning";
        else color = "success";
      }

      return color;
    },
    colorTechnicalReview(item) {
      let color = "grey";
      if (item.VehTechnicalReviewEnd != "" && item.VehTechnicalReviewEnd != null) {
        if (this.verificationDay >= item.VehTechnicalReviewEnd) color = "warning";
        else color = "success";
      }

      return color;
    },
    colorGPS(item) {
      let color = "grey";
      if (item.VehGpsEnd != "" && item.VehGpsEnd != null) {
        if (this.verificationDay >= item.VehGpsEnd) color = "warning";
        else color = "success";
      }

      return color;
    },
    validatedVeh(item) {

      let isValid = false;
      let message = "";

      isValid = item.VehPlate.length > 0;
      if (!isValid) message = "Ingrese Placa de vehículo";
      else {
        isValid = item.VehFabricationYear > 0;
        if (!isValid) message = "Ingrese Año de Fabricación";
        else {
          isValid = item.VehSeatCapacity > 0;
          if (!isValid) message = "Ingrese Cantidad de asientos";
          else {
            if (item.TypeEnsuredr > 0) {
              isValid = item.VehEnsuredInit != null && item.VehEnsuredEnd != null;

              if (!isValid) message = "Verifique rango de fecha del SOAT";
              else {
                isValid =
                  item.VehEnsuredInit.length > 0 &&
                  item.VehEnsuredEnd.length > 0 &&
                  this.$fun.isValidRangeDate(item.VehEnsuredInit, item.VehEnsuredEnd);
                if (!isValid) message = "Verifique rango de fecha del SOAT";
              }
            }

            if (isValid) {
              if (item.VehPolicy != null) {
                isValid = item.VehPolicyInit != null && item.VehPolicyEnd != null;
                if (!isValid) message = "Verifique rango de fecha de la POLIZA";
                else {
                  isValid =
                    item.VehPolicyInit.length > 0 &&
                    item.VehPolicyEnd.length > 0 &&
                    this.$fun.isValidRangeDate(item.VehPolicyInit, item.VehPolicyEnd);
                  if (!isValid) message = "Verifique rango de fecha de la POLIZA";
                }
              }
            }
            if (isValid) {
              if (item.VehTechnicalReviewInit != null && item.VehTechnicalReviewEnd != null) {
                isValid =
                  item.VehTechnicalReviewInit.length > 0 &&
                  item.VehTechnicalReviewEnd.length > 0 &&
                  this.$fun.isValidRangeDate(item.VehTechnicalReviewInit, item.VehTechnicalReviewEnd);
                if (!isValid) message = "Verifique rango de fecha de la Revisión Vehicular";
              }
            }
            if (isValid) {
              if (item.TypeGpsProvider != null) {
                isValid = item.VehGpsInit != null && item.VehGpsEnd != null;

                if (!isValid) message = "Verifique rango de fecha del Servicio de GPS";
                else {
                  isValid = item.VehGpsInit.length > 0 && item.VehGpsEnd.length > 0 && this.$fun.isValidRangeDate(item.VehGpsInit, item.VehGpsEnd);
                  if (!isValid) message = "Verifique rango de fecha del Servicio de GPS";
                }
              }
            }
          }
        }
      }

      if (!isValid) this.$fun.alert(message, "warning");

      return isValid;
    },
    saveVeh() {
      //console.log("aasss ", this.item);
      //this.item.AreID=this.areID;
      this.item.TypeArea = this.typeArea

      /* if(this.typeArea == 2){
         if( this.item.VehRuc == null){
           this.$fun.alert("Ingrese numero de Ruc", "warning")
           return
         }

         if( this.item.VehBusinessName== null){
           this.$fun.alert("Ingrese Razon Social", "warning")
           return
         }

         if( this.item.TypeVehicleBrand == null){
           this.$fun.alert("Ingrese Tipo Vehiculo (Canter || Fuso)", "warning")
           return
         }
      } */
      

      if (this.validatedVeh(this.item)) {

        console.log("Gaurdar", this.item);

        this.$fun.alert("¿Seguro de Guardar?", "question").then((val => {
          if(val.value){
            _sVehicle.save(this.item, this.$fun.getUserID()).then((r) => {
              this.$fun.alert("Guardado Correctamente", "success");
              this.$emit("save", r.data);
              this.close();
            });
          }
        }))
        
        
      }
    },
    validateInput(value) {
      if (value.includes(' ')) {
        this.$nextTick(() => {
          this.item.VehPlate = value.replace(/\s/g, '');
        });
      }
    }
  },
};
</script>
