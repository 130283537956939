<template>

    <v-card dense>    
      
      <v-card-title class="text-h7" style="font-weight: bold;">
        <v-row>
          <v-col>
            CUENTA BANCARIA 
          </v-col>
          
        </v-row>
      </v-card-title>
      <v-card-text> 
        <v-row>
          <v-col class="s-col-form" sm="12" md="12" lg="12">
              <v-row>
                <v-col sm="12" md="12" lg="12">
                  <template>
                    <v-data-table
                      :headers="bankAccountHeaders"
                      class="elevation-1"
                      :items="bankAccountDetail"
                      :sort-by="['SecStatus','TypePay','BatID']" 
                      :sort-desc="[true]"
                    >
                      <template v-slot:[`item.BatIsDefault`]="{ item }">                    
                        {{ item.BatIsDefault == 1 ? 'Cuenta Principal' : 'Cuenta Opcional' }}                     
                      </template>
  
                      <template v-slot:[`item.SecStatus`]="{ item }">
                        <v-chip 
                          small 
                          :color="item.SecStatus ? 'success' : ''" 
                          :text-color="item.SecStatus ? 'white' : ''" 
                          >
                          {{ item.SecStatus  ? 'Activo' : 'Inactivo' }}
                        </v-chip>
                      </template>
                    </v-data-table>
                  </template>
                </v-col>
              </v-row>
           
          </v-col>
        </v-row>
       </v-card-text> 
  
      
    </v-card> 
  </template>
  
  <script>
  import _sWorkertService from '@/services/HumanResource/WorkerService.js';
  
  export default {
    name: "sDatosBancarios",
    components: {  },
    props: {
      visibleTitle: {
        type: Boolean,
        default: true,
      },
      full: {
        type: Boolean,
        default: false,
      },
      typeperson: {
        type: Number,
        default: 1,
      },
      addTypeSex: {
        type: Boolean,
        default: false,
      },
      addBirthDate: {
        type: Boolean,
        default: false,
      },
      addDriver: {
        type: Boolean,
        default: false,
      },
      datawork: { type: Object },
    },
    data() {
      return {
        dataWor: {},
        editing: false,
        item: {
          TypePay: 1,
          TypeAccount: 3,
          TypeMoney: 1,
          TypeEntityBank: 2,
          BatAccountNumber: "",
          BatCCI:"",
          BatIsDefault: 1,
          BatStatus:1,
          UsrCreateID: this.$fun.getUserID()
        },
        editedIndex: -1,
        itemDefault: {
          TypePay: 1,
          TypeAccount: 3,
          TypeMoney: 1,
          TypeEntityBank: 2,
          BatAccountNumber: "",
          BatCCI:"",
          BatIsDefault: 1,
          BatStatus: 1,
          UsrCreateID: this.$fun.getUserID()
        },
        title: 1,
        selectedCenterCost: 0,
        NumCorrelative: null,
        selectedBusinessLine: null,
        bankAccountHeaders: [
          { text: "ID"                          , value: "BatID"                  , sortable: false, width: 20  },
          { text: "Tipo de Cuenta"              , value: "TypePayName"            , sortable: false, width: 50  },
          { text: "Moneda"                      , value: "TypeMoneyName"          , sortable: false, width: 20  },
          { text: "Entidad Bancaria"            , value: "TypeEntityBankName"     , sortable: false, width: 30  },
          { text: "N° de Cuenta"                , value: "BatAccountNumber"       , sortable: false, width: 100 },
          { text: "N° de Cuenta Interbancaria"  , value: "BatCCI"                 , sortable: false, width: 100 },
          { text: "Categoria"                   , value: "BatIsDefault"           , sortable: false, width: 80  },
          { text: "Situación de la Cuenta"      , value: "BatStatusName"          , sortable: false, width: 80  },
          { text: "Estado"                      , value: "SecStatus"              , sortable: false, width: 30  },
        ],
        bankAccountDetail: [],
        bankAccountDetailDelete: [],
        menu3: false,
        config: {},
        dialog: false,
        maxlength: 14,
      };
    },
    methods: {
      initialize() {
  
        //this.bankAccountDetail = this.datawork.BankAccountsCE;
  
        this.datawork.BankAccountsCE.forEach(element => {
          element.UsrUpdateID = this.$fun.getUserID(),
          this.bankAccountDetail.push(element);
        });
  
  
       //console.log("Cuentas Banccarias", this.bankAccountDetail);
      },
      deleteItem(item) {
        //console.log("deleteItem", item);
        
        if(item.BatID!=0){
          item.SecStatus = 0;
          item.BatIsDefault=2;
          item.UsrUpdateID = this.$fun.getUserID();
          //this.bankAccountDetailDelete.push(item);
          //console.log("items", this.bankAccountDetailDelete);
        }else{
          this.editedIndex = this.bankAccountDetail.indexOf(item);
          this.bankAccountDetail.splice(this.editedIndex, 1);
        }
        this.editedIndex = -1;
        //console.log("Items",this.bankAccountDetail);
      },
  
      editItem(item) {
        //console.log("Direcciones", item);
        this.isInitializing = true;
        this.editedIndex = this.bankAccountDetail.indexOf(item);
      
        this.item = {
          ...item
        };
        this.item.BatAccountNumber= item.BatAccountNumber;
        this.title = 0;     
        //console.log("Edit", this.editedIndex);
      },
  
      cancelItemDetail(){
        this.editedIndex = -1;   
        this.title= -1 ;
        this.item = {
          ...this.itemDefault
        }
        this.clearDetail();
      },
  
      addItemDetail() {
        //console.log("Editindex", this.editedIndex);
        //console.log("cuenta", this.item);
        
        if(!this.validateData()) return;
  
        let bankAccount = {
          BatID: this.item.BatID || 0,
          WkrID: this.datawork.WkrID,
          TypePay: this.item.TypePay,
          TypePayName: this.$refs.TypePayRef.items.find(
            (x) => x.DedValue === this.item.TypePay
          ).DedDescription,
          TypeMoney: this.item.TypeMoney,
          TypeMoneyName: this.$refs.TypeMoneyRef.items.find(
            (x) => x.DedValue === this.item.TypeMoney
          ).DedDescription,
          TypeEntityBank: this.item.TypeEntityBank,
          TypeEntityBankName: this.$refs.TypeEntityBankRef.items.find(
            (x) => x.DedValue === this.item.TypeEntityBank
          ).DedDescription,
          BatAccountNumber: this.item.BatAccountNumber.replace(/\s+/g, ""),
          BatCCI: this.item.BatCCI.replace(/\s+/g, ""),
          BatIsDefault: this.item.TypePay == 1 ? 1 : 2, //this.item.BatIsDefault,
          BatStatus: this.item.BatStatus,
          BatStatusName: this.$refs.BatStatusRef.items.find(
            (x) => x.DedValue === this.item.BatStatus
          ).DedDescription,
          SecStatus: 1,
          UsrCreateID: this.item.BatID ? this.item.UsrCreateID :this.$fun.getUserID(),
          UsrUpdateID: this.item.BatID ? this.$fun.getUserID() : 0,
  
        };
  
        const itemBankAccount = this.bankAccountDetail.find(element => element.TypePay === this.item.TypePay && element.SecStatus ==1 && this.bankAccountDetail.indexOf(element) != this.editedIndex)
  
        if(itemBankAccount){
          let indexItemBankAccount = this.bankAccountDetail.indexOf(itemBankAccount); 
  
          if(itemBankAccount.BatID > 0){
            itemBankAccount.SecStatus = 0;
            itemBankAccount.BatIsDefault = 2;
            //Object.assign(this.bankAccountDetail[indexItemBankAccount], itemBankAccount);
          }else{               
            this.bankAccountDetail.splice(indexItemBankAccount, 1);                 
          }
        }
  
        if (this.editedIndex > -1) {
          Object.assign(this.bankAccountDetail[this.editedIndex], bankAccount);
          this.editedIndex = -1;
        } else {       
          this.bankAccountDetail.push(bankAccount);        
        }
        //console.log("Itemss",this.bankAccountDetail);
        this.title = -1;  
        this.$refs.TypePayRef.focus();
        this.clearDetail();
      },
  
      clearDetail() {
        //console.log("Item default111", this.itemDefault);
        this.item = { ...this.itemDefault}
  
        this.NumCorrelative= null;
      
        this.$refs.BatAccountNumberRef.touched=false;
        this.$refs.BatCCIRef.touched=false;
        this.$refs.BatCCIRef.hasError=false;
        this.$refs.BatCCIRef.errorMessage="";
        //console.log("Item default2222", this.item);
        
      },
  
      close() {
        this.$refs.document.focus();
        this.$emit("toclose", 2);
        this.clear();
      },
      save() {
      },
      getData() {
        if (this.validateDetail()) {
          return [
              ...this.bankAccountDetailDelete,
              ...this.bankAccountDetail            
            ];
        }
        else{ return false; }
      },
  
      validateDetail(){
        let valid = this.bankAccountDetail.length > 0;
        
        if(!valid){  this.$fun.alert("Al menos una Cuenta Bancaria es obligatorio", "warning"); return valid;}
  
        valid = this.bankAccountDetail.some(element => element.BatIsDefault == 1);
  
        if(!valid){  this.$fun.alert("Una Cuenta Bancaria Principal es obligatorio", "warning"); }
  
        return valid;
      },
  
      validateData() {
        let valid = true;
        //console.log("Data Bancarios", this.item);
  
        this.item.BatAccountNumber = this.item.BatAccountNumber.replace(/\s+/g, "");
  
        if (this.item.BatAccountNumber.length === 0 || this.item.BatAccountNumber.length < this.maxlength) {
          //this.maxlength =  this.item.TypeAccount == 4 ? 20 :  this.maxlength;
          let message ="El N° de Cuenta debe de tener  " + (this.maxlength)   + " dígitos"; 
          this.$fun.alert(message, "warning");
          valid = false;
        }
  
        if(this.item.BatCCI.length>0 && this.item.BatCCI.length < 20){
          this.$fun.alert("El CCI debe de tener  20 dígitos", "warning");
          valid = false;
        }
  
      
        return valid;
      },
  
    },
    watch: {
      'item.TypeEntityBank'() {
        // console.log("entidad111111", this.$refs.TypeEntityBankRef.items.find(
        //     (x) => x.DedValue === this.item.TypeEntityBank
        //   ));
        this.maxlength = parseInt(this.$refs.TypeEntityBankRef.items.find(
            (x) => x.DedValue === this.item.TypeEntityBank
          ).DedHelper);
      },
      'NumCorrelative'(){
       // console.log("entidad1111");
        if(this.NumCorrelative){
          _sWorkertService.BankAccountCorrelative(this.$fun.getUserID()).then(r=>{
            if (r.status == 200) {
              //console.log("NumCorrelative", r.data);
              this.item.BatAccountNumber= r.data;        
            }
          })
         
        }else{
          this.item.BatAccountNumber= "";        
        }
      }
    },
    created() {
      if (this.datawork) {
          this.initialize();
      } else {
          console.warn("datawork aún no está definido. Esperando su asignación.");
      }
    },
    computed: {
    }
  };
  </script>
  