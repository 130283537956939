let children = [];
//------------ ADMINISTRACION -------------------------
children.push({
    path: "/sistemas/inventario",
    name: "TIC_INVENTORY",
    component: () =>
        import ("@/views/InformationTechnology/Inventory/Inventory.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Sistemas", disabled: true },
            { text: "Inventario", href: "/sistemas/inventario" },
        ],
    },
});
children.push({
    path: "/sistemas/asignacion",
    name: "TIC_ASSIGNAMENT",
    component: () =>
        import ("@/views/InformationTechnology/AssignamentInventory/AssignamentNew.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Sistemas", disabled: true },
            { text: "Asignación Equipos", href: "/sistemas/asignacion" },
        ],
    },
});

children.push({
    path: "/sistemas/equipment",
    name: "TIC_EQUIPMENT",
    component: () =>
        import ("@/views/InformationTechnology/Equipment/Equipment.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Sistemas", disabled: true },
            { text: "Equipos TI", href: "/sistemas/equipment" },
        ],
    },
});

children.push({
    path: "/sistemas/componentes",
    name: "TIC_COMPONET",
    component: () =>
        import ("@/views/InformationTechnology/Inventory/Component.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Sistemas", disabled: true },
            { text: "Componentes", href: "/sistemas/componentes" },
        ],
    },
});
children.push({
    path: "/sistemas/licencias",
    name: "TIC_LICENSE",
    component: () =>
        import ("@/views/InformationTechnology/Inventory/License.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Sistemas", disabled: true },
            { text: "Licencias", href: "/sistemas/licencias" },
        ],
    },
});
children.push({
    path: "/sistemas/configuracion",
    name: "TIC_CONFIGURATION",
    component: () =>
        import ("@/views/InformationTechnology/Configuration/RequestAdministration.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Sistemas", disabled: true },
            { text: "Configuracion", href: "/sistemas/configuracion" },
        ],
    },
});
children.push({
    path: "/sistemas/tickets",
    name: "TIC_TICKET",
    component: () =>
        import ("@/views/InformationTechnology/Management/Soporte.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Sistemas", disabled: true },
            { text: "Tickets", href: "/sistemas/tickets" },
        ],
    },
});

children.push({
    path: "/sistemas/scannerequipmentassignment",
    name: "TIC_SCANNER_EQUIPMENT_ASSIGNMENT",
    component: () =>
        import ("@/views/InformationTechnology/ScannerEquipmentAssignment/ScannerEquipmentAssignment.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Sistemas", disabled: true },
            { text: "Scanner Asignación Equipos", href: "/sistemas/scannerequipmentassignment" },
        ],
    },
});


children.push({
    path: "/sistemas/equipmentassignment",
    name: "ITE_EQUIPMENT_ASSIGNMENT",
    component: () =>
        import ("@/views/InformationTechnology/EquipmentAssignment/EquipmentAssignment.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Sistemas", disabled: true },
            { text: "Scanner Asignación Equipos", href: "/sistemas/equipmentassignment" },
        ],
    },
});

children.push({
    path: "/sistemas/helpdesk",
    name: "ITE_HELP_DESK",
    component: () =>
        import ("@/views/InformationTechnology/HelpDesk/HelpDesk.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Sistemas", disabled: true },
            { text: "Help Desk", href: "/sistemas/helpdesk" },
        ],
    },
});

children.push({
    path: "/sistemas/tareas",
    name: "TIC_TASK",
    component: () =>
        import ("@/views/InformationTechnology/Task/Task.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "TI", disabled: true },
            { text: "Recordatorios", href: "/sistemas/tareas" },
        ],
    },
});

children.push({
    path: "/sistemas/tareasProyect",
    name: "TIC_TASKPROYECT",
    component: () =>
        import ("@/views/InformationTechnology/TaskProyect/TaskProyect.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "TI", disabled: true },
            { text: "Recordatorios", href: "/" },
        ],
    },
});

// configuracion marca de componente David Domador
children.push({
    path: "/sistemas/mantenimiento/marca_componente",
    name: "TIC_COMPONENTS_BRAND",
    component: () =>
        import ("@/views/InformationTechnology/Maintenance/ComponentsBrands.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Sistemas", disabled: true },
            { text: "Marca de componente", href: "/sistemas/mantenimiento/marca_componente" },
        ],
    },
});


children.push({
    path: "/sistemas/controltareas",
    name: "TIC_TASK_CONTROL",
    component: () =>
        import ("@/views/InformationTechnology/ControlTask/ControlTask.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Sistemas", disabled: true },
            { text: "Control Tareas", href: "/sistemas/controltareas" },
        ],
    },
});
children.push({
    path: "/sistemas/SunDrive",
    name: "SNFILEDRVE",
    component: () =>
        import ("@/views/InformationTechnology/SunDrive/sunDrive.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Sistemas", disabled: true },
            { text: "SunDrive", href: "/sistemas/SunDrive" },
        ],
    },
});

export { children };