let children = []

children.push({
    path: "/contabilidad/facturasxml",
    name: "CNT_INVOICES_XML",
    component: () =>
        import ("../views/Accounting/InvoicesXML/InvoicesXML.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Contabilidad", disabled: true },
            {
                text: "Facturas XML",
                href: "/contabilidad/facturasxml",
            },
        ],
    },
});

children.push({
    path: "/contabilidad/Presupuesto",
    name: "BUDGETCNT",
    component: () =>
        import ("../views/Accounting/Budget.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Contabilidad", disabled: true },
            {
                text: "Facturas XML",
                href: "/contabilidad/Presupuesto",
            },
        ],
    },
});

export { children }