<template>
  <v-card elevation="0">
    <s-toolbar
      v-if="visibleTitle"
      label="Persona"
      color="#BAB6B5"
      close
      @close="close()"
      :save="TypeAdmonitionName == '' ? true : false"
      @save="save()"
      style="color: white"
    />
    <v-container style="margin: auto">
      <v-row style="margin-left: 5px" justify="center">
        <v-col cols="6" sm="3" md="3" lg="3">
          <s-select-definition
            :def="1036"
            label="Tipo Documento"
            v-model="TypePersonDocument"
            returnObject
            @change="Limit()"
            :add="$fun.isAdmin()"
          />
        </v-col>
        <v-col cols="6" sm="3" md="3" lg="3">
          <s-text
            @keyupEnter="txtDocument()"
            @input="inputDocument($event)"
            label="Documento*"
            number
            :rules="[
              (v) => !!v || 'El campo es obligatorio.',
              (v) =>
                v.length >= TypePersonDocument.DedHelper2 ||
                'Debe tener al menos ' +
                  TypePersonDocument.DedHelper2 +
                  ' caracteres',
            ]"
            ref="document"
            :max="parseInt(TypePersonDocument.DedHelper) || 99999999"
            :maxlength="parseInt(TypePersonDocument.DedHelper2) || 8"
            v-model="person.GenPerson.PrsDocumentNumber"
            :counter="parseInt(TypePersonDocument.DedHelper2) || 8"
            :showCounter="true"
            :readonly="TypeAdmonitionName == '' ? false : true"
          />          
        </v-col>
        <v-col cols="6" sm="6" md="6" lg="6" v-if="typeperson == 1">
          <s-text
            ref="textNtpName"
            label="Nombre*"
            :rules="[
              (v) => !!v || 'El campo es obligatorio.',
              (v) => v.length >= 3 || 'Debe tener al menos 3 caracteres',
            ]"
            v-model.trim="person.NtpName"
            @input="handleInput($event)"
            :autofocus="person.GenPerson.PrsDocumentNumber.length > 0"
            :uppercase="true"
          />
        </v-col>
        <v-col cols="6" sm="6" md="6" lg="6" v-if="typeperson == 2">
          <s-text
            ref="textJrpName"
            label="Nombre Juridico*"
            v-model="person.JrpName"
            :autofocus="person.GenPerson.PrsDocumentNumber.length > 0"
          />
        </v-col>
      </v-row>
      <v-row v-if="TypeAdmonitionName != ''">
        <v-col cols="12" sm="12" md="12" lg="12">
          <div style="color: red; text-align: center">
            PERSONAL RESTRINGIDO            
          </div>
        </v-col>
      </v-row>

      <v-row style="margin-left: 5px" justify="center" v-if="typeperson == 1">
        <v-col cols="6" sm="6" md="6" lg="6">
          <s-text
            label="Apellido Paterno*"
            :rules="[
              (v) => !!v || 'El campo es obligatorio.',
              (v) => v.length >= 3 || 'Debe tener al menos 3 caracteres',
            ]"
            v-model.trim="person.NtpPaternalSurname"
            @input="handleInputPat($event)"
            :uppercase="true"
          />
        </v-col>
        <v-col cols="6" sm="6" md="6" lg="6">
          <s-text
            label="Apellido Materno*"
            :rules="[
              (v) => !!v || 'El campo es obligatorio.',
              (v) => v.length >= 3 || 'Debe tener al menos 3 caracteres',
            ]"
            v-model.trim="person.NtpMaternalSurname"
            @input="handleInputMat($event)"
            :uppercase="true"
          />
        </v-col>
      </v-row>

      <v-row style="margin-left: 5px" justify="center">
        <v-col cols="4" sm="6" lg="4">
          <s-date v-model="person.NtpBirthDate" label="Fecha Nacimiento" />
        </v-col>
        <v-col cols="4" sm="6" lg="4">
          <s-select-definition
            :def="1037"
            label="Género"
            v-model="person.TypeSex"
            :add="$fun.isAdmin()"
          />
        </v-col>
        <v-col cols="4" sm="6" lg="4">
          <s-select-definition
            :def="1502"
            label="Nacionalidad"
            v-model="person.GenPerson.NatID"
            :add="$fun.isAdmin()"
          />          
        </v-col>
      </v-row>
      <v-row style="margin-left: 5px" justify="start">
        <v-col cols="4" sm="4" lg="4">
          <s-switch
            label="Discapacitado(a)"
            v-model="person.NtpDisability"
          ></s-switch>          
        </v-col>        
        <v-col cols="3" sm="3" lg="6" v-if="person.NtpDisability">
          <v-file-input
            accept=".pdf,.jpg,.jpeg,.png"
            label="Anexo"
            v-model="Attach"
            hide-details
            :rules="[
              // (v) => !!v || 'El campo es obligatorio',
              (v) => (v && v.size < 3000000) || 'El archivo debe ser menor a 3MB',
              (v) => (v && /\.(jpg|jpeg|png|pdf)$/i.test(v.name)) || 'El archivo debe ser una imagen o PDF'
            ]"
            @change="validateFile()"
          />
        </v-col>
        <!-- <v-col cols="1" sm="1" lg="2" v-if="person.NtpDisability">
          <v-btn
            color="success"
            class="mt-5"
            :disabled="false"
            icon
            x-small
            @click="openFile(Attach)"
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </v-col> -->
      </v-row>
      <v-row style="margin-left: 5px" justify="start">
        <v-col cols="4" sm="6" lg="4">
          <s-switch
            label="Sindicalizado(a)"
            v-model="person.NtpSyndical"
          ></s-switch>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="12" lg="12">
          <v-tabs v-model="currentItem" centered>
            <v-tab href="#Address"> Direcciones </v-tab>
            <v-tab href="#TelephoneEmail"> Teléfonos y Correos </v-tab>
            <v-tab href="#Driver"> Brevete </v-tab>
            <v-tab href="#Photo"> Foto </v-tab>
            <v-tab href="#Fingerprint"> Huella </v-tab>
          </v-tabs>

          <v-tabs-items v-model="currentItem">
            <v-tab-item :value="'Address'">              
              <v-col cols="12" sm="12" lg="12">
                <v-card dense>
                  <v-card-title class="text-h7" style="font-weight: bold">
                    <v-row>
                      <v-col>
                        {{ CancelEditarA ? "Editar" : "Nueva" }} Dirección
                      </v-col>
                      <div style="text-align: end" v-if="CancelEditarA">
                        <v-btn
                          class="mx-2"
                          fab
                          dark
                          small
                          @click="cancelItemDetail(0)"
                          color="error"
                        >
                          <v-icon dark> mdi-close </v-icon>
                        </v-btn>
                      </div>
                      <div style="text-align: end">
                        <v-btn
                          class="mx-2"
                          fab
                          dark
                          small
                          @click="addItemDetail(0)"
                          color="success"
                        >
                          <v-icon dark> mdi-plus </v-icon>
                        </v-btn>
                      </div>
                    </v-row>
                  </v-card-title>
                  <v-card-text>
                    <v-row style="margin-left: 5px" justify="center">
                      <v-col cols="3" sm="6" lg="3">
                        <s-select-generic
                          label="Departamento "
                          itemvalue="GenHelper"
                          v-model="DepartmentUbigeo"
                          autocomplete
                          full
                          :config="configDepartment"
                        />
                      </v-col>
                      <v-col cols="3" sm="6" lg="3">
                        <s-select-generic
                          label="Provincia"
                          itemvalue="GenHelper"
                          v-model="ProvinceUbigeo"
                          autocomplete
                          full
                          :config="configProvince"
                        />
                      </v-col>
                      <v-col cols="3" sm="6" lg="3">
                        <s-select-generic
                          itemvalue="GenHelper"
                          v-model="DistrictUbigeo"
                          label="Distrito"
                          autocomplete
                          @updateData="updateDataDistrict()"
                          full
                          :config="configDistrict"
                        />
                      </v-col>
                      <v-col cols="3" sm="6" lg="3">                        
                        <s-select-definition
                          :def="1513"
                          label="Zona"
                          v-model="Zone"
                          ref="ZoneRef"
                          clearable
                          :add="$fun.isAdmin()"
                        />
                      </v-col>
                    </v-row>
                    <v-row style="margin-left: 5px" justify="center">
                      <v-col cols="4" sm="6" lg="4">
                        <s-select-definition
                          :def="1512"
                          label="Tipo de Dirección"
                          v-model="TypeAddress"
                          ref="TypeAddressRef"
                          :add="$fun.isAdmin()"
                        />
                      </v-col>
                      <v-col cols="4" sm="6" lg="4">
                        <s-text
                          v-model="address.PadAddress"
                          label=" Dirección"
                        />
                      </v-col>
                      <v-col cols="4" sm="6" lg="4">
                        <s-text
                          v-model="address.PadAddressReference"
                          label="Referencia"
                        />
                      </v-col>
                    </v-row>
                    <v-row style="margin-left: 5px" justify="center">
                      <v-col cols="12" sm="12" lg="12">
                        <template>
                          <v-data-table
                            :headers="addressHeaders"
                            class="elevation-1"
                            :items="addressDetail"
                          >
                            <template v-slot:[`item.PadIsDefault`]="{ item }">
                              {{ item.PadIsDefault ? "Principal" : "Opcional" }}                              
                            </template>

                            <template v-slot:[`item.SecStatus`]="{ item }">
                              <v-chip
                                small
                                :color="item.SecStatus ? 'success' : ''"
                                :text-color="item.SecStatus ? 'white' : ''"
                              >
                                {{ item.SecStatus ? "Activo" : "Inactivo" }}
                              </v-chip>
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                              <v-icon
                                small
                                class="mr-2"
                                @click="editItem(item, 0)"
                              >
                                mdi-pencil
                              </v-icon>
                              <v-icon small @click="deleteItem(item, 0)">
                                mdi-delete
                              </v-icon>
                            </template>
                          </v-data-table>
                        </template>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>              
            </v-tab-item>

            <v-tab-item :value="'TelephoneEmail'">
              <v-col cols="12" sm="12" lg="12">
                <v-row style="" justify="center">
                  <v-col cols="6" sm="6" lg="5">
                    <v-card dense>
                      <v-card-title class="text-h7" style="font-weight: bold">
                        <v-row>                         
                          <v-col> Teléfonos </v-col>                          
                        </v-row>
                      </v-card-title>
                      <v-card-text>                        
                        <v-row style="margin-left: 5px; margin: auto">
                          <v-col class="s-col-form" cols="6" sm="6" lg="6">
                            <s-select-definition
                              :def="1111"
                              label="Tipo de Teléfono"
                              v-model="phone.TypePhone"
                              ref="TypePhoneRef"
                              :add="$fun.isAdmin()"
                            />
                          </v-col>
                          <v-col class="s-col-form" cols="6" sm="6" lg="6">
                            <s-text
                              v-model="phone.PpnNumberPhone"
                              type="tel"
                              number
                              label="Teléfono Proncipal"
                              :maxlength="maxlengthPhone1"
                              :counter="maxlengthPhone1 || 9"
                              :showCounter="true"
                            />
                          </v-col>
                        </v-row>
                        <v-row style="margin-left: 5px; margin: auto">
                          <v-col class="s-col-form" cols="6" sm="6" lg="6">
                            <s-select-definition
                              :def="1111"
                              label="Tipo de Teléfono"
                              v-model="phone2.TypePhone"
                              ref="TypePhoneRef2"
                              :add="$fun.isAdmin()"
                            />
                          </v-col>
                          <v-col class="s-col-form" cols="6" sm="6" lg="6">
                            <s-text
                              v-model="phone2.PpnNumberPhone"
                              type="tel"
                              number
                              label="Teléfono Secundario"
                              :maxlength="maxlengthPhone2"
                              :counter="maxlengthPhone2 || 9"
                              :showCounter="true"
                            />
                          </v-col>
                        </v-row>                        
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="6" sm="6" lg="7">
                    <v-card dense>
                      <v-card-title class="text-h7" style="font-weight: bold">
                        <v-row>
                          <v-col>                            
                            Correos
                          </v-col>                          
                        </v-row>
                      </v-card-title>
                      <v-card-text>                       
                        <v-row style="margin-left: 5px; margin: auto">
                          <v-col class="s-col-form" cols="6" sm="6" lg="6">
                            <s-text
                              label="Correo Principal"
                              v-model="email.PemEmail"
                              :placeholder="'ejemplo'"
                              @keyupEnter="addItemDetail(2)"
                            />
                          </v-col>
                          <v-col class="s-col-form" cols="6" sm="6" lg="6">
                            <s-select-definition
                              :def="1518"
                              label="Dominio de Correo"
                              v-model="email.TypeDomainEmail"
                              ref="TypeDomainEmailRef"
                              :add="$fun.isAdmin()"
                            />
                          </v-col>
                        </v-row>

                        <v-row style="margin-left: 5px; margin: auto">
                          <v-col class="s-col-form" cols="6" sm="6" lg="6">
                            <s-text
                              label="Correo Corporativo"
                              v-model="email2.PemEmail"
                              :placeholder="'ejemplo'"
                              @keyupEnter="addItemDetail(2)"
                            />
                          </v-col>
                          <v-col class="s-col-form" cols="6" sm="6" lg="6">
                            <s-select-definition
                              :def="1518"
                              label="Dominio de Correo"
                              v-model="email2.TypeDomainEmail"
                              ref="TypeDomainEmailRef2"
                              :add="$fun.isAdmin()"
                            />
                          </v-col>
                        </v-row>                        
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-tab-item>
            <v-tab-item :value="'Driver'">              
              <v-col cols="12" sm="12" lg="12">
                <v-card dense>
                  <v-card-title class="text-h7" style="font-weight: bold">
                    Brevete
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="4" sm="6" lg="3">
                        <s-text
                          v-model="driver.NpdDocumentNumber"
                          label="Brevete"
                        />
                      </v-col>
                      <v-col cols="4" sm="6" lg="3">
                        <s-select-definition
                          :def="1139"
                          label="Categoría"
                          v-model="driver.TypeDocumentCategory"
                          :add="$fun.isAdmin()"
                        />
                      </v-col>
                      <v-col cols="4" sm="6" lg="3">
                        <s-date
                          v-model="driver.NpdDocumentInit"
                          label="Emisión"
                        />
                      </v-col>
                      <v-col cols="4" sm="6" lg="3">
                        <s-date
                          v-model="driver.NpdDocumentEnd"
                          label="Revalidación"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>            
            </v-tab-item>
            <v-tab-item :value="'Photo'">
              <v-col cols="12" sm="12" lg="12">
                <v-card dense>
                  <v-card-title class="text-h7" style="font-weight: bold">
                    Foto
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col md="4" sm="12" lg="6">
                        <v-img
                          style="border-radius: 10px"
                          height="300"
                          width="300"
                          :src="
                            image ? image : '../../../public/static/User.png'
                          "
                        />
                        <v-btn color="primary" @click="openCamera" class="mt-4"
                          >Tomar Foto</v-btn
                        >
                        <v-btn
                          color="success"
                          @click="capturePhoto"
                          class="mt-4"
                          :disabled="!isCameraActive"
                          >Capturar Foto</v-btn
                        >
                      </v-col>
                      <v-col md="4" sm="12" lg="6">
                        <!-- Elemento de video oculto para acceder a la cámara -->
                        <video
                          ref="video"
                          style="width: 100%; height: 300px"
                          autoplay
                        ></video>
                        <!-- Elemento de canvas para tomar la foto -->
                        <canvas ref="canvas" style="display: none"></canvas>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-tab-item>

            <v-tab-item :value="'Fingerprint'">
                <v-col cols="12" sm="12" lg="12">
                  <v-card dense>
                    <v-card-title class="text-h7" style="font-weight: bold">
                      <v-row justify="center">
                        <v-col cols="6" class="d-flex justify-center">
                          <span>IZQUIERDA</span>
                        </v-col>
                        <v-col cols="6" class="d-flex justify-center">
                          <span>DERECHA</span>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-menu
                      v-model="showContextMenu"
                      :position-x="menuX"
                      :position-y="menuY"
                      absolute
                      offset-y
                    >
                      <v-list dense>
                        <v-list-item 
                          @click="deleteFingerprint" 
                          :disabled="!selectedFingerActive"
                        >
                          <v-list-item-title>Eliminar huella</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-card-text>
                      <v-row justify="center">
                        <v-col cols="12" class="d-flex justify-center">
                          <!-- Imagen de las manos con contenedor relative -->
                          <div
                            style="position: relative; display: inline-block"
                          >
                            <v-img
                              lazy-src="@/assets/hands1.png"
                              max-height="400"
                              max-width="700"
                              src="@/assets/hands1.png"
                              class="d-flex justify-center align-center"
                            />

                            <div
                              v-for="(finger, index) in fingers"
                              :key="index"
                              class="finger"
                              :style="{
                                position: 'absolute',
                                top: finger.position.top,
                                left: finger.position.left,
                                backgroundColor:
                                  selectedFinger === index
                                    ? 'orange'
                                    : finger.isActive == true
                                    ? '#53f773'
                                    : 'transparent',
                                cursor: 'pointer',
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontWeight: 'bold',
                                color: 'black',
                                transition: 'background-color 0.3s',
                              }"
                              @mouseover="highlightFinger(index)"
                              @mouseleave="resetHighlight"
                              @click="selectFinger(index)"
                              @contextmenu.prevent="handleRightClick($event, index)"
                            >
                              {{ index + 1 }}
                              <!-- Número de la huella -->
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <v-dialog v-model="showModal" max-width="300px" persistent>
        <v-card>
          <v-card-title
            class="d-flex justify-center"
            style="background-color: #fadf9b"
          >
            <span class="headline">{{
              statusOpen ? "Captura Huella" : "Huella Capturada"
            }}</span>
          </v-card-title>
          <v-card-text style="margin-top: 5px">
            <div class="d-flex justify-center">
              <!-- Icono de huella con animación de escaneo -->
              <div class="fingerprint-icon-container">
                <v-img
                  src="@/assets/FingrPrint.png"
                  alt="Fingerprint Icon"
                  class="fingerprint-icon"
                  max-height="150"
                  max-width="150"
                  v-if="scanning"
                  :style="{
                    backgroundColor: statusOpen
                      ? '#ffcec7'
                      : captured
                      ? '#d5fb9f'
                      : 'transparent',
                  }"
                >
                  <v-progress-linear
                    v-if="scanning"
                    indeterminate
                    color="red"
                    class="scanning-line"
                    :style="{ height: '10px' }"
                  />
                </v-img>
                <v-progress-linear
                  v-if="scanning"
                  indeterminate
                  color="red"
                  class="scanning-line"
                  :style="{ height: '10px' }"
                />
                <img :src="capturedFingerprint" v-if="captured" alt="Huella Dactilar" />
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn color="" @click="CloseFootprint" v-if="!captured">
              Cerrar
            </v-btn>
            
            <v-btn
              v-if="captured"
              small
              color=""
              class="mr-2"
              @click="SaveFingerprint"
            >
              <v-icon small>mdi-content-save</v-icon>
              <span class="ml-1">Guardar</span>
            </v-btn>
            <v-btn
              v-if="captured"
              small
              color=""
              @click="RetakeFingerprint"
            >
              <v-icon small>mdi-refresh</v-icon>
              <span class="ml-1">Reintentar</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-card>
</template>
  
  <script>
import Service from "@/services/General/PersonService";
import _sGeneric from "@/services/General/GenericService";
import _sContracthmRs from "@/services/HumanResource/HmnContractService";
import SText from "@/components/Utils/SText.vue";
import _sAdmonition from "@/services/HumanResource/AdmonitionService";
import _service from "@/services/HelperService";
import _sGenUploadFiles from "@/services/General/GenUploadFiles";
import _sGeneral from "@/services/HelperService.js";

export default {
  name: "ContractPersonAdd",
  components: { SText },
  props: {
    propPerson: {
      type: Object,
      default: null,
    },
    visibleTitle: {
      type: Boolean,
      default: true,
    },
    full: {
      type: Boolean,
      default: false,
    },
    typeperson: {
      type: Number,
      default: 1,
    },
    addTypeSex: {
      type: Boolean,
      default: false,
    },
    addBirthDate: {
      type: Boolean,
      default: false,
    },
    addDriver: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: false, // Controla si el modal está visible
      scanning: false, // Bandera para saber si estamos en el proceso de escaneo
      captured: false, // Bandera para saber si la huella ha sido capturada correctamente
      editing: false,
      configNationality: null,
      statusOpen: false,
      configZones: null,
      TypeAddress: null,
      ipMatch: null,
      configDepartment: null,
      DepartmentUbigeo: "25",
      ProvinceUbigeo: "00",
      DistrictUbigeo: "",
      item: 0,
      dataFingerL: {},
      Zone: 4,
      editedIndexPhone: -1,
      editedIndexEmail: -1,
      editedIndexAddress: -1,
      currentTab: 0,
      currentItem: "tab-Funciones",
      isCameraActive: false, // Para saber si la cámara está activa
      itemsNationality: [],
      CancelEditarT: false,
      CancelEditarE: false,
      CancelEditarA: false,
      image: null,
      menu3: false,
      TypeAdmonitionName: "",
      config: {},
      dialog: false,
      capturedFingerprint: null,
      capturedFingerTemplate: null,
      dataIndexPrint: null,
      capturedFingerprints: Array(10).fill(null),
      showContextMenu: false,
      menuX: 0,
      menuY: 0,
      selectedFingerActive: false,
      selectedFingerDelete: null,
      validEmail:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      fingers: [
        {
          id: 0,
          position: { top: "17%", left: "13%" },
          style: {},
          isActive: false,
        }, // Pulgar izquierdo
        {
          id: 1,
          position: { top: "10%", left: "18%" },
          style: {},
          isActive: false,
        }, // Índice izquierdo
        {
          id: 2,
          position: { top: "8%", left: "23%" },
          style: {},
          isActive: false,
        }, // Medio izquierdo
        {
          id: 3,
          position: { top: "10%", left: "29%" },
          style: {},
          isActive: false,
        }, // Anular izquierdo
        {
          id: 4,
          position: { top: "39%", left: "42%" },
          style: {},
          isActive: false,
        }, // Meñique izquierdo
        {
          id: 5,
          position: { top: "39%", left: "52%" },
          style: {},
          isActive: false,
        }, // Pulgar derecho
        {
          id: 6,
          position: { top: "10%", left: "65%" },
          style: {},
          isActive: false,
        }, // Índice derecho
        {
          id: 7,
          position: { top: "8%", left: "70%" },
          style: {},
          isActive: false,
        }, // Medio derecho
        {
          id: 8,
          position: { top: "10%", left: "76%" },
          style: {},
          isActive: false,
        }, // Anular derecho
        {
          id: 9,
          position: { top: "17%", left: "81%" },
          style: {},
          isActive: false,
        }, // Meñique derecho
      ],
      selectedFinger: null,
      address: {
        PadCodeUbigeo: "",
        PadAddress: "",
        PadAddressReference: "",
        PadDepartment: "",
        ZonID: 1,
        PadIsDefault: 1,
        TypeAddress: 1,
      },
      addressDefault: {
        PadCodeUbigeo: "",
        PadAddress: "",
        PadAddressReference: "",
        PadDepartment: "",
        ZonID: 1,
        PadIsDefault: 1,
        TypeAddress: 1,
      },
      phone: {
        TypePhone: 1,
        PpnNumberPhone: "",
        PpnIsDefault: 1,
        UsrCreateID: this.$fun.getUserID(),
      },
      phone2: {
        TypePhone: 1,
        PpnNumberPhone: "",
        PpnIsDefault: 2,
        UsrCreateID: this.$fun.getUserID(),
      },
      phoneDefault: {
        TypePhone: 1,
        PpnNumberPhone: "",
        PpnIsDefault: 0,
      },
      email: {
        PemEmail: "",
        PemIsDefault: 1,
        TypeDomainEmail: 1,
        UsrCreateID: this.$fun.getUserID(),
      },
      email2: {
        PemEmail: "",
        PemIsDefault: 2,
        TypeDomainEmail: 5,
        UsrCreateID: this.$fun.getUserID(),
      },
      emailDefault: {
        PemEmail: "",
        PemIsDefault: 0,
        TypeDomainEmail: 1,
      },
      addressDetail: [],
      phonesDetail: [],
      emailsDetail: [],
      addressHeaders: [
        { text: "ID", value: "PadID", sortable: false, width: 20 },
        {
          text: "Distrito Ugigeo",
          value: "PadCodeUbigeo",
          sortable: false,
          width: 50,
        },
        { text: "Direccion", value: "PadAddress", sortable: false, width: 100 },
        //{ text: "Tipo de Dirección"   , value: "TypeAddress"            ,sortable:false   , width: 50,  },
        {
          text: "Tipo de Dirección",
          value: "TypeAddressName",
          sortable: false,
          width: 50,
        },
        {
          text: "Referencia",
          value: "PadAddressReference",
          sortable: false,
          width: 100,
        },
        //{ text: "Zona"                , value: "ZonID"                  ,sortable:false   , width: 70,  },
        { text: "Zona", value: "ZonName", sortable: false, width: 70 },
        {
          text: "Categoria",
          value: "PadIsDefault",
          sortable: false,
          width: 70,
        },
        { text: "Estado", value: "SecStatus", sortable: false, width: 70 },
        { text: "Acciones", value: "actions", sortable: false, width: 30 },
      ],
      phonesHeaders: [
        //{ text: "ID"                  , value: "PpnID"                 ,sortable: false  , width: 20,  },
        {
          text: "Tipo de Telfono",
          value: "TypePhoneName",
          sortable: false,
          width: 20,
        },
        {
          text: "Teléfono",
          value: "PpnNumberPhone",
          sortable: false,
          width: 20,
        },
        {
          text: "Categoria",
          value: "PpnIsDefault",
          sortable: false,
          width: 20,
        },
        { text: "Acciones", value: "actions", sortable: false, width: 20 },
      ],
      emailsHeaders: [
        //{ text: "ID"                  , value: "PemID"                 ,sortable: false  , width: 20,  },
        { text: "Correo", value: "PemEmail", sortable: false, width: 40 },
        {
          text: "Categoria",
          value: "PemIsDefault",
          sortable: false,
          width: 40,
        },
        { text: "Estado", value: "SecStatus", sortable: false, width: 40 },
        { text: "Acciones", value: "actions", sortable: false, width: 20 },
      ],
      person: {
        NtpName: "",
        NtpPaternalSurname: "",
        NtpMaternalSurname: "",
        NtpID: 0,
        NtpBirthDate: null,
        TypeSex: 1,
        GenPerson: {
          TypePersonDocument: 0,
          PrsDocumentNumber: "",
          NatID: 1,
          Telephones: "0",
        },
        GenNaturalPersonDriver: null,
        NtpDisability: 0,
        NtpSyndical: 0,
        NtpAttach: "",
        //NtpNumCard: "",
      },
      driver: {
        NpdID: 0,
        NpdDocumentNumber: "",
        NtpID: 0,
        NpdDocumentInit: null,
        NpdDocumentEnd: null,
        TypeDocumentCategory: 0,
      },
      isInitializing: false, // Bandera para controlar la inicialización
      TypePersonDocument: 0,
      Attach: null,
      maxlengthPhone1: 9,
      maxlengthPhone2: 9,
      formats_accepted: [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
      ],
      max_size: 3000000,
    };
  },
  methods: {
    handleInput(event) {
      // console.log("Evento",event);
      this.person.NtpName = this.person.NtpName.trim().toUpperCase();
    },
    handleInputPat(event) {
      // console.log("Evento",event);
      this.person.NtpPaternalSurname =
        this.person.NtpPaternalSurname.trim().toUpperCase();
    },
    handleInputMat(event) {
      // console.log("Evento",event);
      this.person.NtpMaternalSurname =
        this.person.NtpMaternalSurname.trim().toUpperCase();
    },
    changeAddress(item) {
      // console.log("itemmAdrress",item);
      this.TypeAddress = item;
    },
    highlightFinger(index) {
      this.selectedFinger = index;
    },
    resetHighlight() {
      this.selectedFinger = null;
    },
    async selectFinger(index) {
      //console.log("eee",this.person.GenPerson.PrsDocumentNumber);
      if (
        this.person.GenPerson.PrsDocumentNumber !== null &&
        this.person.GenPerson.PrsDocumentNumber !== ""
      ) {
        //console.log('Huella seleccionada:', index);
        //this.initializeDevice(index);
        this.showModal = true;
        this.captured = false; // Resetear la bandera de captura
        this.scanning = true;
        this.capturedFingerprint = "";
        this.dataIndexPrint = index;
        await this.initializeDevice(index);
      } else {
        this.$fun.alert("Completar campos obligatorios", "warning");
      }
    },
    deleteItem(item, detail) {
      //console.log("deleteItem",item);
      switch (detail) {
        case 0:
          this.editedIndexAddress = this.addressDetail.indexOf(item);
          this.addressDetail.splice(this.editedIndexAddress, 1);
          this.editedIndexAddress = -1;
          break;
        case 1:
          this.editedIndexPhone = this.phonesDetail.indexOf(item);
          this.phonesDetail.splice(this.editedIndexPhone, 1);
          this.editedIndexPhone = -1;
          break;
        case 2:
          this.editedIndexEmail = this.emailsDetail.indexOf(item);
          this.emailsDetail.splice(this.editedIndexEmail, 1);
          this.editedIndexEmail = -1;
          break;
        default:
          this.editedIndexAddress = -1;
          this.editedIndexPhone = -1;
          this.editedIndexEmail = -1;
          break;
      }
      //this.clearDetail(detail);
    },

    editItem(item, detail) {
      //console.log("Item del Detalle",item);
      // Deshabilita temporalmente el watch
      this.isInitializing = true;
      switch (detail) {
        case 0:
          this.editedIndexAddress = this.addressDetail.indexOf(item);
          this.address = { ...item };
          this.TypeAddress = item.TypeAddress;
          this.Zone = item.ZonID;
          this.updateUbigeo(item.PadCodeUbigeo);
          this.CancelEditarA = true;
          // console.log("Data de Direccion Para Editar",this.address);
          // console.log("EditIndexAdre",this.editedIndexAddress);
          break;
        case 1:
          this.editedIndexPhone = this.phonesDetail.indexOf(item);
          this.phone = { ...item };
          this.CancelEditarT = true;
          // console.log("Data de Telefono Para Editar",this.phone);
          // console.log("EditIndexPhone",this.editedIndexPhone);
          break;
        case 2:
          this.editedIndexEmail = this.emailsDetail.indexOf(item);
          this.email = { ...item };
          this.email.PemEmail = item.PemEmail.split("@")[0];
          this.CancelEditarE = true;
          // console.log("Data de Email Para Editar",this.email);
          // console.log("EditIndexEmail",this.editedIndexEmail);
          //this.email.TypeDomainEmail  = item.TypeDomainEmail;
          break;
        default:
          this.CancelEditarA = false;
          this.CancelEditarT = false;
          this.CancelEditarE = false;
          break;
      }
    },

    async updateUbigeo(ubigeo) {
      const department = ubigeo.substr(0, 2);
      const province = ubigeo.substr(2, 2);
      const district = ubigeo.substr(4, 2);

      this.DepartmentUbigeo = department;
      await this.updateProvinces(department, province);
      this.ProvinceUbigeo = province;
      // console.log("ProvinceUbigeo",this.ProvinceUbigeo)
      await this.updateDistricts(province, district);
      this.DistrictUbigeo = district;
      this.$nextTick(() => {
        this.isInitializing = false; // Reactiva el watch después de inicializar
      });
    },

    async updateProvinces(department, province) {
      this.loadingProvinces = true; // Puedes usar esto para mostrar un spinner mientras cargas
      try {
        this.ProvinceUbigeo = province;
        this.configProvince = {
          title: "Provincia",
          url: _sGeneric.getProvince(),
          params: {
            requestID: this.$fun.getUserID(),
            DepartmentUbigeo: department,
            SearchText: "",
          },
        };
      } catch (error) {
        console.error("Error al cargar distritos:", error);
      } finally {
        this.loadingDistricts = false;
      }
    },

    async updateDistricts(province, district) {
      this.loadingDistricts = true; // Puedes usar esto para mostrar un spinner mientras cargas
      try {
        this.DistrictUbigeo = district;
        this.configDistrict = {
          title: "Distrito",
          url: _sGeneric.getDistrict(),
          params: {
            requestID: this.$fun.getUserID(),
            DistrictUbigeo: this.DepartmentUbigeo.toString() + province,
            SearchText: "",
          },
        };
      } catch (error) {
        console.error("Error al cargar distritos:", error);
      } finally {
        this.loadingProvinces = false;
      }
    },

    cancelItemDetail(valor) {
      this.clearDetail(valor);
    },
    validateDetail(detail) {
      let valid = true;
      let message = "";
      switch (detail) {
        case 0:
          if (!this.address.PadAddress) {
            message = "El campo Dirección es obligatorio";
            break;
          }
          if (this.TypeAddress === 1) {
            if (
              this.addressDetail.some(
                (element) =>
                  element.TypeAddress === 1 &&
                  this.addressDetail.indexOf(element) != this.editedIndexAddress
              )
            ) {
              message = "Solo puede haber una Direccion del Tipo Domicilio DNI";
              break;
            }
          }
          // console.log("Address",this.address);
          break;
        case 1:
          if (!this.phone.PpnNumberPhone) {
            message = "El campo Teléfono no puede ser vacio";
            break;
          }
          if (this.phone.PpnIsDefault) {
            if (
              this.phonesDetail.some(
                (element) =>
                  element.PpnIsDefault === 1 &&
                  this.phonesDetail.indexOf(element) != this.editedIndexPhone
              )
            ) {
              message = "Solo puede haber un Teléfono como Principal";
              break;
            }
          }
          // console.log("Phone",this.phone);
          break;
        case 2:
          if (!this.email.PemEmail) {
            message = "El campo Email no puede ser vacio";
            break;
          }
          if (this.email.PemIsDefault) {
            if (
              this.emailsDetail.some(
                (element) =>
                  element.PemIsDefault === 1 &&
                  this.emailsDetail.indexOf(element) != this.editedIndexEmail
              )
            ) {
              message = "Solo puede haber un Correo como Principal";
              break;
            }
          }
          // console.log("Email",this.email);
          break;
        default:
          break;
      }

      if (message) {
        this.$fun.alert(message, "warning");
        valid = false;
      }

      return valid;
    },
    addItemDetail(detail) {
      // console.log("Index",this.editedIndexAddress);
      // console.log("IDTabla",detail);
      if (!this.validateDetail(detail)) {
        return;
      }

      switch (detail) {
        case 0:
          let address = {
            PrsID: this.propPerson.PrsID || 0,
            PadCodeUbigeo:
              this.DepartmentUbigeo + this.ProvinceUbigeo + this.DistrictUbigeo,
            PadAddress: this.address.PadAddress,
            PadAddressReference: this.address.PadAddressReference,
            PadDepartment: "",
            PadStreet: "",
            ZonID: this.Zone ? this.Zone : 1,
            ZonName: this.Zone
              ? this.$refs.ZoneRef.items.find((x) => x.DedValue === this.Zone)
                  .DedDescription
              : "",
            TypeAddress: this.TypeAddress ? this.TypeAddress : 3,
            TypeAddressName: this.TypeAddress
              ? this.$refs.TypeAddressRef.items.find(
                  (x) => x.DedValue === this.TypeAddress
                ).DedDescription
              : "",
            SecStatus: 1,
            PadIsDefault: this.TypeAddress == 1 ? 1 : 0,
            UsrCreateID: this.$fun.getUserID(),
          };
          if (this.editedIndexAddress > -1) {
            Object.assign(this.addressDetail[this.editedIndexAddress], address);
          } else {
            this.addressDetail.push(address);
          }
          // console.log("detalleAddress",this.phonesDetail);
          break;
        case 1:
          let phone = {
            PrsID: this.propPerson.PrsID || 0,
            TypePhone: this.phone.TypePhone,
            TypePhoneName: this.$refs.TypePhoneRef.items.find(
              (x) => x.DedValue === this.phone.TypePhone
            ).DedDescription,
            PpnNumberPhone: this.phone.PpnNumberPhone,
            SecStatus: 1,
            PpnIsDefault: this.phone.PpnIsDefault,
            UsrCreateID: this.$fun.getUserID(),
          };
          if (this.editedIndexPhone > -1) {
            Object.assign(this.phonesDetail[this.editedIndexPhone], phone);
          } else {
            this.phonesDetail.push(phone);
            this.phonesDetail = this.phonesDetail.map((item, index) => ({
              ...item,
              index,
            }));
          }
          break;
        case 2:
          let email = {
            PrsID: this.propPerson.PrsID || 0,
            PemEmail:
              this.email.PemEmail +
              this.$refs.TypeDomainEmailRef.items.find(
                (x) => x.DedValue === this.email.TypeDomainEmail
              ).DedDescription,
            SecStatus: 1,
            PemIsDefault: this.email.PemIsDefault,
            UsrCreateID: this.$fun.getUserID(),
            //UsrUpdateID: this.$fun.getUserID(),
            TypeDomainEmail: this.email.TypeDomainEmail,
          };
          if (this.editedIndexEmail > -1) {
            Object.assign(this.emailsDetail[this.editedIndexEmail], email);
          } else {
            this.emailsDetail.push(email);
            this.emailsDetail = this.emailsDetail.map((item, index) => ({
              ...item,
              index,
            }));
          }
          // console.log("detalleEmails",this.emailsDetail);
          break;
        default:
          break;
      }

      this.clearDetail(detail);
    },

    clearDetail(detail) {
      switch (detail) {
        case 0:
          this.editedIndexAddress = -1;
          this.address = { ...this.addressDefault };
          this.DepartmentUbigeo = "20";
          this.ProvinceUbigeo = "01";
          this.DistrictUbigeo = "01";
          this.TypeAddress = 3;
          this.Zone = 4;
          this.CancelEditarA = false;
          break;
        case 1:
          this.editedIndexPhone = -1;
          this.phone = { ...this.phoneDefault };
          this.CancelEditarT = false;
          break;
        case 2:
          this.editedIndexEmail = -1;
          this.email = { ...this.emailDefault };
          this.CancelEditarE = false;
          break;
        default:
          break;
      }
    },

    initialize() {},
    async initializeDevice(index) {
      let obj = {
        WftID: 0,
        DNI: this.person.GenPerson.PrsDocumentNumber,
        WftStatus: 1,
        WfpTypeFinger: index,
        UsrCreateID: this.$fun.getUserID(),
        NtpValue: "",
      };

      setTimeout(() => {
        this.statusOpen = true;
      }, 500);
      await _sContracthmRs
        .InitialFingerprint(obj, this.$fun.getUserID())
        .then((response) => {
          this.captured = true;
          this.scanning = false;
          this.statusOpen = false;
          
          this.capturedFingerprint = "data:image/png;base64," + response.data.data.image;
          this.capturedFingerTemplate = response.data.data.template;

        })
        .catch((error) => {
          console.error("Error initializing device:", error);
        });
    },

    CaptureFootprint() {
      this.scanning = true;
      _sContracthmRs
        .captureFingerprint()
        .then((fingerprintData) => {
          // Do something with the fingerprint data (e.g., show it on screen)
          // console.log("Captured fingerprint:", fingerprintData.fingerprintBase64);
          // Finalizar la animación de escaneo
          this.scanning = false;

          // Mostrar el icono de check verde para indicar éxito
          this.captured = true;
        })
        .catch((error) => {
          // Handle error
          this.scanning = false; // Detener la animación
          alert("No se pudo capturar la huella");
        });
    },
    GetFingerPrintListBy() {
      if (
        this.person.GenPerson.PrsDocumentNumber !== null &&
        this.person.GenPerson.PrsDocumentNumber !== ""
      ) {
        _sContracthmRs
          .FingerPrintListBy(
            this.person.GenPerson.PrsDocumentNumber,
            this.$fun.getUserID()
          )
          .then((r) => {
            if (r.status == 200) {
              // console.log("fingeDATA: ",r.data);
              this.dataFingerL = r.data;
              this.dataFingerL.forEach((item) => {
                // Comparamos el id del dedo con el WfpTypeFinger
                this.fingers.forEach((finger) => {
                  if (finger.id === item.WfpTypeFinger) {
                    // Si coincide, cambiamos el estado de isActive a true
                    finger.isActive = true;
                  }
                });
              });
            }
          });
      }
    },
    async CloseFootprint() {
      this.showModal = false;
      this.captured = false;
      this.scanning = false;
      this.statusOpen = false;
    },
    async RetakeFingerprint() {
      //console.log("RetakeFingerprint", this.dataIndexPrint);
      await this.selectFinger(this.dataIndexPrint);
    },
    SaveFingerprint() {
      // Guarda la huella en el array usando el índice del dedo
      this.capturedFingerprints[this.dataIndexPrint] = {
        NtpValue: this.capturedFingerTemplate,
        Image: this.capturedFingerprint,
        Dni: this.person.GenPerson.PrsDocumentNumber,
        WfpTypeFinger: this.dataIndexPrint,
        usrCreateID: this.$fun.getUserID(),
        SecStatus: 1
      };
      
      // Marca el dedo como activo (cambia el color)
      this.fingers[this.dataIndexPrint].isActive = true;
      
      // Cierra el modal
      this.showModal = false;
      
      // Muestra confirmación al usuario
      this.$fun.alert("Huella capturada con exito", "success");
      //console.log("this.capturedFingerprints", this.capturedFingerprints);
    },
    // Método para abrir la cámara
    openCamera() {
      navigator.mediaDevices
        .getUserMedia({ video: { width: 640, height: 480 } }) // Resolución baja
        .then((stream) => {
          this.$refs.video.srcObject = stream; // Asignar el stream al elemento <video>
          this.$refs.video.style.display = "block"; // Mostrar el video en la interfaz
          this.$refs.video.play(); // Reproducir video
          this.isCameraActive = true; // Indicar que la cámara está activa
        })
        .catch((err) => {
          console.error("Error al acceder a la cámara: ", err);
          this.isCameraActive = false;
        });
    },
    // Método para capturar la foto desde el video
    capturePhoto() {
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext("2d");

      // Asegurarnos que el canvas tiene el mismo tamaño que el video
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // Dibujar el fotograma actual del video en el canvas
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Convertir la imagen a base64
      const dataUrl = canvas.toDataURL("image/jpeg");

      // Asignar la imagen capturada al estado `image`
      this.image = dataUrl;

      // Detener la cámara
      const stream = video.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      video.srcObject = null;
      this.isCameraActive = false;

      // Subir la imagen al servidor
      //this.uploadImage(dataUrl);
    },

    uploadImage(imageData) {
      if (!imageData) {
        this.$fun.alert("Sin archivo para subir");
        return;
      }

      var formData = new FormData();
      
      // Convertir base64 a Blob correctamente
      const byteString = atob(imageData.split(',')[1]);
      const mimeString = imageData.split(',')[0].split(':')[1].split(';')[0]; // Extraer el MIME type
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uintArray = new Uint8Array(arrayBuffer);
      
      for (let i = 0; i < byteString.length; i++) {
          uintArray[i] = byteString.charCodeAt(i);
      }
      
      const file = new Blob([uintArray], { type: mimeString });

      formData.append('file', file, 'photo.jpg');
      formData.append('nameFile', this.person.GenPerson.PrsDocumentNumber);
      formData.append('genParameter', this.paramName);

      _sGenUploadFiles.upload(formData, this.$fun.getUserID())
        .then((resp) => {
            if (resp.status === 200) {
                this.$fun.alert("Imagen subida correctamente", "success");
            }
        })
        .catch(error => {
            console.error("Error al subir la imagen: ", error);
        });
  },
    Limit() {
      // console.log("Limit",this.TypePersonDocument)  ;
    },
    updateDataDistrict() {
      if (this.person != null)
        if (this.person.GenPerson != null)
          if (this.person.GenPerson.Addresses != null)
            if (this.person.GenPerson.Addresses.length > 0) {
              this.DistrictUbigeo =
                this.person.GenPerson.Addresses[0].PadCodeUbigeo;
            }
    },
    txtDocument() {
      if (this.typeperson == 1) {
        this.search();
        this.$refs.textNtpName.focus();
      } else this.$refs.textJrpName.focus();
    },
    inputDocument(event) {
      // console.log("aaaA",event);
      if (!event) {
        console.error("El evento no es válido.");
        //return;
      }

      this.fingers.forEach((finger) => {
        finger.isActive = false;
      });
      //  console.log("escribienod",event);
      if (event) {
        if (event.length > 7) {
          this.GetFingerPrintListBy();
        }
      }
    },
    async search() {
      //this.clear();
      // console.log("Persona11111",this.person);
      try {
        let obj = {
          TypePerson: this.TypePersonDocument.DedValue || 1,
          PrsDocumentNumber: this.person.GenPerson.PrsDocumentNumber,
          UsrID: this.$fun.getUserID(),
          IsValidation: 0,
        };

        const response = await Service.personatusearch(
          obj,
          this.$fun.getUserID()
        );

        if (response.status == 200 && response.data) {
          this.personEdit = response.data;
          //console.log("PersonaEdit111111", this.personEdit);

          const responseAdm = await _sAdmonition.adminitionByNtpID(
            this.personEdit.NtpID,
            this.$fun.getUserID()
          );

          if (responseAdm.status == 200 && responseAdm.data) {
            let Admonition = responseAdm.data;
            //console.log("Admonition", Admonition);

            this.TypeAdmonitionName = Admonition.TypeAdmonitionName;

            this.person.NtpName = this.personEdit.NtpName;
            this.person.NtpMaternalSurname = this.personEdit.NtpMaternalSurname;
            this.person.NtpPaternalSurname = this.personEdit.NtpPaternalSurname;
          } else {
            this.editing = true;
            this.$emit("search", this.personEdit);
          }

          // Cerrar el diálogo de agregar persona
          //this.dialogAddPerson = false;

          // Esperar un momento para que Vue actualice el DOM
          // this.$nextTick(() => {
          //   // Abrir el diálogo de editar persona
          //   this.dialogEditPerson = true;
          // });
        } else {
          //this.$toast.warning("DNI no encontrado, puedes registrarlo.");
        }
      } catch (error) {
        console.error("Error al buscar persona:", error);
        this.$toast.error("Error en la búsqueda.");
      }
      // Service.getNaturalPerson(this.person.GenPerson.PrsDocumentNumber, this.$fun.getUserID()).then((r) => {
      //   if (r.status == 200) {
      //     this.editing = true;
      //     this.personEdit = r.data;
      //     // if (this.personEdit != null) {
      //     //   if (this.personEdit.GenPerson != null) {
      //     //     this.person = { ...this.personEdit };
      //     //     this.person.NtpBirthDate = this.$moment(this.personEdit.NtpBirthDate).format("DD-MM-YYYY");
      //     //     this.person.NtpBirthDate = this.personEdit.NtpBirthDate;
      //     //     this.person.GenPerson.TypePersonDocument = 1;

      //     //     if (this.person.GenPerson.Emails.length > 0) {
      //     //       this.email = this.person.GenPerson.Emails[0];
      //     //     }
      //     //     if (this.person.GenPerson.Telephones.length > 0) {
      //     //       this.phone = this.person.GenPerson.Telephones[0];
      //     //     }
      //     //     if (this.person.GenPerson.Addresses.length > 0) {
      //     //       this.ZoneID = this.person.GenPerson.Addresses[0].ZonID;
      //     //       this.address = this.person.GenPerson.Addresses[0];
      //     //       this.DepartmentUbigeo = this.person.GenPerson.Addresses[0].PadCodeUbigeo.substr(0, 2);
      //     //       this.ProvinceUbigeo = this.person.GenPerson.Addresses[0].PadCodeUbigeo.substr(0, 4);
      //     //       this.DistrictUbigeo = this.person.GenPerson.Addresses[0].PadCodeUbigeo;
      //     //     }
      //     //     if (this.person.GenNaturalPersonDriver.length > 0) {
      //     //       this.driver = this.person.GenNaturalPersonDriver[0];
      //     //     }
      //     //   } else if (this.propPerson.PrsDocumentNumber) this.person.GenPerson.PrsDocumentNumber = this.propPerson.PrsDocumentNumber;

      //     //   if (this.typeperson == 1) this.$refs.textNtpName.focus();
      //     //   else this.$refs.textJrpName.focus();
      //     // }
      //     // this.editing = false;
      //   }
      //   this.$emit("search", this.person);
      // });
    },
    clear() {
      this.ZoneID = 15;
      this.DepartmentUbigeo = "20";
      this.ProvinceUbigeo = "2001";
      this.phone = {
        TypePhone: 1,
        PpnNumberPhone: "",
        PpnIsDefault: 1,
      };
      this.address = {
        PadCodeUbigeo: "",
        PadAddress: "",
        PadAddressReference: "",
        PadStreet: "",
        PadDepartment: "",
        ZonID: 0,
        PadIsDefault: 1,
      };
      this.email = {
        PemEmail: "",
        PemIsDefault: 1,
      };
      this.driver = {
        NpdID: 0,
        NpdDocumentNumber: "",
        NpdDocumentInit: null,
        NpdDocumentEnd: null,
        TypeDocumentCategory: 0,
      };

      let doc;
      if (this.person.GenPerson.PrsDocumentNumber.length > 0)
        doc = this.person.GenPerson.PrsDocumentNumber;
      else doc = "";
      this.person = {
        NtpName: "",
        NtpPaternalSurname: "",
        NtpMaternalSurname: "",
        NtpID: 0,
        NtpBirthDate: this.$moment().format(this.$const.DateMomentFormat),
        TypeSex: 1,
        GenPerson: {
          TypePersonDocument: 1,
          PrsDocumentNumber: doc,
          NatID: 193,
        },
        //TypePersonDocument: null,
      };
    },
    validateData() {
      let message = "";
      let valid = true;

      if (
        this.person.GenPerson.PrsDocumentNumber.length >
        this.TypePersonDocument.DedHelper2
      ) {
        message =
          "El numero del Documento no debe ser mayor de " +
          this.TypePersonDocument.DedHelper2 +
          " caracteres";
      }

      if (
        this.person.GenPerson.PrsDocumentNumber.length <
        this.TypePersonDocument.DedHelper2
      ) {
        message = "Número de Documento Incorrecto";
      }

      if (this.person.NtpName == "") {
        message = "Registre Nombre";
      }

      if (this.person.NtpPaternalSurname.trim().length < 1) {
        message = "Registre Apellido Paterno";
      }

      if (this.person.NtpMaternalSurname.trim().length < 1) {
        message = "Registre Apellido Materno";
      }

      if (this.addressDetail.length == 0) {
        message = "Registre al menos una Dirección";
      }

      if (!this.addressDetail.some((element) => element.TypeAddress == 1)) {
        message = "Una Dirección del tipo Domicilio DNI es obligatoria";
      }

      if (!this.$fun.isValidDate(this.person.NtpBirthDate)) {
        message = "Fecha de Nacimiento Inválida";
      }

      if (!this.$fun.isValidAge(this.person.NtpBirthDate)) {
        message =
          "Fecha de Nacimiento Inválida, tiene que ser mayor a 18 años y menor a 65 años";
      }

      if (this.driver) {
        if (this.driver.NpdDocumentNumber.length > 0) {
          if (
            this.driver.TypeDocumentCategory == 0 ||
            this.driver.TypeDocumentCategory == null
          ) {
            message = "Seleccione Categoría";
          }

          if (!this.$fun.isValidDate(this.driver.NpdDocumentEnd)) {
            message = "Seleccione Fecha de Revalidación";
          }
          if (
            !this.$fun.isValidRangeDate(
              this.driver.NpdDocumentInit,
              this.driver.NpdDocumentEnd
            )
          ) {
            message = "Verifique Fechas de Validez";
          }
        }
      }

      if (
        this.phone.PpnNumberPhone > 0 &&
        this.phone.PpnNumberPhone.length < this.maxlengthPhone1
      ) {
        message =
          "El número de Teléfono Principal no puede menor de " +
          this.maxlengthPhone1 +
          " dígitos";
      }
      if (
        this.phone2.PpnNumberPhone > 0 &&
        this.phone2.PpnNumberPhone.length < this.maxlengthPhone2
      ) {
        message =
          "El número de Teléfono Secundario no puede menor de " +
          this.maxlengthPhone1 +
          " dígitos";
      }

      if (message) {
        this.$fun.alert(message, "warning");
        valid = false;
      }
      return valid;
    },
    save() {
      // this.person.GenPerson.Telephones = "3232323";
      if (!this.validateData()) {
        return;
      }

      this.person.GenPerson.TypePerson = this.typeperson;
      this.person.GenPerson.TypePersonDocument =
        this.TypePersonDocument.DedValue;

      this.person.GenPerson.PrsDocumentNumber =
        this.person.GenPerson.PrsDocumentNumber;
      this.person.NtpName = this.person.NtpName.toUpperCase().trim();
      this.person.NtpPaternalSurname =
        this.person.NtpPaternalSurname.toUpperCase().trim();
      this.person.NtpMaternalSurname =
        this.person.NtpMaternalSurname.toUpperCase().trim();

      this.person.GenPerson.Telephones = [];
      this.person.GenPerson.Emails = [];

      if (this.phone.PpnNumberPhone.length > 0) {
        this.person.GenPerson.Telephones.push(this.phone);
        if (this.phone2.PpnNumberPhone.length > 0) {
          this.person.GenPerson.Telephones.push(this.phone2);
        }
      }

      if (this.email.PemEmail.length > 0) {
        this.email.PemEmail =
          this.email.PemEmail +
          this.$refs.TypeDomainEmailRef.items.find(
            (x) => x.DedValue === this.email.TypeDomainEmail
          ).DedDescription;
        this.person.GenPerson.Emails.push(this.email);
        if (this.email2.PemEmail.length > 0) {
          this.email2.PemEmail =
            this.email2.PemEmail +
            this.$refs.TypeDomainEmailRef2.items.find(
              (x) => x.DedValue === this.email2.TypeDomainEmail
            ).DedDescription;
          this.person.GenPerson.Emails.push(this.email2);
        }
      }
 

      this.person.GenPerson.Addresses = this.addressDetail;
      this.person.NtpDisability = this.person.NtpDisability ? 1 : 0;
      this.person.NtpSyndical = this.person.NtpSyndical ? 1 : 0;

      this.person.NtpAttach = this.Attach?.name || "";

      let p = { ...this.person };

      if (this.full)
        p.NtpBirthDate =
          this.person.NtpBirthDate.substr(6, 4) +
          "-" +
          this.person.NtpBirthDate.substr(3, 2) +
          "-" +
          this.person.NtpBirthDate.substr(0, 2);

      if (this.driver) {
        if (this.driver.NpdDocumentNumber.length > 0) {
          this.driver.SecStatus = 1;
          this.driver.NtpID = p.NtpID;
          this.driver.UsrCreateID = this.$fun.getUserID();
          p.GenNaturalPersonDriver = [this.driver];
        } else {
          if (this.driver.NpdID > 0) {
            this.driver.SecStatus = 0;
            this.driver.NtpID = p.NtpID;
            p.GenNaturalPersonDriver = [this.driver];
          }
        }
      }

      const fingerprints = this.capturedFingerprints.filter(fp => fp !== null);

      Service.savenaturalperson(p, this.$fun.getUserID()).then((r) => {
        const personID = r.data.usrID;
        const fingerprintsWithPersonID = fingerprints.map(fp => ({
          ...fp,           // Mantiene todas las propiedades existentes
          NtpID: personID, // Agrega el ID de la persona
        }));

        _sContracthmRs.SaveFingerprints(fingerprintsWithPersonID, this.$fun.getUserID())
          .then((r) => {
            if (this.Attach) {
              this.saveFile();
            }

            if(this.image) {
              this.uploadImage(this.image);
            }
            this.$emit("personSaved", this.person);
            this.$emit("save", this.person);
            this.person.GenPerson.PrsDocumentNumber = "";
            this.clear();
            this.$fun.alert("Guardado Correctamente", "success");
            this.closeSave();
          })
          .catch(error => {
            console.error("Error al guardar:", error);
            this.$fun.alert("Error al guardar" + error, "error");
          });
        
      });
    },

    closeSave() {
      this.fingers.forEach((finger) => {
        finger.isActive = false;
      });
      this.$emit("toclose", 2);
      this.clear();
    },
    close() {
      //this.$refs.document.focus();
      this.$fun.alertFull("¿Está seguro de Salir?", "question").then((resp) => {
        if (resp.value) {
          this.fingers.forEach((finger) => {
            finger.isActive = false;
          });
          this.$emit("toclose", 2);
          this.clear();
        }
      });
    },
    closeAll() {
      this.$refs.document.focus();
      this.fingers.forEach((finger) => {
        finger.isActive = false;
      });
      this.$emit("toclose", 2);
      this.clear();
      this.$fun.alertFull("¿Está seguro de Salir?", "question").then((resp) => {
        if (resp.value) {
          let obj = {
            WftID: 4,
            DNI: this.person.GenPerson.PrsDocumentNumber,
            WftStatus: 0,
            SecStatus: 0,
            WfpTypeFinger: 0,
            UsrCreateID: this.$fun.getUserID(),
            NtpValue: "",
            // this.ipMatch[1]
          };
          _sContracthmRs
            .saveFingerTemp(obj, this.$fun.getUserID())
            .then((r) => {});
          this.$refs.document.focus();
          this.fingers.forEach((finger) => {
            finger.isActive = false;
          });
          this.$emit("toclose", 2);
          this.clear();
        }
      });
    },
    handleRightClick(event, index) {
      // Only show menu for active fingerprints
      if (this.fingers[index].isActive) {
        this.selectedFingerDelete = index;
        this.selectedFingerActive = true;
        this.showContextMenu = true;
        this.menuX = event.clientX;
        this.menuY = event.clientY;
      } else {
        this.selectedFingerActive = false;
      }
    },
    deleteFingerprint() {
      
      if (this.selectedFingerDelete !== null && this.fingers[this.selectedFingerDelete].isActive) {
        // Buscar la huella en dataFingerL (guardadas en BD)
        const fingerprintToDelete = this.dataFingerL.find(
          item => item.WfpTypeFinger === this.fingers[this.selectedFingerDelete].id
        );

        if (fingerprintToDelete) {
          // Si está en la base de datos, marcarla para eliminación
          fingerprintToDelete.SecStatus = 0;

          // Si existe en capturedFingerprints, actualizar estado
          if (this.capturedFingerprints[this.selectedFingerDelete]) {
            this.capturedFingerprints[this.selectedFingerDelete].SecStatus = 0;
          } else {
            this.capturedFingerprints[this.selectedFingerDelete] = {
              Dni: this.person.GenPerson.PrsDocumentNumber,
              WfpTypeFinger: this.selectedFingerDelete,
              usrCreateID: this.$fun.getUserID(),
              SecStatus: 0
            };

            if (fingerprintToDelete.WfpID) {
              this.capturedFingerprints[this.selectedFingerDelete].WfpID = fingerprintToDelete.WfpID;
            }
          }
        } else if (this.capturedFingerprints[this.selectedFingerDelete]) {
          // Si solo existe en capturedFingerprints (temporal), eliminarla
          delete this.capturedFingerprints[this.selectedFingerDelete];
        }

        // Actualizar UI - Cambiar apariencia del dedo
        this.fingers[this.selectedFingerDelete].isActive = false;

        // Cerrar el menú contextual si aplica
        this.showContextMenu = false;

        // Mostrar confirmación al usuario
        this.$fun.alert("Huella eliminada o marcada para eliminación", "success");
      }
    },
    uploadFile() {
      var formData = new FormData();
      let i = 0;

      formData.append(i.toString(), this.Attach);
      i++;

      _sGeneral.uploadfile("123", this.$fun.getUserID(), formData, 3);
      this.$fun.alert("Guardado Correctamente", "success");
      this.$emit("onClose");
    },

    openFile(url) {
      //console.log("Nombre de url", url);
      _sGeneral.download(url.name, this.$fun.getUserID()).then((r) => {
        // console.log("rtpa",r);
        this.$fun.download(r.data, url.name);
        // if(r.status === 200){

        // 	this.$fun.downloadHttp(r);
        // }
      });
    },
    validateFile() {
      if (!this.Attach) return;
      if (!this.formats_accepted.includes(this.Attach.type)) {
        this.$fun.alert(
          "Formato de archivo anexo no permitido (3MB)",
          "warning"
        );
        return;
      }
      if (this.Attach.size > this.max_size) {
        this.$fun.alert(
          "Tamaño de archivo excede el límite permitido",
          "warning"
        );
        return;
      }
    },
    saveFile() {
      // console.log("Nombre del Archivo: " , this.Attach);
      // console.log("Item:" , this.selected);
      this.validateFile();
      s_HarvestSchedule
        .uploadFileName(
          this.selected.HvsID,
          this.Attach.name,
          this.$fun.getUserID()
        )
        .then((r) => {
          if (r.status === 200) {
            // console.log("Subido Correcto");
            this.uploadFile();
          }
        });
    },
  },
  computed: {
    filteredAddressDetail() {
      // Filtrar los elementos donde SecStatus sea 1
      return this.addressDetail.filter((item) => item.SecStatus === 1);
    },
    filteredPhonesDetail() {
      return this.phonesDetail.filter((item) => item.SecStatus === 1);
    },
    filteredEmailsDetail() {
      return this.emailsDetail.filter((item) => item.SecStatus === 1);
    },
    MaxLengthDoc() {
      return this.TypePersonDocument.DedHelper || 20;
    },
  },
  watch: {
    propPerson() {
      if (this.propPerson != null) {
        if (this.propPerson.GenPerson != null) {
          this.person = { ...this.propPerson };
          // this.person.NtpBirthDate = this.$moment(this.propPerson.NtpBirthDate).format("DD-MM-YYYY");
          this.person.NtpBirthDate = this.propPerson.NtpBirthDate;
          this.person.GenPerson.TypePersonDocument = 1;

          if (this.person.GenPerson.Emails.length > 0)
            this.email = this.person.GenPerson.Emails[0];
          if (this.person.GenPerson.Telephones.length > 0) {
            this.phone = this.person.GenPerson.Telephones[0];
          }
          if (this.person.GenPerson.Addresses.length > 0) {
            this.address = this.person.GenPerson.Addresses[0];
            // this.DepartmentUbigeo = this.person.GenPerson.Addresses[0].PadCodeUbigeo.substr(0, 2);
            // this.ProvinceUbigeo = this.person.GenPerson.Addresses[0].PadCodeUbigeo.substr(0, 4);
            // this.DistrictUbigeo = this.person.GenPerson.Addresses[0].PadCodeUbigeo;
          }
          if (this.person.GenNaturalPersonDriver.length > 0) {
            this.driver = this.person.GenNaturalPersonDriver[0];
          }
        } else {
          this.person.GenPerson.PrsDocumentNumber =
            this.propPerson.PrsDocumentNumber;
        }
      }
    },
    DepartmentUbigeo() {
      if (this.isInitializing) return;
      this.ProvinceUbigeo = 0;
      this.configProvince = {
        title: "Provincia",
        url: _sGeneric.getProvince(),
        params: {
          requestID: this.$fun.getUserID(),
          DepartmentUbigeo: this.DepartmentUbigeo,
          SearchText: "",
        },
      };
    },
    ProvinceUbigeo() {
      if (this.isInitializing) return;
      this.DistrictUbigeo = 0;

      // console.log("Privincia1111",this.DepartmentUbigeo.toString() + this.ProvinceUbigeo.toString());
      // console.log("Privincia2222",this.ProvinceUbigeo);
      if (this.ProvinceUbigeo != 0) {
        this.configDistrict = {
          title: "Distrito",
          url: _sGeneric.getDistrict(),
          params: {
            requestID: this.$fun.getUserID(),
            DistrictUbigeo:
              this.DepartmentUbigeo.toString() + this.ProvinceUbigeo.toString(),
            SearchText: "",
          },
        };
      }
      //if (this.ProvinceUbigeo == "2001" && this.editing == false) this.DistrictUbigeo = "200114";
      //if( this.DepartmentUbigeo=='20') this.DistrictUbigeo='14'; else this.DistrictUbigeo='01'
    },
    DistrictUbigeo() {
      // console.log("DistrictUbigeo3333",this.DistrictUbigeo);
    },

    "phone.TypePhone"() {
      this.maxlengthPhone1 = parseInt(
        this.$refs.TypePhoneRef.items.find(
          (x) => x.DedValue === this.phone.TypePhone
        ).DedHelper2
      );
    },

    "phone2.TypePhone"() {
      this.maxlengthPhone2 = parseInt(
        this.$refs.TypePhoneRef2.items.find(
          (x) => x.DedValue === this.phone2.TypePhone
        ).DedHelper2
      );
    },
  },
  created() {
    //if (this.full) {
    this.configNationality = {
      title: "Nacionalidad",
      url: _sGeneric.getNationality(),
      params: { requestID: this.$fun.getUserID() },
    };
    this.configZones = {
      title: "Zonas",
      url: _sGeneric.getZones(),
      params: { requestID: this.$fun.getUserID() },
    };
    this.configDepartment = {
      title: "Departamento",
      url: _sGeneric.getDepartment(),
      params: { requestID: this.$fun.getUserID() },
    };

    this.configProvince = {
      title: "Provincia",
      url: _sGeneric.getProvince(),
      params: {
        requestID: this.$fun.getUserID(),
        DepartmentUbigeo: 20,
        SearchText: "",
      },
    };

    this.configDistrict = {
      title: "Distrito",
      url: _sGeneric.getDistrict(),
      params: {
        requestID: this.$fun.getUserID(),
        DistrictUbigeo: "2201",
        SearchText: "",
      },
    };
    // console.log("Distrito",this.configDistrict);

    this.DepartmentUbigeo = "20";
    //}
    if (this.propPerson != null)
      if (this.propPerson.GenPerson != null) {
        this.person = Object.assign(this.propPerson, {});
      } else
        this.person.GenPerson.PrsDocumentNumber =
          this.propPerson.PrsDocumentNumber;

    console.log("IMAge111",this.image);
  },
  mounted() {
    console.log("IMAge2222",this.image);
    // const rtc = new RTCPeerConnection();
    // rtc.createDataChannel("");
    // rtc.createOffer().then((offer) => rtc.setLocalDescription(offer));
    // rtc.onicecandidate = (event) => {
    //   if (event && event.candidate) {
    //      this.ipMatch = /([0-9]{1,3}(\.[0-9]{1,3}){3})/.exec(event.candidate.candidate);
    //     if (this.ipMatch) {
    //       console.log("Local IP:", this.ipMatch[1]);
    //     }
    //   }};
    //     console.log("mounted",this.person.GenPerson.TypePersonDocument);
    // let recaptchaScript = document.createElement("script");
    // recaptchaScript.setAttribute("src", this.$const.URL + "/signalr/hubs");
    // document.head.appendChild(recaptchaScript);
    // recaptchaScript.addEventListener("load", () => {
    // 	$.connection.hub.url = this.$const.URL + "/signalr";
    // 	var chat = $.connection.notification;
    // 	chat.client.SendMessageTagfingerprint = group => {
    //     this.captured = true;
    //     this.scanning = false;
    //     this.statusOpen=false;
    //     setTimeout(() => {
    //             this.showModal = false;
    //         }, 1000);
    //         this.GetFingerPrintListBy();
    // 	};
    // 	$.connection.hub.start({ jsonp: true }).done(e => {
    // 		chat.invoke("addToGroup", "fingerprintData");
    // 	});
    // });
  },
};
</script>
  <style scoped>
.finger {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #e0e0e0;
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
  margin: 5px;
}

.finger:hover {
  background-color: orange;
}
</style>

    