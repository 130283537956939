import Service from "../Service";

const resource = "orderpurchaseapproval/";

export default {

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    listPerson(UsrID , requestID) {
        return Service.post(resource + "listPerson", {}, {
            params: { UsrID : UsrID, requestID: requestID },
        });
    },

};