<template>
    <v-dialog v-model="dialog" max-width="320" persistent color="success">
          <v-container style="display: flex; justify-content: center; flex-direction: column; margin-top: 30px; overflow: hidden;">
              <v-row>
                  <v-col cols="12" style="position: relative;">
                    <div style="display: flex; justify-content: center; transform: rotate(-40deg) scale(0.8); position: relative;">
                      <div style="position: absolute; z-index: 10; top: 62px; left: 165px; transform: rotate(40deg)">
                        <img src="/static/hoja.png" alt="" width="120">
                      </div>
                      <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                          <linearGradient id="gradiente" x1="0%" y1="100%" x2="-30%" y2="0%">
                            <stop offset="0%" style="stop-color:#f92121;stop-opacity:1">
                              <animate attributeName="offset" values="0;0.1;0" keyTimes="0;0.5;1" dur="3s" repeatCount="indefinite" />
                            </stop>
                            <stop offset="40%" style="stop-color:#F1C21B;stop-opacity:1">
                              <animate attributeName="offset" values="0.2;0.9;0.2" keyTimes="0;0.5;1" dur="3s" repeatCount="indefinite" />
                            </stop>
                            <stop offset="100%" style="stop-color:#22cb0e;stop-opacity:1">
                              <animate attributeName="offset" values="0.8;1.5;0.8" keyTimes="0;0.5;1" dur="3s" repeatCount="indefinite" />
                            </stop>
                          </linearGradient>

                          <filter id="ruido">
                            <feTurbulence type="fractalNoise" baseFrequency="1.5" numOctaves="1" result="noise"/>
                            <feBlend in="SourceGraphic" in2="noise" mode="multiply"/>
                            <feComposite in2="SourceAlpha" operator="in" />
                            <feDropShadow dx="2" dy="3" stdDeviation="4" flood-color="rgba(0, 0, 0, 0.4)"/>
                          </filter>

                          <filter id="brillo">
                            <feGaussianBlur in="SourceAlpha" stdDeviation="5" result="blur"/>
                            <feSpecularLighting result="specOut" specularExponent="10" lighting-color="white">
                              <fePointLight x="60" y="20" z="100"/>
                            </feSpecularLighting>
                            <feComposite in="specOut" in2="blur" operator="atop"/>
                          </filter>
                        </defs>

                        <path fill="url(#gradiente)" filter="url(#ruido)" d="M51.6,-17.6C60.2,9.5,55.7,40,39,51.6C22.4,63.2,-6.5,56,-29.4,39.5C-52.3,23.1,-69.2,-2.4,-63.3,-25.9C-57.4,-49.3,-28.7,-70.7,-3.6,-69.5C21.5,-68.4,43.1,-44.7,51.6,-17.6Z" transform="translate(100 100) scale(-1,1)" />

                        <path fill="none" stroke="white" stroke-width="7" filter="url(#brillo)"
                          d="M51.6,-17.6C60.2,9.5,55.7,40,39,51.6C22.4,63.2,-6.5,56,-29.4,39.5C-52.3,23.1,-69.2,-2.4,-63.3,-25.9C-57.4,-49.3,-28.7,-70.7,-3.6,-69.5C21.5,-68.4,43.1,-44.7,51.6,-17.6Z"
                          transform="translate(100 100) scale(-1,1)" opacity="0.5"/>
                      </svg>
                    </div>
                    <div :style="{
                            position: 'absolute',
                            zIndex: 10,
                            bottom: totalItems == 0 ? '35px' : '25px',
                            left: 0,
                            right: 0,
                            fontSize: '16px',
                            margin: 'auto',
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            flexDirection: 'column',
                            alignItems: 'center'
                          }">
                      <div class="texto_loading">Cargando...</div>
                      <div v-if="totalItems > 0" style="color: #fff; font-size: 12px;">{{ countItems }}/{{ totalItems }}</div>
                    </div>
                  </v-col>
              </v-row>
          </v-container>
      </v-dialog>
</template>
<script>
export default {
  name: "AlertSunshine",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    countItems: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("update:dialog", value);
      },
    },
  }
};
</script>

<style>
.v-dialog {
  box-shadow: none !important;
}

.texto_loading {
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  border-right: 0.2em solid #f92121;
  animation:
    typeAndDelete 3s steps(12) infinite,
    blinkCursor 0.5s step-end infinite alternate;
}

@keyframes blinkCursor {
  50% {
    border-right-color: transparent;
  }
}

@keyframes typeAndDelete {
  0%,
  10% {
    width: 0;
  }
  45%,
  55% {
    width: 6.2em;
  }
  90%,
  100% {
    width: 0;
  }
}
</style>