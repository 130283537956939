let children = [];
//------------ TESORERIA ------------
children.push({
    path: "/treasury/paymentScheduling",
    name: "TSR_PROGRAMACION_DE_PAGOS",
    component: () =>
        import ("@/views/Treasury/PaymentScheduling/PaymentScheduling.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Tesoreria", disabled: true },
            { text: "Programación de Pagos", href: "/treasury/paymentScheduling" },
        ],
    },
});

export { children };