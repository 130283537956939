<template>
    <v-card elevation="0">
      <s-toolbar v-if="visibleTitle" label="Datos Persona" />
        <v-card-text> 
          <v-row>
            <v-col class="s-col-form" sm="4" md="3" lg="4">
                <s-toolbar-person  :defaultDocument="item.PrsDocumentNumber" @returnPerson="returnPerson($event, item)" v-model="item.PrsDocumentNumber" noCamera  :showCounter="true" />
            </v-col>
            <v-col  class="s-col-form" cols="8" sm="8" md="8" lg="8" >			          
              <s-text ref="textNtpName" label="Nombres y Apellidos Completos" v-model="item.NtpFullName" readonly />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="s-col-form" sm="4" md="4" lg="4">
              <s-select-definition :def="1060" label="Planilla" v-model="item.TypePayroll"  :add="$fun.isAdmin()" />
            </v-col>
            <v-col class="s-col-form" sm="4" md="4" lg="4" > 
              <s-select-area 	label="Area" v-model="item.AreID" :text="item.AreName" ></s-select-area>
            </v-col>
            <v-col class="s-col-form" sm="4" md="4" lg="4"> 
              <s-select-area 	v-model="selectedCenterCost" :text="item.CceName" centercost :clearable="true" label="Centro de Costo"	/>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="s-col-form" sm="4" md="4" lg="4">                
                <s-select-position 	v-model="item.PstID" :text="item.PstName" centercost :clearable="true" label="Cargo"	/>                
            </v-col> 
            <v-col class="s-col-form" sm="2" md="2" lg="2">  
              <v-label>
                <b> Nivel de Criticidad  </b>
              </v-label>       
              <v-chip
                  
                    :color="
                      item.PstIsBascCritic == 1 || item.PstIsBascCritic == 0
                        ? 'white'
                        : item.PstIsBascCritic == 2
                        ? 'primary'
                        : item.PstIsBascCritic == 3
                        ? 'warning'
                        : 'error'
                    "
                    style="margin:0px;"
                    >{{
                      item.PstIsBascCritic == 0
                        ? "No Definido"
                        : item.PstIsBascCriticName
                    }}
                  </v-chip>
            </v-col>
            <v-col class="s-col-form" sm="2" md="2" lg="2">                
                <s-select
                      label="Cuadrilla"
                      :items="quadrille"
                      autocomplete
                      clearable
                      item-text="GngName"
                      item-value="GngID"
                      v-model="item.GngID"
                    ></s-select>
                </v-col>
            <v-col class="s-col-form" sm="4" md="4" lg="4">
              <s-select-business-line v-model="selectedBusinessLine"  :cceID="selectedCenterCost" :clearable="true" label="Línea de Negocio"/>
            </v-col>
          </v-row>
          <v-row>            
            <v-col class="s-col-form" sm="4" md="4" lg="4">
              <s-select-definition :def="1482"  label="Categoria" v-model="item.typeCategory" :add="$fun.isAdmin()" />
            </v-col>
            <v-col class="s-col-form" sm="4" md="4" lg="4">
              <s-select-definition :def="1069"  label="Tipo de Contrato"  v-model="item.TypeContract"  :add="$fun.isAdmin()" />
            </v-col>
            <v-col class="s-col-form" sm="4" md="4" lg="4">
              <s-text label="N° de hijos" number v-model="item.AgtChildrenQuantity" :min="0" > </s-text>
            </v-col>
            
          </v-row>
          <v-row v-if="item.TypeContract == 9">
            <v-col>
              <s-textarea label="Describa en Servicio" :height=80 v-model="item.AgtServiceDescription"></s-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="s-col-form" sm="4" md="4" lg="4">
              <s-select-definition :def="1039"  :dgr="4" label="Tipo de Trabajador"  v-model="item.TypeWorker" :add="$fun.isAdmin()" />
            </v-col>
            <v-col class="s-col-form" sm="4" md="4" lg="4">
              <s-select-definition :def="1481"  label="Régimen laboral"  v-model="item.TypeLaborRegime" :add="$fun.isAdmin()" />
            </v-col>
            <v-col class="s-col-form" sm="4" md="4" lg="4">
              <s-select-definition :def="1077"  label="Cat. Ocupacional"  v-model="item.TypeOccupationalCategory" :add="$fun.isAdmin()" />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="s-col-form" sm="4" md="4" lg="4" >                
                <s-select
                      label="Profesion"
                      :items="profession"
                      autocomplete
                      clearable
                      item-text="PrnDescription"
                      item-value="PrnID"
                      v-model="item.PrnID"
                    ></s-select>
                </v-col>
            <v-col class="s-col-form" sm="4" md="4" lg="4" >                
                <s-select
                  label="Nivel Educativo"
                  :items="EducationLevels"
                  autocomplete              
                  item-text="LveDescription"
                  item-value="LveID"
                  v-model="item.LveID"
                ></s-select>
            </v-col>
            <v-col class="s-col-form" sm="4" md="4" lg="4">
              <s-select-definition :def="1071"  label="Estado civil"  v-model="item.TypeCivilStatus" :add="$fun.isAdmin()" />
            </v-col>
          </v-row>
          
          <v-row>           
            <v-col class="s-col-form" sm="4" md="4" lg="4">
              <s-select-definition :def="1070"   label="Situación especial" v-model="item.TypeSpecialSituation"  :add="$fun.isAdmin()" />
            </v-col>                       
            <v-col class="s-col-form" sm="4" md="4" lg="4">              
              <s-money-input label="Sueldo/Jornal"  v-model="item.AgtSalary" > </s-money-input>
            </v-col>
            <v-col class="s-col-form" sm="4" md="4" lg="4">
              <s-select-definition :def="1278"   label="Turno"  v-model="item.TypeTurno" :add="$fun.isAdmin()" />
            </v-col>
          
          </v-row>
          <v-row>
            <v-col class="s-col-form" sm="4" md="4" lg="4">
              <s-select-definition :def="1045"  label="Situación"  v-model="item.TypeSituation" :add="$fun.isAdmin()" />
            </v-col>
            <v-col class="s-col-form" sm="4" md="4" lg="4" v-if="item.TypeSituation == 2 ? true : false">
              <s-select-campaign-se 
                label="Campaña"
                v-model="item.CmpID"
                ref="CmpIDRef"
              />              
            </v-col>
            <v-col v-if="item.TypeSpecialSituation == 4 || item.TypeSpecialSituation == 5" class="s-col-form" sm="4" md="4" lg="4">
              <s-text
                label="Meses de Prueba"
                v-model="item.AgtMonthsProbation"
                type="number"
              />
            </v-col>
          </v-row>
          <v-row> 
            <v-col class="s-col-form" sm="4" md="4" lg="4">
              <s-date v-model="item.AgtAdmissionDate" label="Fecha de Ingreso" :clearable="true"/>
            </v-col>          
            <v-col class="s-col-form" sm="4" md="4" lg="4">
              <s-date v-model="item.AgtBeginDate" label="Inicio de Contrato" :clearable="true"/>
            </v-col>
            <v-col class="s-col-form" sm="4" md="4" lg="4">
              <s-date v-model="item.AgtEndDate" label="Fin de Contrato" :clearable="true" :min="item.AgtBeginDate"/>
            </v-col>     
          </v-row>
          <v-row>   
            <v-col class="s-col-form "   xs="4" sm="2" md="4" lg="2">
              <s-switch label="Jornada Máxima" v-model="item.AgtMaximunDay"></s-switch>
            </v-col>
            <v-col class="s-col-form " xs="4" sm="2" md="4" lg="2" >
            <s-switch
              label="Registrar Cese"
              v-model="item.ceseActivo"
            ></s-switch>    
          </v-col>                
           
            <v-col class="s-col-form" sm="4" md="3" lg="4" v-if="item.ceseActivo">
              <s-select-definition  :def="1051"   label="Motivo cese" :clearable="true" v-model="item.TypeTermination"  :disabled="!item.ceseActivo" :add="$fun.isAdmin()" />
            </v-col>
            <v-col class="s-col-form" sm="4" md="3" lg="4" v-if="item.ceseActivo">
              <s-date v-model="item.AgtTerminationDate" label="Fecha cese"  :disabled="!item.ceseActivo" />
            </v-col>          
          </v-row>
        </v-card-text>     
    </v-card>
  </template>
  
<script>
  import SSelectArea from '../../../components/Utils/SSelectArea.vue';
  import _sQuadriService from '@/services/HumanResource/hmnGang.js';
  import _sProfService from '@/services/HumanResource/ProfessionsGen.js';
  import SSelectBusinessLine from "../../../components/Utils/SSelectBusinessLine.vue";
  import _sCttoService from '@/services/HumanResource/HmnContractService.js';
  import sToolbarPerson from "@/components/Utils/RecursosHm/sToolbarAgreementPerson";
  import sEducationLevel from "@/services/HumanResource/HmnEducationalLevel.js";
  import SSelectPosition from '../../../components/Utils/sSelectPosition.vue';
  import _sWorkertService from '@/services/HumanResource/WorkerService.js';
  import _constapp from "@/plugins/const";
  import _sContractService 	from '@/services/HumanResource/HmnContractService.js';
  import _sPositionService from "@/services/General/PositionService.js";

  export default {
    name: "sDatosLaborals",
    components: { 
		  SSelectArea,
			SSelectBusinessLine,
      sToolbarPerson,
      SSelectPosition 
    },
    props: {
      visibleTitle: {
        type: Boolean,
        default: true,
      },
      full: {
        type: Boolean,
        default: false,
      },
      typeperson: {
        type: Number,
        default: 1,
      },
      addTypeSex: {
        type: Boolean,
        default: false,
      },
      addBirthDate: {
        type: Boolean,
        default: false,
      },
      addDriver: {
        type: Boolean,
        default: false,
      },
      datawork: { type: Object },
      
    },
    data() {
      return {
        dataWor:{},
        profession: [],
        EducationLevels: [],
        quadrille: [],
        Position:[],
        editing: false,
        item: {
          TypePayroll:  1,
          AreID: null,
          AreName:null,
          CceName:null,
          PstID: null,
          CmpID: 35,
          BslID: null,
          TypeWorker: 4,
          PrnID: null,
          GngID:null,
          TypeSituation: 2,
          AgtAdmissionDate: null,
          AgtBeginDate: null,
          AgtEndDate: null,
          LveID:7,
          TypeSpecialSituation:3,
          TypeTermination:null,
          TypeCivilStatus:2,
          AgtTerminationDate:null,
          TypeLaborRegime:1,
          ceseActivo: false,
          centercost:0,
          typeCategory:1,
          TypeContract:11,
          TypeTurno:1,
          TypeOccupationalCategory:4,
          AgtSalary:0,
          AgtChildrenQuantity:0,
          AgtStatus:1,
          PrsDocumentNumber:null,
          AgtMonthsProbation: 3,
          AgtServiceDescription: null,
          PstIsBascCritic:0,
          PstIsBascCriticName:"",
        },
        selectedCenterCost: 0,      
        selectedBusinessLine: null,       
        menu3: false,
        config: {},
        dialog: false,
        workert:null,
      };
    },
    inject: ['contractDates'], // Recibe las fechas del padre
    methods: {
      initialize(){
        this.getProfession();
        this.getQuadrille();
        this.getEducationLevels();
        //this.getPositiom();
        //console.log("dataWork",this.datawork);
        this.TypeEducationLevel = 1;        
			},

      // encryptSalary(salary){
      //   if (isNaN(salary)) {
      //     throw new Error("El salario debe ser un número válido");
      //   }
      //   const secretKey =_constapp._cKey;
      //   const encryptedSalary = CryptoJS.AES.encrypt(salary.toString(), secretKey).toString();
      //   return encryptedSalary;
      // },

      updateDates() {
        //console.log("Cambio de Fechas",this.contractDates);
        this.contractDates.AgtAdmissionDate = this.item.AgtAdmissionDate;
        this.contractDates.AgtEndDate       = this.item.AgtEndDate;
      },      
      getDateBeginAndEnd(){
        let obj = { AgtAdmissionDate: this.item.AgtAdmissionDate, AgtEndDate: this.item.AgtEndDate };
        return obj;
      },
      validateData(){
        let valid = true;
        //console.log("Data Laboral",this.item);

        if(this.item.NtpID==null ){
          this.$fun.alert("Por favor seleccionar una Persona","warning");
          valid = false;  
        }

        if(this.item.AreID==null ){
          this.$fun.alert("El campo Area es obligatorio","warning");
          valid = false;  
        }

        if(this.selectedCenterCost == null ){
          this.$fun.alert("El campo Centro de Costo es obligatorio","warning");
          valid = false;  
        }
        
        if(this.selectedBusinessLine==null ){
          this.$fun.alert("El campo Linea de Negocio es obligatorio","warning");
          valid = false;  
        }

        if(this.item.PstID==null ){
          this.$fun.alert("El campo Cargo es obligatorio","warning");
          valid = false;  
        }

        if(this.item.LveID == null ){
          this.$fun.alert("El campo Nivel Educativo es obligatorio","warning");
          valid = false;  
        }

        if(this.item.AgtAdmissionDate == null ){
          this.$fun.alert("Fecha inválida del campo Fecha de Ingreso ","warning");
          valid = false;  
        }

        if(this.item.AgtBeginDate == null ){
          this.$fun.alert("Fecha inválida del campo Incio de Contrato ","warning");
          valid = false;  
        }

        if(this.item.ceseActivo){
          if(this.item.TypeTermination== null){
            this.$fun.alert("Si esta activo de Registro de Cese , debe seleccionar un motivo de Cese ","warning");
            valid = false;  
          }
        }

        if(this.item.AgtEndDate == null ){
          this.$fun.alert("Fecha inválida del campo Fin de Contrato ","warning");
          valid = false;  
        }

        return valid;
      },

      clear() {
      
      },
       
      close() {
        this.$refs.document.focus();
        this.$emit("toclose", 2);
        this.clear();
      },
      save(){

      },
      getProfession(){
        let obj = {
					prnStatus: 1
				}
				_sProfService
				.list(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.profession = resp.data;
					}
				})
			},

      getEducationLevels(){
        sEducationLevel.list(this.$fun.getUserID()).then(resp => {
					if(resp.status == 200){					
						this.EducationLevels = resp.data;
            //this.TypeEducationLevel = 1;
					}
				})
      },
      getQuadrille(){
        let obj = {
					SecStatus: 1
				}
				_sQuadriService
				.list(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.quadrille = resp.data;
					}
				})
      },
      getPositiom(){
        let obj ={AreID:null, TypePosition:1 }
        _sCttoService
        .Positionlist(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.Position = resp.data;
					}
				})
      },
      getData() {
        if(this.validateData()){
          this.searchWorkertByNtpID(this.item.NtpID);

          return {
            //AgtID : null , //this.AgtID,
            AgtChildrenQuantity: this.item.AgtChildrenQuantity,// Num Hijos
            AgtBeginDate: this.item.AgtBeginDate,
            AgtEndDate: this.item.AgtEndDate,
            AgtTerminationDate: this.item.ceseActivo ? this.item.AgtTerminationDate : null,
            AgtMaximunDay: this.item.AgtMaximunDay,
            AgtSalary: this.$fun.encryptSalary(this.item.AgtSalary),
            AgtAdmissionDate: this.item.AgtAdmissionDate,
            AgtStatus: 1,
            WkrID: this.workert ? this.workert.WkrID : 0,
            TypePayroll: this.item.TypePayroll,
            AreID: this.item.AreID,
            CceID: this.selectedCenterCost,
            TypeCategory: this.item.typeCategory,
            CmpID: this.item.TypeSituation == 2 ? this.item.CmpID : null,
            GngID: this.item.GngID,
            BslID: this.selectedBusinessLine,
            TypeSituation: this.item.TypeSituation,
            TypeSpecialSituation: this.item.TypeSpecialSituation,
            TypeCivilStatus: this.item.TypeCivilStatus,
            TypeWorker: this.item.TypeWorker,
            TypeLaborRegime: this.item.TypeLaborRegime,
            TypeOccupationalCategory: this.item.TypeOccupationalCategory,
            LveID: this.item.LveID,
            PstID: this.item.PstID,
            PrnID:this.item.PrnID,
            TypeContract: this.item.TypeContract,          
            TypeTermination: this.item.ceseActivo ?  this.item.TypeTermination: null,
            TypeTurno:this.item.TypeTurno,
            AgtRenovation : 0,// Renovacion
            RqpID:0,//requerimiento de Personal
            AgtServiceDescription: this.item.AgtServiceDescription,
            AgtMonthsProbation: this.item.AgtMonthsProbation,
            //ceseActivo: this.item.ceseActivo,
            //AmsID:null,//Movimiento de Contrato
            SecStatus:1,
            UsrCreateID: this.$fun.getUserID(),        
            Supervision: this.item.TypeSpecialSituation == 3 ? 0 : 1,
            //Persona de Contrato
            NtpID: this.item.NtpID,
            workert: this.workert || null,
          };
        }
        else{
          return false;
        }
      }, 
      returnPerson(value, item) {   
        //console.log("Valueeeeee", value);
        if(value!=null){
          item.NtpFullName  = value.NtpFullName;
          item.NtpID        = value.NtpID;
          this.searchWorkertByNtpID(value.NtpID);
        }else{
          item.NtpFullName = null;
          item.NtpID = null;
        }
      },

      async searchWorkertByNtpID(ntpID) {
        try {
          // Buscar trabajador por NtpID
          const response = await _sWorkertService.searchworkertByNtpID(ntpID, this.$fun.getUserID());
          this.workert = response.data;
          
          console.log("Workert encontrado:", this.workert);

          if (!this.workert)  { this.$emit("updateContractsPast", null); return;} // Si no hay trabajador, detener la ejecución

          if (!this.workert.AgreementCE?.AgtID)  { this.$emit("updateContractsPast", null);  return;}  //No hay contratos pasados

          // Buscar contrato activo por WkrID
          const contractResponse = await _sContractService.searchContractByWkrID(this.workert.WkrID, this.$fun.getUserID());
          const contract = contractResponse.data;
          
          console.log("Contrato Activo encontrado:", contract);

          if (contract) {
            this.$fun.alert("Este trabajador tiene un contrato activo", "warning");
            this.$emit("updateContractsPast", null); 
            return; // Si tiene contrato, no seguir con la búsqueda de contratos pasados
          }

        

          // Buscar contratos pasados
          const workerPersonResponse = await _sContractService.workerPerson(
            this.workert.WkrID, 
            this.workert.AgreementCE?.AgtID || null, 
            this.$fun.getUserID()
          );

          const contractWokert = workerPersonResponse.data;

          if (workerPersonResponse.status === 200) {
            console.log("Contratos pasados:", workerPersonResponse.data);
          }

          if(contractWokert){
            this.contractsPast = workerPersonResponse.data;

            // Emitir el evento a la vista padre (Contract)
            this.$emit("updateContractsPast", this.contractsPast);

          }

        } catch (error) {
          console.error("Error en searchWorkertByNtpID:", error);
          const errorMessage = error.response?.data?.Message || "Ocurrió un error inesperado";
          this.$fun.alert(`Error: ${errorMessage}`, "error");
        }
      },
    },
    watch: {
     'item.CmpID'(){
      //console.log("itemsssss",this.$refs.CmpIDRef );
     },
     'item.PstID'(){     
        if(!this.item.PstID) {   
          this.item.PstIsBascCritic = 0;
          this.item.PstIsBascCriticName = "";          
          return;
        };

        _sPositionService.searchByPstID(this.item.PstID,this.$fun.getUserID()).then(x=>{         
          let postion = x.data;
          this.item.PstIsBascCritic = postion.PstIsBascCritic;
          this.item.PstIsBascCriticName = postion.TypeCriticName;
        });
      
      },
      'item.AgtAdmissionDate'(){        
        this.updateDates();
      },
      'item.AgtEndDate'(){      
        this.updateDates();
      }
     
    },
  created() {
  if (this.datawork && typeof this.datawork === 'object') {
    this.dataWor = Object.assign({}, this.datawork);
  }
    this.initialize();
  },
  mounted() {
    //console.log("Items", this.$refs.CmpIDRef);
    //this.$refs.CmpIDRef
  },

  };
  </script>
  