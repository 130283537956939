<template>
  <div>
    <s-toolbar-crud
      @configurationEvent="configurationEvent()"
      :configuration="configuration"
      :view="view" v-if='!noToolbar'
      :searchInput="searchInput"
      style="margin-bottom:3px;"
      ref="toolbar"
      class="mb-1"
      @load="loadEvent()"
      :load="load"
      :transfer="transfer"
      @transfer="transferEvent()"
      :enable="enable"
      @enable="enableEvent()"
      :saveList="saveList"
      :search="!noSearch"
      :formPermanent="formPermanent"
      :title="title"
      :doubleClick="doubleClick"
      @showFormEvent="showFormDefEvent($event)"
      @EditEvent="EditEvent()"
      @addEvent="AddEventClick()"
      @activePrd="activePrd()"
      :colorToolbar="colorToolbar"
      :noDark="noDark"
      @excel="reportExcel()"
      @restartEntity="restartEntity($event)"
      @refresh="refresh($event)"
      @save="saveEvent()"
      :save="save"
      :service="config.service"
      @searchEvent="searchEvent($event)"
      :entity="row"
      :show="showForm"
      :add="add"
      :edit="edit"
      :excel="excel" :off=off @off='$emit("off",row)'
      @edit="editEvent()"
      :pdf="pdf"
      @pdf="pdfEvent()"
      :remove="remove"
      @delete="deleteEvent($event)"
      :deleteNoFunction="deleteNoFunction"
      :addNoFunction="addNoFunction"
      @add="addEvent()"
      :restore="restore"
      @removed="deleteExecuted()"
      @approved="$emit('approved')"
      @disapproved="$emit('disapproved')"
      :approve="approve"
      :close="close"
      @close="$emit('close')"
      :print="print"
      @print="$emit('print')"
      @sync="$emit('sync')"
      :sync="sync"
      :btnsearch="btnsearch"
      @btnsearch="searchBtn()"
      :btnsearchKey="btnsearchKey"
      :loading="loading"
    >
      <template v-slot:options>
        <slot name="options"> </slot>
      </template>
    </s-toolbar-crud>

    <v-card elevation="0">

        
      <div v-if="!showForm">
         <slot style="z-index:-999;" name="filter"> </slot> 
      </div>

      <v-container v-else class="py-2 px-0 mb-2" >
        <v-row>
          <v-col >
            <slot :item="row"></slot>
          </v-col>
        </v-row> 

        <v-row v-if="formPermanent" class="px-3">
          <v-col cols="6" class="pb-2 pt-3">
            <v-btn small block text @click="btnAdd()">Nuevo </v-btn>
          </v-col>
          <v-col cols="6" class="pb-2 pt-3">
            <v-btn small block color="primary" @click="btnSave()">
              Guardar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <div elevation="0" rounded>
        <v-data-table 
          @toggle-select-all="toggleSelectAll"
          :show-select="!singleRow"
          fixed-header=""
          :disable-sort="!sortable"
          class="elevation-0 scroll"
          @click:row="rowClick"
          @item-selected="selectedCheckSlot($event)"
          @dblclick:row="doubleClickFunc"
          :height="$vuetify.breakpoint.xs?'auto': height"
          :hide-default-footer="noFooter"
          :headers="config.headers"
          selectable-key="isSelectable"
          v-model="selected"
          :items="desserts"
          :item-key="propID"
          :options.sync="options"
          dense
          :footer-props="{
            showCurrentPage: true,
            showFirstLastPage: true,
            itemsPerPageOptions: itemsPerPage,
          }"
          :items-per-page="selectItemsPerPage"
          :server-items-length="total"
          :loading="loading"
          loading-text="Cargando"
          no-data-text="Sin Datos"
          :single-expand="true"
          :expanded.sync="expanded"
          :mobile-breakpoint="mobile"
          :group-by="groupBy"
          :show-group-by="showGroupBy"
        >
        <template v-slot:footer.prepend v-if="showCount">
          <div class="pa-2">
            <v-icon>mdi-bookmark-check-outline</v-icon> {{ selected.length }}
          </div>
        </template>
          <template v-slot:expanded-item="{ headers, item }" v-if="expandDetail">
            <td :colspan="headers.length" style="margin:0px;padding:0px;">
              <slot name="expanded" :item="item"> </slot>
            </td>
          </template>
          <!-- SLOT PROPIEDADES-->
          <template v-for="p in getProps()" v-slot:[getNameSlot(p)]="{ item }">
            <slot :name="p" :row="item">{{ formatItem(item[p], configModel[p]) }} </slot>
          </template>
        </v-data-table>
      </div>
    </v-card>

    <v-dialog v-model="dialogScrud" persistent max-width="900px">
      <v-card>
        <s-toolbar label="Editar Tabla" dark color="#8e8f91" close @close="closeConfigurationEvent()" edit @edit="configurationEditEvent()">
          <v-tooltip bottom="">
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on" small @click="restoreConfiguration()"><v-icon style="font-size:16px;">mdi-cog-counterclockwise</v-icon></v-btn>
            </template>
            <span>Restablecer a Configuración por Defecto</span>
          </v-tooltip>
        </s-toolbar>
        <v-container>
          <div style="display: flex; width: 100%; flex-direction: column; gap: 10px;">
            <div style="width: 100%;">
              <div style="width: 100%; background-color: #8e8f91; color: #fff; padding: 5px 10px; border-radius: 2px;"><h4>Lista Visible</h4></div>
              <div style="overflow: hidden; overflow-x: auto;">
                <draggable :move="checkMove" :list="configHeader" group="scrudDraggable" draggable=".item">
                  <transition-group type="transition" style="display: flex;">
                    <div
                      v-for="element in configHeader"
                      :key="element.value"
                      :style="{
                        padding: '10px',
                        backgroundColor: '#a1dffc',
                        border: '1px solid #8e8f91',
                        cursor: configurationEdit === true ? 'default' : 'grab'
                      }"
                      :class="configurationEdit === false ? 'item' : ''"
                      @contextmenu.prevent="editConfigValue(element.value, $event)"
                    >
                      <p v-if="!configurationEdit" style="white-space: nowrap; min-width: 70px; margin: 0px; user-select: none;">
                        {{ element.text }}
                        <v-icon v-if="element.draggable == false">mdi-swap-horizontal-bold</v-icon>
                      </p>
                      <s-text v-else v-model="element.text" style="min-width: max-content;"></s-text>

                      <div v-if="activeTooltip === element.value" style="position: fixed; z-index: 1000;" :style="{ top: tooltipY + 'px', left: tooltipX + 'px' }">
                        <v-card>
                          <s-toolbar label="Tipo de Dato" dark color="#8e8f91" save @save="notBlurInput(element.value)"></s-toolbar>
                          <v-container>
                            <s-select-definition
                              :def="1544"
                              label=""
                              v-model="selectDefinition"
                              />
                          </v-container>
                        </v-card>
                      </div>

                    </div>
                  </transition-group>
                </draggable>
              </div>
            </div>
            <div style="width: 100%;">
              <div style="width: 100%; background-color: #8e8f91; color: #fff; padding: 5px 10px; border-radius: 2px;"><h4>Items Disponibles</h4></div>
              <div style="overflow: hidden; overflow-x: auto;">
                <draggable :move="checkMove" :list="remainingHeaders" group="scrudDraggable" draggable=".item" :disabled="remainingHeaders.length == 0">
                  <transition-group type="transition" style="display: flex;">
                    <div
                      :style="{
                        padding: '10px',
                        backgroundColor: '#a1dffc',
                        border: '1px solid #8e8f91',
                        cursor: configurationEdit === true ? 'default' : 'grab',
                        opacity: configurationEdit === true ? 0.5 : 1
                      }"
                      v-for="element in remainingHeaders"
                      :key="element.value"
                      :class="configurationEdit === false ? 'item' : ''"
                    >
                      <p style="white-space: nowrap; min-width: 70px; margin: 0px; user-select: none;">{{ element.text }}</p>
                    </div>
                  </transition-group>
                </draggable>
              </div>
            </div>
          </div>
          <br>
          <v-data-table dense :headers="configHeader" :items="dessertsFirst" class="elevation-1"></v-data-table>
          <s-switch label="Aplicar para Todos" v-model="SrdIsAdmin" :true-value="1" :false-value="0" />
          <v-btn block color="primary" class="mt-4" @click="saveOrder"><v-icon>mdi-content-save-cog-outline</v-icon>&nbsp;Guardar Configuración</v-btn>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import draggable from "vuedraggable";
import SCrud from "../../services/General/SCrudService";
import _sDefinition from "@/services/General/DefinitionService.js";

export default {
  components: { draggable },
  name: "SCrud",
  props: {
    configuration: {
      type: Boolean,
      default: false,
    },
    nameScrud: { type: String, default: "" },
    showCount: { type: Boolean, default: false },
    selectItemsPerPage: {type: Number, default: 25},
    mobile: {type: Number, default: 600},
    approve: { type: Boolean, default: false },
    noFull: { type: Boolean, default: false },
    noEdit: { type: Boolean, default: false },
    save: { type: Boolean, default: false },
    view: { type: Boolean, default: false },
    //expandir detalles
    expandDetail: { type: Boolean, default: false },
    //RESTAURAR EL ITEM HACE STAAATUS IGUAL A 1
    restore: { type: Boolean, default: false },
    deleteNoFunction: { type: Boolean, default: false },
    searchInput: { type: Boolean, default: false },
    //desabilita por accion del boton agregar de abrir el formulario: emite evento add(item)
    addNoFunction: { type: Boolean, default: false },
    config: { type: Object, default: null },
    noToolbar: { type: Boolean, default: false },
    colorToolbar: { type: String, default: "primary" },
    height: { type: String, default: "320" },
    noSearch: { type: Boolean, default: false },
    noDark: { type: Boolean, default: false },
    noFooter: { type: Boolean, default: false },
    load: { type: Boolean, default: false },
    transfer: { type: Boolean, default: false },
    enable: { type: Boolean, default: false },
    close: { type: Boolean, default: false },
    off: {
      type: Boolean,
      default: false,
    },
    filter: { type: Object, default: null },
    title: { type: String, default: "" },
    refreshTable: { type: Boolean, default: false },
    add: { type: Boolean, default: false },
    sync: { type: Boolean, default: false },
    btnsearch: { type: Boolean, default: false },
    edit: { type: Boolean, default: false },
    remove: { type: Boolean, default: false },
    excel: { type: Boolean, default: false },
    pdf: { type: Boolean, default: false },
    print: { type: Boolean, default: false },
    parentID: { type: Number, default: 0 },
    //SI ES SELECCION UNICA
    singleRow: { type: Boolean, default: true },
    //GUARDA O EDITA MULTIPLES FILAS SELECCIONADAS..ACTUALMENTE SOLO ESTA HABILITADO PARA ELIMINAR REGISTROS MASIVOS
    saveList: { type: Boolean, default: false },
    sortable: { type: Boolean, default: false },
    noBorder: { type: Boolean, default: false },
    //FORMULARIO DE AGREGAR O EDITAR NO SE OCULTA
    formPermanent: { type: Boolean, default: false },
    //DESABILITA LA ACCION DE QUE EL DOBLECLICK EJECUTE LA ACCION DEL BOTON DE EDITAR
    noDoubleClick: { type: Boolean, default: false },
    //DESABILITA EL MENSAJE DE CONFIRMACIÓN AL AGREGAR O EDITAR O ELIMINAR
    noConfimationSave: { type: Boolean, default: false },
    groupBy: null,
    showGroupBy: {type: Boolean, default: false},
    rowDefault : {type: Boolean, default: true}, //enviar false para que no se seleccione fila por defecto
    itemsPerPage: {type: Array, default: () => [25, 50, 100, 200, -1]},
  },
  data: () => ({
    // itemsPerPage:[25, 50, 100, 200, -1],
    expanded: [],
    //peticion actual de paginacion del crud
    request: null,
    doubleClick: false,
    showForm: false,
    messageError: [],
    row: null,
    total: 0,
    loading: true,
    options: {},
    search: "",
    selected: [],
    desserts: [],
    parametersPagination: {
      filter: {},
      draw: 1,
      start: 0,
      length: 10,
      order: [{ dir: "asc", column: "" }],
      searchText: "",
    },
    btnsearchKey: false,
    dialogScrud: false,
    configHeader: [],
    configHeaderBackup: [],
    configurationEdit: false,
    SrdIsAdmin:0,
    configModel: {},
    configModelBackup: {},
    activeTooltip: null,
    tooltipX: 0,
    tooltipY: 0,
    selectDefinition: null,
    itemSelectDefinition: []
  }),
  watch: {
    refreshTable() { 
      this.selected = [];
      this.getDataFromApi().then((data) => {
        (this.desserts = data.items), (this.total = data.total);
        if (this.desserts.length > 0) {
          this.refresh(this.desserts[0]);
        }
      });
    },
    filter: {
      handler() {
        // console.log("filter", this.filter);
        // console.log("CRUD parametersPagination", this.parametersPagination.filter);
         
        if (this.btnsearch) {
          this.btnsearchKey = true;
          return;
        }
        this.options.page=1;
        this.selected = [];
        if (!this.noToolbar) this.$refs.toolbar.showForm(false, false);
        this.getDataFromApi().then((data) => {
          this.desserts = data.items;
          this.total = data.total;
          if (this.desserts.length > 0) this.refresh(this.desserts[0]);
        });
      },
      deep: true,
    },
    search() { 
      this.debouncedGetAnswer();
    },
    options: {
      handler() { 
        this.getDataFromApi().then((data) => {
          this.desserts = data.items;
          this.total = data.total;

          if (this.desserts.length > 0) this.refresh(this.desserts[0]);
        });
      },
    },
    configHeader: {
      deep: true,
      handler(newHeaders) {
        newHeaders.forEach((element) => {
          if (!this.configModel.hasOwnProperty(element.value) && element.value !== "ID") {
            this.$set(this.configModel, element.value, "");
          }
        });

        if (this.configModel.hasOwnProperty("ID")) {
          this.$set(this.configModel, "ID", this.configModel["ID"])
        }
      }
    }
  },
  filters: {},
  methods: {
    searchBtn(){
      // console.log("searchBtn", this.filter);
      this.btnsearchKey = false;
      this.options.page=1;
      this.selected = [];
      if (!this.noToolbar) this.$refs.toolbar.showForm(false, false);
      this.getDataFromApi().then((data) => {
        this.desserts = data.items;
        this.total = data.total;
        if (this.desserts.length > 0) this.refresh(this.desserts[0]);
      });
      this.$emit('btnsearch')
    },
    EditEvent(){
      this.$emit("EditEvent", this.selected);
    },
    AddEventClick(){
      this.$emit("addEvent");
    },

    activePrd(){
      this.$emit("activePrd");
    },
    formatItem(value, prop) {
      let val = value;
      if (prop == "date") val = this.$fun.formatDateView(val);
      if (prop == "time") val = this.$fun.formatTimeView(val);
      if (prop == "datetime") val = this.$fun.formatDateTimeView(val);
      return val;
    },
    selectedCheckSlot(obj) {
      let any = obj.item;
      if (
        this.selected.find((x) => {
          return x == any;
        }) != null
      )
        this.selected = this.selected.filter((x) => {
          return x != any;
        });
      else {
        this.selected.push(any);
        this.row = Object.assign({}, any);
      }
      this.$emit("itemSelected", any);
      this.$emit("rowSelected", this.selected);
    },
    loadEvent() {
      this.$emit("load");
    },

    transferEvent() {
      this.$emit("transfer");
    },
    enableEvent() {
      this.$emit("enable");
    },

    deleteExecuted() {
      this.$emit("removed");
    },
    deleteEvent(item) {
      if(this.singleRow)
      this.$emit("delete", item);
      else   this.$emit("delete", this.selected);
    },
    pdfEvent() {
      if (this.row[this.propID] != 0) this.$emit("pdf", this.row);
      else this.$emit("pdf", null);
    },
    getProps() {
      let r = [];
      for (var prop in this.configModel) r.push(prop);
      return r;
    },
    getNameSlot(p) {
      return "item." + p;
    },
    btnAdd() {
      this.$refs.toolbar.showForm(true, false);
      this.$emit("clearForm");
    },
    btnSave() {
      this.$refs.toolbar.saveEvent();
    },
    
    removed(obj) {
      if (this.saveList) obj = obj[0];
      this.refresh(obj);
      this.$emit("removed");
    },
    doubleClickFunc: function(mouse, row) {
      if (this.edit) this.doubleClick = !this.doubleClick;
      this.$emit("doubleClick", row.item);
      this.$emit("dblClick", row.item);
    },
    refresh(obj) {
      this.expanded = [];
      if (obj == null) {
        this.getDataFromApi().then((data) => {
          this.desserts = data.items;
          this.total = data.total;
          if (this.desserts.length > 0) {
            this.selected = this.formPermanent ? [] : [this.desserts[0]];
            this.row = Object.assign({}, this.desserts[0]);
            this.$emit("rowSelected", this.selected);
          }
        });
      } else if (this.propID != null) {
        let ob = this.desserts.find((x) => obj[this.propID] == x[this.propID]);
        /* console.log("ob",ob); */
        for (var prop in ob) {
          ob[prop] = obj[prop];
        }

        if(this.rowDefault){
          this.selected = this.formPermanent ? [] : [ob];
        }
          
        this.row = Object.assign({}, ob);
        this.$emit("rowSelected", this.selected);
      }
      if (this.formPermanent){ this.restart();}
    },
    searchEvent(search) {
      this.parametersPagination.searchText = search;
      if (this.btnsearch) {
        this.btnsearchKey = true;
        return;
      }
      
      this.options.page = 1;
      this.getDataFromApi().then((data) => {
        this.desserts = data.items;
        this.total = data.total;
        if (this.desserts.length > 0) this.refresh(this.desserts[0]);
      });
    },

    saveExecute() {
      return new Promise((resolve, reject) => {
      let objsave = this.row;
      objsave.UsrCreateID = objsave[this.propID] == null||objsave[this.propID] == 0 ? this.$fun.getUserID() : objsave.UsrCreateID;
      objsave.UsrUpdateID = objsave[this.propID] != null ? this.$fun.getUserID() : objsave.UsrCreateID;
      this.config.service.save(objsave, this.$fun.getUserID()).then((response) => {
           resolve(response);
        if (response.status == 200) {
          this.$emit("returnObject", response.data);
          let obj = Object.assign({}, this.row);
          if (obj[this.propID] == 0) {
            this.getDataFromApi().then((data) => {
             
          
              this.desserts = data.items;
              this.total = data.total;
              if (this.desserts.length > 0) this.refresh(this.desserts[0]);
              this.showForm = false || this.formPermanent;
              this.$fun.alert("Agregado Correctamente", "success");
            });
          } else { 
            this.refresh();
            this.showForm = false || this.formPermanent;
            this.$fun.alert("Actualizado Correctamente", "success");
          }
        }
        
      },
       (e)=>{
          reject(e);
      })
      })
      
    },
    saveEvent() {
      if (this.save) {
        this.$emit("save", this.row);
        return;
      }
     
      let obj = Object.assign({}, this.row);
      for (var prop in this.configModel) if (this.configModel[prop] == "time") obj[prop] = this.$moment(new Date()).format("L") + this.row[prop];

      this.row.save = () => {
           return new Promise((resolve, reject) => {

        if (this.noConfimationSave) this.saveExecute().then((r)=>{
resolve(r);
        },(e)=>{
reject(e)
        });
        else
          this.$fun.alert("Seguro de Guardar?", "question").then((val) => {
            if (val.value) this.saveExecute().then((r)=>{
resolve(r);
        },(e)=>{
reject(e)
        });
          });
           })
      };
      this.$emit("save", this.row);
    },
    reportExcel() {
      if(this.row)
      this.parametersPagination.filter[this.propID]=this.row.OrdID;
      this.config.service.excel(this.parametersPagination, this.$fun.getUserID(), this.row).then((r) => {
        this.$fun.downloadFile(r.data, this.$const.TypeFile.EXCEL, this.title);
      });
    },
    rowClick: function(item, row) {
      if (this.singleRow) {
        this.expanded = [item];
        this.selected = [item];
        this.row = Object.assign({}, item);
      } else {
        if (
          this.selected.find((x) => {
            return x == item;
          }) != null
        )
          this.selected = this.selected.filter((x) => {
            return x != item;
          });
        else {
          this.selected.push(item);
          this.row = Object.assign({}, item);
        }
      }
      this.$emit("rowSelected", this.selected);
      if(this.noEdit) this.btnAdd();
    },
    restartEntity(entity) {
      if (!this.formPermanent) {
        this.row = entity;
        this.selected = [entity];
        this.$emit("rowSelected", this.selected);
      }
    },
    cancelRequest() {
      this.request.cancel();
      this.request = null;
    }, 
    areObjectsEqual(objA, objB) {
      const keysA = Object.keys(objA);
      const keysB = Object.keys(objB);

      if (keysA.length !== keysB.length) return false;

      for (let key of keysA) {
          if (objA[key] !== objB[key]) return false;
      }

      return true;
    },
    getDataFromApi() {
      // console.log("CRUD parametersPagination compa", this.parametersPagination.filter == this.filter);
      // if ( this.areObjectsEqual( this.parametersPagination.filter, this.filter) ) {
      //   return new Promise((resolve, reject) => {
      //     reject({})
      //   });
      // }
      // console.log("CRUD filter", this.filter);
      // console.log("CRUD parametersPagination", this.parametersPagination.filter);
          
      //samir
      // this.filterTemp = this.filter;
      // console.log("this.filterTemp", this.filterTemp);

      if (this.request) this.cancelRequest();
      const axiosSource = axios.CancelToken.source();
      //  console.log("----------------------------------------------------");
      //  console.log(axiosSource.token);
      this.request = { cancel: axiosSource.cancel, msg: "Loading..." };

      this.loading = true;
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        this.parametersPagination.start = (this.options.page - 1) * this.parametersPagination.length;
        this.parametersPagination.filter = this.filter;
        this.parametersPagination.length = itemsPerPage;
        if (this.options.sortBy.length > 0)
          this.parametersPagination.order = [
            {
              column: this.options.sortBy[0],
              dir: this.options.sortDesc[0] ? "desc" : "asc",
            },
          ];
        this.config.service.pagination(this.parametersPagination, this.$fun.getUserID(), axiosSource).then((response) => {
          this.selected = [];
          this.$emit("rowSelected", this.selected);
      
          this.restart();
          let items = response.data.data;
          items.forEach((element) => {
            try {
              for (var prop in this.configModel)
                if (this.configModel[prop] == "date")
                  element[prop] = element[prop] == null ? "" : this.$moment(element[prop]).format(this.$const.FormatDateDB);
                else if (this.configModel[prop] == "time")
                  element[prop] = element[prop] == null ? "" : this.$moment(element[prop]).format(this.$const.FormatTimeDB);
                else if (this.configModel[prop] == "time2")
                  element[prop] = element[prop] == null ? "" : this.$moment(element[prop]).format(this.$const.FormatTimeShortView);
                else if (this.configModel[prop] == "decimal")
                  element[prop] = element[prop] == null ? "" :element[prop].toFixed(2) ;
                else if (this.configModel[prop] == "decimal4")
                  element[prop] = element[prop] == null ? "" :element[prop].toFixed(4) ;
                else if (this.configModel[prop] == "datetime")
                  element[prop] = element[prop] == null ? "" : this.$moment(element[prop]).format(this.$const.FormatDateTimeDB);
                else if (this.configModel[prop] == "currency")
                  element[prop] = element[prop] == null ? "" :  new Intl.NumberFormat("es-PE", { 
                      minimumFractionDigits: 2, 
                      maximumFractionDigits: 2,
                      // style: "unit",
                      // unit: "KGM",
                  }).format(element[prop]);
            } catch (error) {
              let col= (this.itemSelectDefinition.find(item => item.DedAbbreviation == this.configModel[prop]) || {}).DedDescription || '';
              let col2 = this.configHeader.find((x) => x.value == prop);
              this.$fun.alert(`Error en ${col2.text} por el formato ${col}`, 'warning');
            }
                
          });
          const total = response.data.recordsTotal;
          this.$emit("getDataRefresh", items);
           
          this.$emit("getFullData", response.data);
          if (!this.formPermanent) this.showForm = false;
          setTimeout(() => {
            this.loading = false;
            resolve({ items, total });
          }, 0);
        });
      });
    },
    toggleSelectAll({ items, value }) {
      if (value) this.selected = items;
      else this.selected = [];
      this.$emit("rowSelected", this.selected);
    },
    getAnswer() {},
    restartCreated() {
      this.selected = [];
      
      let r = {};
      for (var prop in this.configModel) {
        if (this.configModel[prop] == "date") {r[prop] =null;
       
        }
        else 
        if (this.configModel[prop] == "string") r[prop] = "";
        else if (this.configModel[prop] == "number") r[prop] = 0;
        else if (this.configModel[prop] == "ID") r[prop] = 0;
        else if (this.configModel[prop] == "time") r[prop] = "00:00";
        else if (this.configModel[prop] == "boolean") r[prop] = 0;
        else r[prop] = "";
        r.SecStatus = null;
      }

      this.row = Object.assign(r, {});
    },
    restart() {
      this.row=null;
      this.selected = [];
      
      let r = {};
      for (var prop in this.configModel) {
        if (this.configModel[prop] == "date") {r[prop] =null;
       
        }
        else 
        if (this.configModel[prop] == "string") r[prop] = "";
        else if (this.configModel[prop] == "number") r[prop] = 0;
        else if (this.configModel[prop] == "ID") r[prop] = 0;
        else if (this.configModel[prop] == "time") r[prop] = "00:00";
        else if (this.configModel[prop] == "boolean") r[prop] = 0;
        else r[prop] = "";
        r.SecStatus = null;
      }

      this.row = Object.assign(r, {});
    },
    addEvent() {
        //alert("nuevo")
      if (this.row[this.propID] != 0) this.$emit("add", this.row);
      else this.$emit("add", null);
    },
    editEvent() {
      //alert("sdfsdfds");
      if (this.row[this.propID] != 0) this.$emit("edit", this.row);
      else this.$emit("edit", null);
    },
    showFormDefEvent(options) {
      if (options.add) {
  
        this.restart();
        this.$emit("rowSelected", this.selected);
      }
      this.showForm = options.add || options.edit || this.formPermanent;
    },
    checkMove(event) {
      if (event.draggedContext.element.draggable === false && event.to !== event.from) {
        return false;
      }
      return true;
    },
    configurationEvent() {
      this.dialogScrud = true;
      this.configHeaderBackup = [...this.configHeader];
      this.configModelBackup = {...this.configModel};
      this.configurationEdit = false;
    },
    closeConfigurationEvent() {
      this.dialogScrud = false;
      this.configHeader = this.configHeaderBackup;
      this.configModel = this.configModelBackup;
    },
    saveOrder() {
      const obj = {};

      if(!this.nameScrud){
        this.$fun.alert("El Scrud no tiene Nombre", "warning");
        return;
      }
      obj.SrdNameScrud = this.nameScrud;

      if(this.configHeaderBackup.length == 0){
        this.$fun.alert("Arreglo original no contiene items", "warning");
        return;
      }
      obj.SrdOriginalArrangement = JSON.stringify(this.configHeaderBackup);

      if(this.configHeader.length == 0){
        this.$fun.alert("Arreglo personalizado no contiene items", "warning");
        return;
      }
      obj.SrdCustomArrangement = JSON.stringify(this.configHeader);

      // if(JSON.stringify(this.configHeader) == JSON.stringify(this.configHeaderBackup)){
      //   this.$fun.alert("No se han realizado cambios", "warning");
      //   return;
      // }

      obj.SrdCustomModel = JSON.stringify(this.configModel);
      obj.UsrCreateID = this.$fun.getUserID();
      obj.SrdIsAdmin = this.SrdIsAdmin;
      obj.SecStatus = 1;

      SCrud.save(obj, this.$fun.getUserID()).then(resp => {
        if (resp.status == 200) {
          this.$fun.alert("Configuración guardada correctamente", "success");
          this.getConfigHeader();
          this.dialogScrud = false;
        }
      })
    },
    configurationEditEvent() {
      if (this.configurationEdit){
        this.configurationEdit = false;
      }else{
        this.configurationEdit = true;
      }
    },
    getConfigHeader(){
      const ObjFilterScrud = {
        SrdNameScrud : this.nameScrud,
        UsrCreateID : this.$fun.getUserID()
      }

      SCrud.list(ObjFilterScrud, this.$fun.getUserID()).then(resp => {
        if (resp.status == 200) {
          if(resp.data.length > 0){
            this.configHeader = JSON.parse(resp.data[0].SrdCustomArrangement);
            this.configModel = JSON.parse(resp.data[0].SrdCustomModel);

            let configHeaderScrud = this.config.headers;
            let configHeaderOrigen = JSON.parse(resp.data[0].SrdOriginalArrangement);
            let configHeaderCustom = JSON.parse(resp.data[0].SrdCustomArrangement);

            let configExtra = configHeaderScrud.filter(origen =>
              !configHeaderOrigen.some(scrud => scrud.value === origen.value)
            );

            if(configExtra.length > 0){
              for (let extra of configExtra) {
                configHeaderCustom.push(extra);
              }
              this.configHeader = configHeaderCustom;
        
              const obj = {};
              obj.SrdNameScrud = this.nameScrud;
              obj.SrdOriginalArrangement = JSON.stringify(configHeaderScrud);
              obj.SrdCustomArrangement = JSON.stringify(this.configHeader);
              obj.SrdCustomModel = JSON.stringify(this.configModel);
              obj.UsrCreateID = this.$fun.getUserID();
              obj.SrdIsAdmin = this.SrdIsAdmin;
              obj.SecStatus = 1;

              SCrud.save(obj, this.$fun.getUserID()).then(resp => {
                if (resp.status == 200) {
                  // console.log("Configuración guardada correctamente", resp);
                }
              })
            }

            this.searchBtn();
          }
        }
      });
    },
    restoreConfiguration(){
      this.$fun.alertFull("¿Seguro de restablecer la configuración?", "question")
				.then(async r => {
					if (r.value) {
            const obj = {};
            obj.SrdNameScrud = this.nameScrud;
            obj.SrdOriginalArrangement = JSON.stringify(this.configHeaderBackup);
            obj.SrdCustomArrangement = JSON.stringify(this.configHeader);
            obj.UsrCreateID = this.$fun.getUserID();
            obj.SrdIsAdmin = this.SrdIsAdmin;
            obj.SecStatus = 0;
      
            SCrud.save(obj, this.$fun.getUserID()).then(resp => {
              if (resp.status == 200) {
                // console.log("Configuración restaurada correctamente", resp);
                this.$fun.alert("Configuración restaurada correctamente", "success");
                this.configHeader = this.config.headers.map(item => ({
                  ...item,
                  draggable: item.draggable != false
                }));
                this.dialogScrud = false;
              }
            })
					}
				});
    },
    notBlurInput(key){
      this.activeTooltip = null;
      this.searchBtn();
      this.configModel[key] = (this.itemSelectDefinition.find(item => item.DedValue == this.selectDefinition) || {}).DedAbbreviation || '';
    },
    editConfigValue(key, event) {
      if(this.remainingHeaders.length == 0){
        this.$fun.alert("Necesita tener almenos una fila para modificar el tipo de dato", "warning");
        return;
      }
      this.tooltipX = event.clientX;
      this.tooltipY = event.clientY;
      this.selectDefinition = null;

      if(!this.configModel[key] && this.selectDefinition == null){
        this.selectDefinition = 1;
      }else{
        const data = this.configModel[key];
        this.selectDefinition = (this.itemSelectDefinition.find(item => item.DedAbbreviation == data) || {}).DedValue || null;
      }

      if (this.configModel[key] != 'ID') {
        this.activeTooltip = key;
      }else{
        this.activeTooltip = null;
        this.$fun.alert("No se puede editar el ID", "warning");
        return;
      }
    },
  },
  computed: {
    propID() {
      for (var prop in this.configModel) if (this.configModel[prop] == "ID") return prop;
      return null;
    },
    remainingHeaders() {
      if (!this.desserts.length) return [];

      const dessertKeys = Object.keys(this.desserts[0]);

      const usedValues = this.configHeader.map(header => header.value);

      const unusedKeys = dessertKeys.filter(key => !usedValues.includes(key));

      return unusedKeys.map(key => ({
        text: key,
        value: key
      }));
    },
    dessertsFirst() {
      return this.desserts.length ? [this.desserts[0]] : [];
    }
  },
  mounted() {
    // console.log("this.selectItemsPerPage", this.selectItemsPerPage);
    this.parametersPagination.IsLevelAdmin = this.$fun.getSecurity().IsLevelAdmin ? 1 : 0;
    this.showForm = this.formPermanent;
  },
  created() {
    this.configHeader = this.config.headers.map(item => ({
      ...item,
      draggable: item.draggable != false
    }));

    this.configModel = this.config.model? { ...this.config.model } : {};
    if(this.nameScrud != ""){
      this.getConfigHeader();
    }
    
    if(this.noFull)
      this.itemsPerPage=[25, 50, 100, 200],
      this.restart();
    let filterHeaders = [];
    this.config.headers.forEach((element) => {
      if (element.IsLevelAdmin == true) {
        if (this.$fun.getSecurity().IsLevelAdmin) filterHeaders.push(element);
      } else filterHeaders.push(element);


      if(this.configModel[element.value]=='decimal')
        element.align='end';
    });
    this.config.headers = filterHeaders;
  
    this.debouncedGetAnswer = _.debounce(this.getAnswer, 500);

    // DATOS DE DEFINICIÓN
    const objSelectDefinition = {};
    objSelectDefinition.defID = 1544;
    objSelectDefinition.dgrID = 0;
    objSelectDefinition.requestID = this.$fun.getUserID();
    this.$http
      .post(
        _sDefinition.definitiongroupURL(),
        {},
        {
          params: objSelectDefinition,
        }
      )
      .then((r) => {
        if (r.status == 200) {
          this.itemSelectDefinition = r.data;
        }
      });
  },
  destroyed() {},
};
</script>