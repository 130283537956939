<template>
    <v-card elevation="0">
      <v-container style="margin: auto; " >
        <v-row>
          <v-col cols="8">
            <v-card dense >
                <v-card-title class="text-h7" style="font-weight: bold;">
                    <v-row>
                    <v-col>
                        Datos Personales
                    </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text style="padding-bottom: 30px; margin-bottom: 2px;">
                  <v-row style="margin-left: 5px ;" justify="center" >
                    <v-col cols="6" sm="3" md="3" lg="3" >	            
                      <s-select-definition :def="1036" label="Tipo Documento" v-model="TypePersonDocument" returnObject readonly/>
                    </v-col>
                    <v-col   cols="6" sm="3" md="3" lg="3">			
                      <s-text
                        label="Documento*"
                        readonly
                        number
                        ref="document"
                        v-model="person.GenPerson.PrsDocumentNumber"
                      />
                    </v-col>
                    <v-col  cols="6" sm="6" md="6" lg="6" v-if="typeperson == 1">			          
                      <s-text
                        ref="textNtpName"
                        label="Nombre*"
                        :rules="[
                          v => !!v || 'El campo es obligatorio.',
                          v => v.length >= 3 || 'Debe tener al menos 3 caracteres'
                        ]"
                        
                        v-model.trim="person.NtpName"
                        @input="handleInput($event)"
                        :autofocus="person.GenPerson.PrsDocumentNumber.length > 0"
                        :uppercase="true"
                        readonly
                      />
                    </v-col>
                    <v-col cols="6" sm="6" md="6" lg="6" v-if="typeperson == 2">
                      <s-text ref="textJrpName" label="Nombre Juridico*" v-model="person.JrpName" :autofocus="person.GenPerson.PrsDocumentNumber.length > 0" />
                    </v-col>
                  </v-row>
            
                  <v-row style="margin-left: 5px; " justify="center" v-if="typeperson == 1" >
                      <v-col cols="6" sm="6" md="6" lg="6" >
                      <s-text label="Apellido Paterno*" 
                      :rules="[
                          v => !!v || 'El campo es obligatorio.',
                          v => v.length >= 3 || 'Debe tener al menos 3 caracteres'
                        ]"
                      v-model.trim="person.NtpPaternalSurname"
                      @input="handleInputPat($event)"
                      :uppercase="true"
                      readonly
                      />
                    </v-col>
                    <v-col cols="6" sm="6" md="6" lg="6" >
                      <s-text label="Apellido Materno*" 
                      :rules="[
                          v => !!v || 'El campo es obligatorio.',
                          v => v.length >= 3 || 'Debe tener al menos 3 caracteres'
                        ]"
                      v-model.trim="person.NtpMaternalSurname"
                      @input="handleInputPat($event)"
                      :uppercase="true"
                      readonly
                      />
                    </v-col>
                  </v-row>
            
                  <v-row style="margin-left: 5px" justify="center">
                    <v-col  cols="4" sm="6"  lg="4" >  
                      <s-text label="Fecha Nacimiento" :value="$fun.formatDateView(person.NtpBirthDate)" readonly/>
                    </v-col>
                    <v-col cols="4" sm="6"  lg="4" >
                      <s-select-definition :def="1037" label="Género" v-model="person.TypeSex" readonly/>
                    </v-col>
                    <v-col cols="4" sm="6" lg="4" >
                      <s-select-definition :def="1502" label="Nacionalidad" v-model="person.GenPerson.NatID" readonly/>
                    </v-col>
                  </v-row>
                  <v-row style="margin-left: 5px" justify="start">
                    <v-col cols="4" sm="6" lg="4" >
                      <s-switch
                        label="Discapacitado(a)"
                        v-model="person.NtpDisability"
                        disabled
                      ></s-switch>
                    </v-col>
                    <v-col cols="2" sm="4" v-if="person.NtpDisability">
                      <v-file-input
                        accept=".pdf,.jpg,.jpeg,.png"
                        label="Anexo"
                        v-model="Attach"
                        hide-details
                        disabled
                      />
                    </v-col>
                    <v-col cols="2" sm="4" v-if="person.NtpDisability">
                      <v-btn
                        color="success"
                        class="mt-5"
                        :disabled="!Attach"
                        icon
                        x-small
                        @click="openFile(Attach)"
                      >
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="4" sm="6" lg="4">
                      <s-switch label="Sindicalizado(a)" v-model="person.NtpSyndical" disabled></s-switch>
                    </v-col>
                  </v-row>
                </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            
            <v-row>
              <v-col md="12"  sm="12" lg="12" class="d-flex justify-center align-center">
                <div style="height:300px; width:300px; border-radius:10px; overflow:hidden">
                  <v-img :src="image?image:'../../../public/static/User.png'" style="height:100%;width:100%" />
                </div>
              </v-col>
            </v-row>
              
          </v-col>
        </v-row>
        <v-row  justify="center">
          <v-col cols="12" sm="12" lg="12"> 
          <v-tabs v-model="currentItem" centered >
            <!-- <v-tab href="#Photo"> Foto </v-tab> -->
            <v-tab href="#Address"> Direcciones </v-tab>
            <v-tab href="#TelephoneEmail"> Teléfonos y Correos </v-tab>
            <v-tab href="#Driver"> Brevete </v-tab>
            <v-tab href="#Fingerprint"> Huella </v-tab>
          </v-tabs>
          <keep-alive>
            <v-tabs-items v-model="currentItem">
              <v-tab-item :value="'Address'"> 
                  <v-col cols="12" sm="12" lg="12"> 
                    <v-card dense >
                        <v-card-title class="text-h7" style="font-weight: bold;">
                          <v-row>
                            <v-col>
                              Visualizar Dirección 
                            </v-col>
                          </v-row>
                        </v-card-title>
                        <v-card-text>
                          <v-row style="margin-left: 5px" justify="center" >
                            <v-col cols="12" sm="12" lg="12">
                              <template>
                                <v-data-table  :headers="addressHeaders" class="elevation-1" :items="addressDetail" :sort-by="['SecStatus','TypeAddress','PadID']" 
                                :sort-desc="[true]" >
                                  <template v-slot:[`item.PadIsDefault`]="{ item }">
                                    {{  item.PadIsDefault == 1 ? 'Principal' : 'Opcional' }}
                                  </template>
                                  <template v-slot:[`item.SecStatus`]="{ item }">
                                    <v-chip 
                                      small 
                                      :color="item.SecStatus ? 'success' : ''" 
                                      :text-color="item.SecStatus ? 'white' : ''" 
                                      >
                                      {{ item.SecStatus  ? 'Activo' : 'Inactivo' }}
                                    </v-chip>
                                  </template>
                                </v-data-table>
                              </template>
                            </v-col>
                          </v-row>
                        </v-card-text>
                    </v-card>
                  </v-col>
                <!-- </v-row> --> 
              </v-tab-item>
              <v-tab-item :value="'TelephoneEmail'"> 
                <v-col cols="12" sm="12" lg="12"> 
                  <v-row style="" justify="center" > 
                    <v-col cols="6" sm="6" lg="5">
                      <v-card dense    >
                        <v-card-title class="text-h7"  style="font-weight: bold;" >
                          <v-row>
                            <v-col>
                              Teléfonos
                            </v-col>
                          </v-row>
                        </v-card-title>
                        <v-card-text>
                          
                          <v-row style="margin-left: 5px ;margin: auto" >
                            <v-col cols="6" sm="6"  lg="6" >
                              <s-select-definition :def="1111" label="Tipo de Teléfono" v-model="phone.TypePhone" ref="TypePhoneRef" readonly/>
                            </v-col>
                            <v-col cols="6" sm="6" lg="6" >
                              <s-text v-model="phone.PpnNumberPhone" type="tel" number label="Teléfono Principal"   :maxlength="maxlengthPhone1"  :counter="maxlengthPhone1 || 9" :showCounter="true" readonly/>
                            </v-col>
                          </v-row>
                          <v-row style="margin-left: 5px ;margin: auto" >
                            <v-col class="s-col-form" cols="6" sm="6"  lg="6" >
                              <s-select-definition :def="1111" label="Tipo de Teléfono" v-model="phone2.TypePhone" ref="TypePhoneRef2" readonly />
                            </v-col>
                            <v-col class="s-col-form" cols="6" sm="6" lg="6" >
                              <s-text v-model="phone2.PpnNumberPhone" type="tel" number label="Teléfono Secundario" :maxlength="maxlengthPhone2" :counter="maxlengthPhone1 || 9" :showCounter="true" readonly/>
                            </v-col>                          
                          </v-row>
                            
                        </v-card-text>
                      </v-card>
                    </v-col>  
                    <v-col cols="6" sm="6" lg="7"> 
                      <v-card dense >
                        <v-card-title class="text-h7" style="font-weight: bold;">                        
                          <v-row>
                            <v-col>
                              Correos
                            </v-col>
                          </v-row>
                        </v-card-title>
                        <v-card-text>
                         
                          <v-row style="margin-left: 5px ;margin: auto" >
                            <v-col class="s-col-form" cols="6" sm="6" lg="6" >
                              <s-text label="Correo Principal" v-model="email.PemEmail" 
                                :placeholder="'ejemplo'"
                                readonly
                              />
                            </v-col>
                            <v-col  class="s-col-form" cols="6" sm="6"  lg="6" >
                              <s-select-definition :def="1518" label="Dominio de Correo" v-model="email.TypeDomainEmail" ref="TypeDomainEmailRef" readonly/>
                            </v-col>
                          </v-row>
                          <v-row style="margin-left: 5px ;margin: auto" >
                            <v-col class="s-col-form" cols="6" sm="6" lg="6" >
                              <s-text label="Correo Corporativo"  v-model="email2.PemEmail" 
                                :placeholder="'ejemplo'"
                                readonly
                              />
                            </v-col>
                            <v-col  class="s-col-form" cols="6" sm="6"  lg="6" >
                              <s-select-definition :def="1518" label="Dominio de Correo" v-model="email2.TypeDomainEmail" ref="TypeDomainEmailRef2" readonly/>
                            </v-col>
                          </v-row>                      
                          
                        </v-card-text>
                      </v-card>
                    </v-col>                                
                  </v-row> 
                </v-col>   
              </v-tab-item>
              <v-tab-item :value="'Driver'">
                  <v-col cols="12" sm="12" lg="12">
                    <v-card dense >
                        <v-card-title class="text-h7" style="font-weight: bold;">
                          Brevete
                        </v-card-title>
                        <v-card-text>
                          <v-row>
                            <v-col cols="4" sm="6" lg="3">
                              <s-text  v-model="driver.NpdDocumentNumber" label="Brevete" />
                            </v-col>
                            <v-col cols="4" sm="6" lg="3">
                              <s-select-definition :def="1139" label="Categoría" v-model="driver.TypeDocumentCategory" readonly/>
                            </v-col>
                            <v-col cols="4" sm="6" lg="3">
                              <s-date v-model="driver.NpdDocumentInit" label="Emisión" :format="'DD/MM/YYYY'" disabled/>
                            </v-col>
                            <v-col cols="4" sm="6" lg="3">
                              <s-date v-model="driver.NpdDocumentEnd" label="Revalidación" :format="'DD/MM/YYYY'" disabled/>
                            </v-col>
                          </v-row>
                        </v-card-text>
                    </v-card>
                  </v-col>
                <!-- </v-row> -->
              </v-tab-item>
              <!-- <v-tab-item :value="'Photo'">
                <v-col cols="12" sm="12" lg="12">
                  <v-card dense >
                        <v-card-title class="text-h7" style="font-weight: bold;">
                          Foto
                        </v-card-title>
                        <v-card-text>
                          <v-row>
                            <v-col md="4"  sm="12" lg="12" class="d-flex justify-center align-center">
                              <div style="height:300px; width:300px; border-radius:10px; overflow:hidden">
                                <v-img :src="image?image:'../../../public/static/User.png'" style="height:100%;width:100%" />
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-text>
                  </v-card>
                
                </v-col>  
              </v-tab-item> -->
              <v-tab-item :value="'Fingerprint'">
                <v-col cols="12" sm="12" lg="12">
                    <v-card dense>
                    <v-card-title class="text-h7" style="font-weight: bold;">
                        <v-row justify="center">
                        <v-col cols="6"  class="d-flex justify-center">  <span>IZQUIERDA</span> </v-col>
                        <v-col cols="6"  class="d-flex justify-center">   <span>DERECHA</span> </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row justify="center">
                        <v-col cols="12" class="d-flex justify-center">
                            <!-- Imagen de las manos con contenedor relative -->
                            <div style="position: relative; display: inline-block;">
                            <v-img 
                                lazy-src="@/assets/hands1.png"
                                max-height="400"
                                max-width="700"
                                src="@/assets/hands1.png"
                                class="d-flex justify-center align-center"
                            />

                            <div 
                                v-for="(finger, index) in fingers" 
                                :key="index"
                                class="finger"
                                :style="{
                                position: 'absolute',
                                top: finger.position.top,
                                left: finger.position.left,
                                backgroundColor: selectedFinger === index ? 'orange' : finger.isActive==true ? '#53f773': 'transparent', 
                                cursor: 'pointer', 
                                width: '30px', 
                                height: '30px', 
                                borderRadius: '50%', 
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'center',
                                fontWeight: 'bold',
                                color: 'black',
                                transition: 'background-color 0.3s'
                                }"
                                @mouseover="highlightFinger(index)"
                                @mouseleave="resetHighlight"
                            >
                                {{ index + 1 }} <!-- Número de la huella -->
                            </div>
                            </div>
                        </v-col>
                        </v-row>
                    </v-card-text>
                    </v-card>
                </v-col>
              </v-tab-item>

            </v-tabs-items>
          </keep-alive>
          </v-col>
          
        </v-row>
      </v-container>
      
    </v-card>

    
  </template>
  
  <script>
  import Service from "@/services/General/PersonService";
  import _sGeneric from "@/services/General/GenericService";
  import _sContracthmRs from "@/services/HumanResource/HmnContractService";
  import _sHelper from "@/services/HelperService";
  import SText from "@/components/Utils/SText.vue";
  import _sGenUploadFiles from "@/services/General/GenUploadFiles";
  import _sGenParam from "@/services/General/ParameterService";
  import _sGeneral from "@/services/HelperService.js";

  export default {
    name: "ContractPersonAdd",
    components: { SText },
    props: {
      propPerson: {
        type: Object,
        default: null,
      },
      visibleTitle: {
        type: Boolean,
        default: true,
      },
      full: {
        type: Boolean,
        default: false,
      },
      typeperson: {
        type: Number,
        default: 1,
      },
      addTypeSex: {
        type: Boolean,
        default: false,
      },
      addBirthDate: {
        type: Boolean,
        default: false,
      },
      addDriver: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showModal: false, // Controla si el modal está visible
        scanning: false, // Bandera para saber si estamos en el proceso de escaneo
        captured: false, // Bandera para saber si la huella ha sido capturada correctamente
        editing: false,
        configNationality: null,
        statusOpen:false,
        configZones: null,
        TypeAddress:null,
        configDepartment: null,
        DepartmentUbigeo: "25",
        ProvinceUbigeo: "01",
        DistrictUbigeo: "01",
        item: 0,
        dataFingerL:{},
        Zone: null,
        referencEdit:null,     
        editedIndexPhone    :-1,
        editedIndexEmail    :-1,
        editedIndexAddress  :-1,
        currentTab: 0,
        currentItem:"tab-Funciones",
        isCameraActive: false, // Para saber si la cámara está activa
        itemsNationality: [],
        CancelEditarT:false,
        CancelEditarE:false,
        CancelEditarA:false,
        image:null,
        menu3: false,
        ipMatch :null,
        title : -1,
        config: {},
        dialog: false,
        validEmail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        fingers: [
            { id: 0, position: { top: '17%' , left: '13%' }, style: {}, isActive: false }, // Pulgar izquierdo
            { id: 1, position: { top: '10%' , left: '18%' }, style: {}, isActive: false }, // Índice izquierdo
            { id: 2, position: { top: '8%'  , left: '23%' }, style: {}, isActive: false }, // Medio izquierdo
            { id: 3, position: { top: '10%' , left: '29%' }, style: {}, isActive: false }, // Anular izquierdo
            { id: 4, position: { top: '39%' , left: '42%' }, style: {}, isActive: false }, // Meñique izquierdo
            { id: 5, position: { top: '39%' , left: '52%' }, style: {}, isActive: false }, // Pulgar derecho
            { id: 6, position: { top: '10%' , left: '65%' }, style: {}, isActive: false }, // Índice derecho
            { id: 7, position: { top: '8%'  , left: '70%' }, style: {}, isActive: false }, // Medio derecho
            { id: 8, position: { top: '10%' , left: '76%' }, style: {}, isActive: false }, // Anular derecho
            { id: 9, position: { top: '17%' , left: '81%' }, style: {}, isActive: false }  // Meñique derecho
        ],
        selectedFinger: null,        
        address: {
          PadCodeUbigeo: "",
          PadAddress: "",      
          PadAddressReference: "",        
          PadDepartment: "",
          ZonID: 1,
          PadIsDefault: 1,
          TypeAddress:1,
        },
        addressDefault: {
          PadCodeUbigeo: "",
          PadAddress: "",      
          PadAddressReference: "",        
          PadDepartment: "",
          ZonID: 1,
          PadIsDefault: 1,
          TypeAddress:1,
        },
        phone: {
          PnpID:0,
          TypePhone: 1,
          PpnNumberPhone: "",
          PpnIsDefault: 1,
          UsrCreateID : this.$fun.getUserID(),
        },
        phone2: {
          PnpID:0,
          TypePhone: 1,
          PpnNumberPhone: "",
          PpnIsDefault: 2,
          UsrCreateID : this.$fun.getUserID(),
        },
        phoneDefault:{
          PnpID:0,
          TypePhone: 1,
          PpnNumberPhone: "",
          PpnIsDefault: 2,
          UsrCreateID : this.$fun.getUserID(),
        },
        email: {
          PemID:0,
          PemEmail: "",
          PemIsDefault: 1,
          TypeDomainEmail:1,
          UsrCreateID : this.$fun.getUserID(),
        },
        email2: {
          PemID:0,
          PemEmail: "",
          PemIsDefault: 2,
          TypeDomainEmail:5,
          UsrCreateID : this.$fun.getUserID(),
        },
        emailDefault: {
          PemID:0,
          PemEmail: "",
          PemIsDefault: 0,
          TypeDomainEmail:1,
          UsrCreateID : this.$fun.getUserID(),
        },
        addressDetail :[],
        phonesDetail  :[],
        emailsDetail  :[],
        addressHeaders: [ 
          { text: "ID"                  , value: "PadID"                  ,sortable: false  , width: 20,  },
          { text: "Distrito Ugigeo"     , value: "PadCodeUbigeo"          ,sortable:false   , width: 50,  },
          { text: "Direccion"           , value: "PadAddress"             ,sortable:false   , width: 100, },         
          //{ text: "Tipo de Dirección"   , value: "TypeAddress"            ,sortable:false   , width: 50,  },   
          { text: "Tipo de Dirección"   , value: "TypeAddressName"        ,sortable:false   , width: 50,  },             
          { text: "Referencia"          , value: "PadAddressReference"    ,sortable:false   , width: 100, },
          //{ text: "Zona"                , value: "ZonID"                  ,sortable:false   , width: 70,  },
          { text: "Zona"                , value: "ZonName"                ,sortable:false   , width: 70,  },
          { text: "Categoria"           , value: "PadIsDefault"           ,sortable:false   , width: 70,  },
          { text: "Estado"              , value: "SecStatus"              ,sortable:false   , width: 70,  },
          // { text: 'Acciones'            , value: 'actions'                ,sortable: false  , width: 30,  },
			  ],
        phonesHeaders: [ 
          //{ text: "ID"                  , value: "PpnID"                 ,sortable: false  , width: 20,  },
          { text: "Tipo de Telfono"     , value: "TypePhoneName"         ,sortable: false  , width: 20,  },
          { text: "Teléfono"            , value: "PpnNumberPhone"        ,sortable: false  , width: 20,  },
          { text: "Categoria"           , value: "PpnIsDefault"          ,sortable: false  , width: 20,  },        
          { text: 'Acciones'            , value: 'actions'               ,sortable: false  , width: 30,  },
			  ],        
        emailsHeaders: [ 
          //{ text: "ID"                  , value: "PemID"                 ,sortable: false  , width: 20,  },
          { text: "Correo"              , value: "PemEmail"              ,sortable: false  , width: 20,  },
          { text: "Categoria"           , value: "PemIsDefault"          ,sortable: false  , width: 40,  },
          { text: "Estado"              , value: "SecStatus"             ,sortable: false  , width: 40,  }, 
          { text: 'Acciones'            , value: 'actions'               ,sortable: false  , width: 30,  },
			  ],                        
        person: {
          NtpName: "",
          NtpPaternalSurname: "",
          NtpMaternalSurname: "",
          NtpID: 0,
          NtpBirthDate: null,
          TypeSex: 1,
          GenPerson: {
            TypePersonDocument: 0,
            PrsDocumentNumber: "",
            NatID: 1,
            Telephones:"0",
          },
          GenNaturalPersonDriver: null,
          NtpDisability: 1,
          NtpAttach: ""
        },
        driver: {
          NpdID: 0,
          NpdDocumentNumber: "",
          NtpID: 0,
          NpdDocumentInit: null,
          NpdDocumentEnd: null,
          TypeDocumentCategory: 0,
        },
        isInitializing: false, // Bandera para controlar la inicialización
        TypePersonDocument:0,
        Attach:null,
        maxlengthPhone1:9,
        maxlengthPhone2:9,
      };
      
    },
    methods: {

      handleInput(event) {      
        this.person.NtpName = this.person.NtpName.trim();
      },
      handleInputPat(event) {        
        this.person.NtpPaternalSurname = this.person.NtpPaternalSurname.trim();
      },
      handleInputMat(event) {        
        this.person.NtpMaternalSurname = this.person.NtpMaternalSurname.trim();
      },
      changeAddress(item){
        this.TypeAddress = item;
      },
      highlightFinger(index) {
        this.selectedFinger = index;
      },
      resetHighlight() {
        this.selectedFinger = null;
      },
      getImagePerson(document) {
        _sHelper.getPhotoWorkerBase64(document, this.$fun.getUserID()).then((rsp) => {
          if (rsp.status === 200) {
            // La respuesta 'rsp.data' contiene la cadena Base64
            this.image = 'data:image/jpg;base64,' + rsp.data;  // Asignamos la cadena Base64 directamente
            return this.image;
          } else {
            console.error("No se pudo cargar la imagen");
          }
        }).catch((error) => {
          console.error("Error al obtener la imagen:", error);
        });

        // Llamamos al método para obtener el QR, si es necesario
      },
      LoadData() {
        this.getImagePerson(this.propPerson.PrsDocumentNumber);

        // this.DepartmentUbigeo = "20";
        // this.ProvinceUbigeo   = "01";
        //}

        if (this.propPerson != null)
          if (this.propPerson.GenPerson != null) {
            this.person = Object.assign(this.propPerson, {});
          } else {
            
            this.person.GenPerson.PrsID = this.propPerson.PrsID;
            this.person.GenPerson.PrsDocumentNumber =
            this.propPerson.PrsDocumentNumber;
            this.person.GenPerson.NatID = this.propPerson.NatID;
            //this.person.GenPerson.SecStatus           = this.propPerson.SecStatus;

            this.person.NtpName = this.propPerson.NtpName;
            this.person.NtpPaternalSurname = this.propPerson.NtpPaternalSurname;
            this.person.NtpMaternalSurname = this.propPerson.NtpMaternalSurname;
            this.person.TypeSex = this.propPerson.TypeSex;
            this.person.NtpBirthDate = this.propPerson.NtpBirthDate;
            this.person.NtpID = this.propPerson.NtpID;
            this.person.PrsID = this.propPerson.PrsID;

            this.person.NtpDisability = this.propPerson.GenNatutalPerson?.NtpDisability || 0;
        
            this.Attach = { name: this.propPerson.GenNatutalPerson?.NtpAttach };
            this.person.NtpSyndical = this.propPerson.GenNatutalPerson
              .NtpSyndical
              ? this.propPerson.GenNatutalPerson.NtpSyndical
              : 0;
            //this.person.SecStatus                     = this.propPerson.SecStatus;

            for (var i = 0; i < this.propPerson.Addresses.length; i++) {
              let address = {
                PadID: this.propPerson.Addresses[i].PadID,
                PrsID: this.propPerson.PrsID,
                PadCodeUbigeo: this.propPerson.Addresses[i].PadCodeUbigeo,
                PadAddress: this.propPerson.Addresses[i].PadAddress,
                PadAddressReference:
                  this.propPerson.Addresses[i].PadAddressReference,
                PadDepartment: this.propPerson.Addresses[i].PadDepartment,
                ZonID: this.propPerson.Addresses[i].ZonID,
                ZonName: this.propPerson.Addresses[i].ZonName,
                TypeAddress: this.propPerson.Addresses[i].TypeAddress,
                TypeAddressName: this.propPerson.Addresses[i].TypeAddressName,
                SecStatus: this.propPerson.Addresses[i].SecStatus,
                PadIsDefault: this.propPerson.Addresses[i].PadIsDefault,
                UsrCreateID: this.propPerson.Addresses[i].UsrCreateID,
                UsrUpdateID: this.$fun.getUserID(),
              };
              this.addressDetail.push(address);
            }

            if (this.propPerson.Telephones.length > 0) {
              // this.phone={
              //     ...this.propPerson.Telephones[0],
              //     UsrUpdateID: this.$fun.getUserID()
              // }
              this.phone = this.propPerson.Telephones.find(
                (e) => e.SecStatus == 1 && e.PpnIsDefault == 1
              );

              if (this.propPerson.Telephones.length > 1 && this.propPerson.Telephones.some(e=> e.PpnIsDefault == 2)) {
                // this.phone2={
                //   ...this.propPerson.Telephones[1],
                //   UsrUpdateID: this.$fun.getUserID()
                // }
                this.phone2 = this.propPerson.Telephones.find(
                  (e) => e.SecStatus == 1 && e.PpnIsDefault == 2
                );
              }

              // this.propPerson.Telephones.forEach(element => {
              //   element.UsrUpdateID = this.$fun.getUserID()
              //   this.phonesDetail.push(element);
              // });
            }
            //  console.log("TELEFONOS DETAIL",this.phonesDetail);
            // for(var i=0; i<this.propPerson.Telephones.length ; i++){
            // let phone ={
            //     PpnID           : this.propPerson.Telephones[i].PpnID,
            //     PrsID           : this.propPerson.PrsID,
            //     TypePhone       : this.propPerson.Telephones[i].TypePhone,
            //     TypePhoneName   : this.propPerson.Telephones[i].TypePhoneName,
            //     PpnNumberPhone  : this.propPerson.Telephones[i].PpnNumberPhone,
            //     SecStatus       : this.propPerson.Telephones[i].SecStatus,
            //     PpnIsDefault    : this.propPerson.Telephones[i].PpnIsDefault,
            //     UsrCreateID     : this.propPerson.Telephones[i].UsrCreateID,
            //     UsrUpdateID     : this.$fun.getUserID()
            //   }
            //   this.phonesDetail.push(phone);
            // }

            if (this.propPerson.Emails.length > 0) {
              this.email = this.propPerson.Emails.find(
                (e) => e.SecStatus == 1 && e.PemIsDefault == 1
              );
              // this.email={
              //     ...this.propPerson.Emails[0],
              //     UsrUpdateID: this.$fun.getUserID()
              // }
              this.email.PemEmail = this.email.PemEmail.split("@")[0];
              if (this.propPerson.Emails.length > 1) {
                // this.email2={
                //   ...this.propPerson.Emails[1],
                //   UsrUpdateID: this.$fun.getUserID()
                // }
                this.email2 = this.propPerson.Emails.find(
                  (e) => e.SecStatus == 1 && e.PemIsDefault == 2
                );
                if(this.email2){
                  this.email2.PemEmail = this.email2.PemEmail.split("@")[0];
                }
              }
              // this.propPerson.Emails.forEach(element => {
              //   element.UsrUpdateID = this.$fun.getUserID();
              //   this.emailsDetail.push(element);
              // });
            }
            // console.log("Emails DETAIL",this.emailsDetail);
            // for(var i=0; i<this.propPerson.Emails.length ; i++){
            //   let email = {
            //     PemID           : this.propPerson.Emails[i].PemID,
            //     PrsID           : this.propPerson.PrsID,
            //     PemEmail        : this.propPerson.Emails[i].PemEmail,
            //     SecStatus       : this.propPerson.Emails[i].SecStatus,
            //     PemIsDefault    : this.propPerson.Emails[i].PemIsDefault,
            //     TypeDomainEmail : this.propPerson.Emails[i].TypeDomainEmail,
            //     UsrCreateID     : this.propPerson.Emails[i].UsrCreateID,
            //     UsrUpdateID     : this.$fun.getUserID()
            //   }
            //   this.emailsDetail.push(email);
            // }
            if (
              this.propPerson.GenNatutalPerson !== null &&
              this.propPerson.GenNatutalPerson.GenNaturalPersonDriver.length > 0
            ) {
              this.driver.NpdDocumentNumber =
                this.propPerson.GenNatutalPerson.GenNaturalPersonDriver[0].NpdDocumentNumber;
              this.driver.TypeDocumentCategory =
                this.propPerson.GenNatutalPerson.GenNaturalPersonDriver[0].TypeDocumentCategory;
              this.driver.NpdDocumentInit =
                this.propPerson.GenNatutalPerson.GenNaturalPersonDriver[0].NpdDocumentInit;
              this.driver.NpdDocumentEnd =
                this.propPerson.GenNatutalPerson.GenNaturalPersonDriver[0].NpdDocumentEnd;
              this.driver.NpdID =
                this.propPerson.GenNatutalPerson.GenNaturalPersonDriver[0].NpdID;
              this.driver.NtpID = this.propPerson.NtpID;
              this.driver.SecStatus =
                this.propPerson.GenNatutalPerson.GenNaturalPersonDriver[0].SecStatus;
              this.driver.UsrCreateID =
                this.propPerson.GenNatutalPerson.GenNaturalPersonDriver[0].UsrCreateID;
              this.driver.UsrUpdateID = this.$fun.getUserID();
            }
            this.GetFingerPrintListByNtpID();
          }
      },
      GetFingerPrintListByNtpID() {
      if (this.person.NtpID !== null && this.person.NtpID !== 0) {
        _sContracthmRs
          .FingerprintByNtp(this.person.NtpID, this.$fun.getUserID())
          .then((r) => {
            if (r.status == 200) {
              // console.log("fingeDATA: ",r.data);
              this.dataFingerL = r.data;
              this.dataFingerL.forEach((item) => {
                // Comparamos el id del dedo con el WfpTypeFinger
                this.fingers.forEach((finger) => {
                  if (finger.id === item.WfpTypeFinger) {
                    // Si coincide, cambiamos el estado de isActive a true
                    finger.isActive = true;
                  }
                });
              });
            }
          });
      }
    },
			openFile(url) {
				_sGeneral.download(url.name, this.$fun.getUserID()).then((r) => {
					this.$fun.download(r.data, url.name);
				});
    		},
      clear() {
        this.ZoneID = 15;
        this.DepartmentUbigeo = "20";
        this.ProvinceUbigeo = "2001";
        this.phone = {
          TypePhone: 1,
          PpnNumberPhone: "",
          PpnIsDefault: 1,
        };
        this.address = {
          PadCodeUbigeo: "",
          PadAddress: "",
          PadAddressReference: "",
          PadStreet: "",
          PadDepartment: "",
          ZonID: 0,
          PadIsDefault: 1,
        };
        this.email = {
          PemEmail: "",
          PemIsDefault: 1,
        };
        this.driver = {
          NpdID: 0,
          NpdDocumentNumber: "",
          NpdDocumentInit: null,
          NpdDocumentEnd: null,
          TypeDocumentCategory: 0,
        };
  
        let doc;
        if (this.person.GenPerson.PrsDocumentNumber.length > 0) doc = this.person.GenPerson.PrsDocumentNumber;
        else doc = "";
        this.person = {
          NtpName: "",
          NtpPaternalSurname: "",
          NtpMaternalSurname: "",
          NtpID: 0,
          NtpBirthDate: this.$moment().format(this.$const.DateMomentFormat),
          TypeSex: 1,
          GenPerson: {
            TypePersonDocument: 1,
            PrsDocumentNumber: doc,
            NatID: 193,
          },
        };
      },

      closeSave(){
        this.fingers.forEach(finger => {finger.isActive = false;});
        this.$emit("toclose", 2);
        this.clear();
      },
    },
    computed:{
    },
    watch: {
      propPerson() {
        
      },

      'phone.TypePhone'(){
        this.maxlengthPhone1 = parseInt(this.$refs.TypePhoneRef.items.find((x) => x.DedValue === this.phone.TypePhone).DedHelper2);
      },

      'phone2.TypePhone'(){
        this.maxlengthPhone2 = parseInt(this.$refs.TypePhoneRef2.items.find((x) => x.DedValue === this.phone2.TypePhone).DedHelper2);
      }
    },
    created() {
        this.LoadData();
    },
    mounted(){
    }
  };

  </script>
  <style scoped>
  .finger {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: #e0e0e0;
    border-radius: 50%;
    text-align: center;
    font-weight: bold;
    margin: 5px;
  }
  
  .finger:hover {
    background-color: orange;
  }
  </style>

    