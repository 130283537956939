<template>
  <div>
    <s-crud
      v-if="show == true"
      @doubleClick="doubleClick($event)"
      noBorder
      noDark
      searchInput
      title="Personas"
      :filter="filterGrid"
      :config="this.config"
    >
      <template v-slot:TypeSex="{ row }">
        {{ row.TypeSex == 1 ? "Masculino" : "Femenino" }}
      </template>
      <template v-slot:AsgAdd="{ row }" v-if="phone">
        <v-btn x-small block color="primary" @click="doubleClick(row)" dark><v-icon>mdi-plus</v-icon></v-btn>
      </template>
    </s-crud>
  </div>
</template>

<script>
import ServicePrs from "@/services/General/PersonService";
export default {
  name: "sSearchPerson",
  components: {},
  props: {
    typeperson: {
      type: Number,
      default: 0,
    },
    addTypeSex: {
      type: Boolean,
      default: false,
    },
    addBirthDate: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    phone: false,
    show: false,
    filterGrid: { TypePerson: 1, SecStatus: 1 },
    config: {
      model: {
        PrsID: "ID",
        PrsDocumentNumber: "string",
        PrsFullName: "string",
        TypePersonDocument: "int",
        SecStatus: "",
        NtpBirthDate: "date",
        TypeSex: "",
        AsgAdd: "",
      },
      service: ServicePrs,
      headers: [
        // { text: "ID", value: "PrsID" },
        // { text: "N°Documento", value: "PrsDocumentNumber" },
        // { text: "Nombre", value: "PrsFullName" },
        // // { text: "Agregar", value: "AsgAdd", width: 70},
      ],
    },
  }),
  methods: {
    validatePhone() {
      this.phone = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
      // console.log("this.phone", this.phone);
      if(this.phone){
        const headersToAdd = [
          { text: "Agregar", value: "AsgAdd", width: 70},
          { text: "Nombre", value: "PrsFullName" },
          { text: "N°Documento", value: "PrsDocumentNumber" },
          { text: "ID", value: "PrsID" },
        ];
  
        let contador_initial = 0;
        headersToAdd.forEach(header => {
          const headerExists = this.config.headers.some(h => h.value === header.value);
          if (!headerExists) {
            this.config.headers.splice(contador_initial, 0, header);
          }
        });
      }else{
        const headersToAdd = [
          { text: "Nombre", value: "PrsFullName" },
          { text: "N°Documento", value: "PrsDocumentNumber" },
          { text: "ID", value: "PrsID" },
        ];
  
        let contador_initial = 0;
        headersToAdd.forEach(header => {
          const headerExists = this.config.headers.some(h => h.value === header.value);
          if (!headerExists) {
            this.config.headers.splice(contador_initial, 0, header);
          }
        });
      }
    },
    doubleClick(item) {
      this.$emit("returnPerson", item);
    },
  },
  created() {
    this.config.typeperson = this.typeperson;
    if (this.addTypeSex) this.config.headers.push({ text: "Sexo", value: "TypeSex" });
    if (this.addBirthDate)
      this.config.headers.push({
        text: "Fecha de Nacimiento",
        value: "NtpBirthDate",
      });

    this.show = true;
    this.validatePhone();
  },
};
</script>
