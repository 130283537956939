<template>
  <v-card elevation="0">
    <s-toolbar v-if="visibleTitle" label="Datos Persona" />
    <v-card-text>
      <v-row>
        <v-col class="s-col-form" sm="4" md="3" lg="4">
          <s-toolbar-person
            ref="PersonRef"
            :defaultDocument="item.PrsDocumentNumber"
            @returnPerson="returnPerson($event, item)"
            v-model="item.PrsDocumentNumber"
            noSearch
            noCamera
            readonly
            noEdit
            :showCounter="true"
            :disabled="extension ? extension : renovation"
          />
        </v-col>
        <v-col class="s-col-form" cols="8" sm="8" md="8" lg="8">
          <s-text
            ref="textNtpName"
            label="Nombres y Apellidos Completos"
            v-model="item.NtpFullName"
            readonly
            :disabled="extension ? extension : renovation"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <s-select-definition
            :def="1060"
            label="Planilla"
            v-model="item.TypePayroll"
            
            readonly
          />
        </v-col>
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <s-select-area
            label="Area"
            v-model="item.AreID"
            :text="item.AreName"
            disabled
          ></s-select-area>
        </v-col>
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <s-select-area
            v-model="item.CceID"
            :text="item.CceName"
            centercost
            :clearable="true"
            label="Centro de Costo"
            disabled
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <!-- <s-select-definition :def="1039" label="Cargo" v-model="item.PstID" :clearable="true"/>-->
          <s-select-position
            v-model="item.PstID"
            :text="item.PstName"
            centercost
            :clearable="true"
            label="Cargo"
            disabled
          />
          <!-- <s-select
                      label="Cargo"
                      :items="Position"
                      autocomplete
                      clearable
                      item-text="PstName"
                      item-value="PstID"
                      v-model="item.PstID"
                    ></s-select>-->
        </v-col>
        <v-col class="s-col-form" sm="4" md="4" lg="2">  
          Nivel de Criticidad        
          <v-chip
              
                :color="
                  item.PstIsBascCritic == 1 || item.PstIsBascCritic == 0
                    ? 'white'
                    : item.PstIsBascCritic == 2
                    ? 'primary'
                    : item.PstIsBascCritic == 3
                    ? 'warning'
                    : 'error'
                "
                style="margin:0px;"
                >{{
                  item.PstIsBascCritic == 0
                    ? "No Definido"
                    : item.PstIsBascCriticName
                }}
              </v-chip>
        </v-col>
        <v-col class="s-col-form" sm="4" md="4" lg="2">
          <s-select
            label="Cuadrilla"
            :items="quadrille"
            autocomplete
            clearable
            item-text="GngName"
            item-value="GngID"
            v-model="item.GngID"
            disabled
          ></s-select>
        </v-col>
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <s-select-business-line
            v-model="item.BslID"
            :cceID="item.CceID"
            :clearable="true"
            :item-text="item.BslName"
            label="Línea de Negocio"
            disabled
          />
        </v-col>
      </v-row>
      <v-row>
        <!-- <v-col class="s-col-form" sm="3" md="3" lg="4">
          <s-select-definition
            :def="1030"
            label="Campaña"
            v-model="item.CmpID"
            
            :readonly="extension ? extension : renovation"
          />
        </v-col> -->
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <s-select-definition
            :def="1482"
            label="Categoria"
            v-model="item.TypeCategory"
            
            disabled
          />
        </v-col>
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <s-select-definition
            :def="1069"
            label="Tipo de Contrato"
            v-model="item.TypeContract"
            
            readonly
            :disabled="extension ? extension : renovation"
          />
        </v-col>
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <s-text
              label="N° de hijos"
              number
              v-model="item.AgtChildrenQuantity"
              :min="0"
              readonly
              :disabled="extension ? extension : renovation"
            >
          </s-text>
        </v-col>
      </v-row>
      <v-row v-if="item.TypeContract == 9">
            <v-col>
              <s-textarea 
              label="Describa en Servicio" 
              :height=80 
              readonly
              :disabled="extension ? extension : renovation"
              v-model="item.AgtServiceDescription"
              ></s-textarea>
            </v-col>
          </v-row>
      <v-row>
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <s-select-definition
            :def="1039"
            :dgr="4"
            label="Tipo de Trabajador"
            v-model="item.TypeWorker"
            
            readonly
            :disabled="extension ? extension : renovation"
          />
        </v-col>
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <s-select-definition
            :def="1481"
            label="Régimen laboral"
            v-model="item.TypeLaborRegime"
            
            readonly
            :disabled="extension ? extension : renovation"
          />
        </v-col>
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <s-select-definition
            :def="1077"
            label="Cat. Ocupacional"
            v-model="item.TypeOccupationalCategory"
            
            readonly
            :disabled="extension ? extension : renovation"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <!--<s-select-definition :def="1376"  label="Profesion" v-model="item.profesion" />-->
          <s-select
            label="Profesión"
            :items="profession"
            autocomplete
            clearable
            item-text="PrnDescription"
            item-value="PrnID"
            v-model="item.PrnID"
            disabled
          ></s-select>
        </v-col>
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <!-- <s-select-definition :def="1480"  label="Nivel Educativo" :clearable="true" v-model="item.TypeEducationLevel" /> -->
          <s-select
            label="Nivel Educativo"
            :items="EducationLevels"
            autocomplete
            item-text="LveDescription"
            item-value="LveID"
            v-model="item.LveID"
            readonly
          ></s-select>
        </v-col>
        <v-col class="s-col-form" sm="4" md="4">
          <s-select-definition
            :def="1071"
            label="Estado civil"
            v-model="item.TypeCivilStatus"
            
            readonly
            :disabled="extension ? extension : renovation"
          />
        </v-col>
      </v-row>
      <v-row>
        <!-- <v-col class="s-col-form" sm="3" md="3" lg="4">
          <s-select-definition
            :def="1045"
            label="Situación"
            v-model="item.TypeSituation"
            
            :readonly="extension ? extension : renovation"
          />
        </v-col> -->
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <s-select-definition
            :def="1070"
            label="Situación especial"
            v-model="item.TypeSpecialSituation"
            
            readonly
          />
        </v-col>
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <!-- <s-text   v-if="$fun.isAdmin()" label="Sueldo/Jornal" number v-model="item.AgtSalary" :min="0"  :readonly="extension ? extension : renovation">            
           </s-text> -->
          <s-money-input v-if="$fun.isAdmin()"  label="Sueldo/Jornal"  v-model="item.AgtSalary" readonly > </s-money-input>
          <s-text
              v-else
              label="Sueldo/Jornal"              
              :value="'*****'"
              readonly
            ></s-text>
        </v-col>
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <s-select-definition
            :def="1278"
            label="Turno"
            v-model="item.TypeTurno"
            
            readonly
          />
        </v-col>
      </v-row>
      <v-row>
        <!-- <v-col class="s-col-form" sm="3" md="3" lg="4">
              <s-date v-model="item.AgtAdmissionDate" label="Fecha ingreso" :clearable="true"/>
            </v-col>
            <v-col class="s-col-form pa-0 ma-0" sm="3" md="3" lg="2" >
              <v-checkbox  style="padding: 0; margin: 0;" :clearable="true" v-model="item.ceseActivo" label="Registrar Cese" />
            </v-col>
            <v-col class="s-col-form pa-0 ma-0"   xs="4" sm="4" md="4" v-if="this.$fun.getSecurity().IsLevelAdmin" lg="2">
              <s-switch label="Jornada Maxima" v-model="item.AgtMaximunDay"></s-switch>
            </v-col> -->
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <s-select-definition
            :def="1045"
            label="Situación"
            v-model="item.TypeSituation"
            
            readonly
          />
          
        </v-col>
        <v-col class="s-col-form" sm="4" md="4" lg="4" v-if="item.TypeSituation == 2 ? true :false">
          <s-select-campaign-se 
                label="Campaña"
                v-model="item.CmpID"
                readonly
              />
          <!-- <s-select-Campaign  label="Campaña" v-model="item.CmpID" :readonly="extension ? extension : renovation" />                  -->
          <!-- <s-select-definition
            :def="1030"
            label="Campaña"
            v-model="item.CmpID"
            
            :readonly="extension ? extension : renovation"
          /> -->
        </v-col>
        <!-- <v-col class="s-col-form" sm="4" md="4" lg="4">
          <s-select-definition
            :def="1059"
            label="Estado del Trabajador"
            v-model="item.AgtStatus"
            
            :readonly="extension ? extension : renovation"
            :disabled="extension ? extension : renovation" 
          />
        </v-col> -->
        <v-col v-if="item.TypeSpecialSituation == 4 || item.TypeSpecialSituation == 5" class="s-col-form" sm="4" md="4" lg="4">
              <s-text
                label="Meses de Prueba"
                v-model="item.AgtMonthsProbation"
                type="number"
                disabled
              />
            </v-col>
      </v-row>      
      <v-row>
        <v-col  v-if="!renovation && !extension " class="s-col-form" sm="4" md="4" lg="4">
          <s-text
            :value="$fun.formatDateView(item.AgtAdmissionDate)"
            label="Fecha de Ingreso"
            :clearable="true"
            disabled
          />
        </v-col>
        <v-col  v-if="renovation || extension " class="s-col-form" sm="4" md="4" lg="4">

          <s-text 
            label="Fecha de Ingreso"
            :value="$fun.formatDateView(item.AgtAdmissionDate)"
            readonly
            disabled
            >
          </s-text> 
        </v-col>
        <v-col v-if="renovation" class="s-col-form" sm="4" md="4" lg="4">
          <s-date 
            v-model="item.AgtBeginDate"
            label="Inicio de Contrato"
            disabled
          />
        </v-col>
          <v-col v-if="!renovation" class="s-col-form" sm="4" md="4" lg="4">
          <s-text
            label="Inicio de Contrato"
            :value="$fun.formatDateView(item.AgtBeginDate)"
            readonly
            disabled
            >
          </s-text>
        </v-col>
        <v-col v-if="renovation || extension" class="s-col-form" sm="4" md="4" lg="4">
          <s-date
            v-model="item.AgtEndDate"
            label="Fin de Contrato"
            :min="MinDay"
            disabled
          />
        </v-col>
        <v-col v-if="!renovation && !extension " class="s-col-form" sm="4" md="4" lg="4">
          <s-text
            label="Fin de Contrato"
            :value="$fun.formatDateView(item.AgtEndDate)"
            readonly
            disabled
            >
          </s-text>
        </v-col>
        <!-- <v-col class="s-col-form" sm="3" md="3" lg="4">
              <s-select-definition  :def="1051"   label="Motivo cese" :clearable="true" v-model="item.TypeTermination"  :disabled="!item.ceseActivo" />
            </v-col> -->
        <!-- <v-col class="s-col-form" sm="3" md="3" lg="4">
              <s-select-definition :def="1059"   label="Situación especial" v-model="item.estadoTrabadr" />
            </v-col> -->
      </v-row>
      <v-row>
        <!-- <v-col class="s-col-form" sm="3" md="3" lg="4">
              <s-date v-model="item.AgtEndDate" label="Fin contrato" :clearable="true"/>
            </v-col> -->
        <v-col
          class="s-col-form "
          xs="4"
          sm="2"
          md="4"
          
          lg="2"
        >
          <s-switch
            label="Jornada Máxima"
            v-model="item.AgtMaximunDay"
            disabled
             
          ></s-switch>
        </v-col>
        <v-col class="s-col-form " sm="2" md="4" lg="2">
          <s-switch
            label="Registrar Cese"
            v-model="RegisterCessation"
            disabled
          ></s-switch>
          <!-- <v-checkbox
            style="padding: 0; margin: 0;"
            :clearable="true"
            v-model="item.RegisterCessation"
            label="Registrar Cese"
          /> -->
        </v-col>
        
        <v-col class="s-col-form" sm="4" md="3" lg="4" v-if="RegisterCessation">
          <s-select-definition
            :def="1051"
            label="Motivo de Cese"
            :clearable="true"
            v-model="item.TypeTermination"
             disabled
          />
        </v-col>
        <v-col class="s-col-form" sm="4" md="3" lg="4" v-if="RegisterCessation">
          <s-text
            :value="$fun.formatDateView(item.AgtTerminationDate)"
            label="Fecha cese"
            disabled
          />
        </v-col>
        <!-- <v-col class="s-col-form" sm="3" md="3" lg="4">
              <s-select-definition :def="1278"   label="Turno" :clearable="true" v-model="item.TypeTurno" />
            </v-col> -->
      </v-row>
      <v-row v-if="!renovation && !extension">
        <!-- <v-col class="s-col-form" sm="4" md="3" lg="4">
          <s-date
            v-model="item.AmsEndDateMovement"
            label="Fecha Fin del Movimiento Anterior"            
          />          
        </v-col> -->
        <v-col class="s-col-form" sm="4" md="3" lg="4">
          <s-date
              v-model="item.AmsStartDateMovement"
              :value="$fun.formatDateView(item.AmsStartDateMovement)"
              label="Fecha Inicio del Movimiento Actual"  
              disabled            
            />
        </v-col>
        
      </v-row>
      <v-row>
        <v-data-table
          :headers="agreementMovemtsHeaders"
          class="elevation-1"          
          :items="modifiedMovements"
          :sort-by="['AmsID','AgtID','SecStatus']" 
          :sort-desc="[true]"
          :group-by="['AgtID']" 
          :group-desc="[true]"
          :items-per-page="4"
        >
        <!-- Slot para la cabecera del grupo 👇 -->
        <template slot="group.header" slot-scope="{ group, headers, isOpen, toggle }">
          <tr>
            <td :colspan="headers.length">
              <v-btn @click="toggle" small icon :class="isOpen ? 'mdi-minus' : 'mdi-plus'"></v-btn>
              <strong>ID de Contrato {{ group }}<br> Inicio {{ $fun.formatDateView(groupByValues[group].AgtBeginDate) }} - Fin {{ $fun.formatDateView(groupByValues[group].AgtEndDate) }}</strong>
            </td>
          </tr>
        </template>
        /<!-- accion para editar -->
        <template v-slot:[`item.AmsID`]="{ item }">
          {{item.AmsID}}
        </template>

        <template v-slot:[`item.changes`]="{ item }">
        <div v-if="item.changes">
          <div v-for="(value, key) in item.changes" :key="key">
            <strong>{{ translateField(key) }}:</strong> 
            {{ value.old ? value.old : "Vacío" }} → 
            <span class="text-red">{{ value.new ? value.new : "Vacío" }}</span>
          </div>
        </div>
      </template>

        <!-- Fechas formateadas -->
        <template v-slot:[`item.AmsAdmissionDate`]="{ item }">        
            {{ $fun.formatDateView(item.AmsAdmissionDate) }}          
        </template>
        <template v-slot:[`item.AmsBeginDate`]="{ item }">
          {{ $fun.formatDateView(item.AmsBeginDate) }}    
        </template>  
        <template v-slot:[`item.AmsEndDate`]="{ item }">        
          {{ $fun.formatDateView(item.AmsEndDate) }}        
        </template>
        <template v-slot:[`item.AmsStartDateMovement`]="{ item }">        
          {{ $fun.formatDateView(item.AmsStartDateMovement) }}        
        </template>
        <template v-slot:[`item.AmsEndDateMovement`]="{ item }">        
          {{ $fun.formatDateView(item.AmsEndDateMovement) }}        
        </template>
        <template v-slot:[`item.AmsTerminationDate`]="{ item }">        
          {{ $fun.formatDateView(item.AmsTerminationDate) }}        
        </template>

        <!-- Salario -->
        <template v-slot:[`item.AmsSalary`]="{ item }">        
            {{ $fun.isAdmin() ? $fun.decryptSalary(item.AmsSalary) : '*****' }}
        </template>
        
        <!-- Estado -->
        <template v-slot:[`item.AmsStatus`]="{ item }">
          <v-chip 
            small 
            :color="item.AmsStatus == 1 ? 'success' : ''" 
            :text-color="item.AmsStatus == 1 ? 'white' : ''" 
            >
            {{ item.AmsStatus == 1  ? 'ACTIVO' : 'INACTIVO' }}
          </v-chip>
        </template>

        <!-- Estado Trabajador -->
        <template v-slot:[`item.AgtStatusName`]="{ item }">
          <v-chip 
            small 
            :color="item.AgtStatusName === 'ACTIVO' ? 'success' : ''" 
            :text-color="item.AgtStatusName === 'ACTIVO' ? 'white' : ''" 
            >
            {{ item.AgtStatusName }}
          </v-chip>
        </template>

        <!-- Máximo Día -->
        <template v-slot:[`item.AmsMaximunDay`]="{ item }">
          <v-chip 
            small 
            :color="item.AmsMaximunDay ? 'success' : ''" 
            :text-color="item.AmsMaximunDay ? 'white' : ''" 
            >
            {{ item.AmsMaximunDay  ? 'SI' : 'NO' }}
          </v-chip>
        </template>
      </v-data-table>
      </v-row>
    </v-card-text>
    <!--<v-card-actions>
        <v-container>
          <v-row>
            <v-col class="s-col-form"><v-btn block text @click="close()">Cancelar</v-btn></v-col>
            <v-col class="s-col-form"><v-btn block color="primary" @click="save()">GUARDAR</v-btn></v-col>
          </v-row>
        </v-container>
      </v-card-actions>-->
    <v-dialog v-model="openDialogMovement" max-width="1000px" persistent>
      <v-card elevation="0">
        <v-card-title>
          <v-row>
            <v-col cols="12">
              <h3 class="headline mb-0">Movimiento de Contrato</h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
      <v-row>
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <s-select-definition
            :def="1060"
            label="Planilla"
            v-model="movement.TypePayroll"
            :readonly="extension ? extension : renovation"
          />
        </v-col>
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <s-select-area
            label="Areaa"
            v-model="movement.AreID"
            :text="movement.AreName"
          ></s-select-area>
        </v-col>
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <s-select-area
            v-model="movement.CceID"
            :text="movement.CceName"
            centercost
            :clearable="true"
            label="Centro de Costo"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <s-select-position
            v-model="movement.PstID"
            :text="movement.PstName"
            centercost
            :clearable="true"
            label="Cargo"
          />
        </v-col>
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <s-select
            label="Cuadrilla"
            :items="quadrille"
            autocomplete
            clearable
            item-text="GngName"
            item-value="GngID"
            v-model="movement.GngID"
          ></s-select>
        </v-col>
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <s-select-business-line
            v-model="item.BslID"
            :cceID="item.CceID"
            :clearable="true"
            :item-text="movement.BslName"
            label="Línea de Negocio"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <s-select-definition
            :def="1482"
            label="Categoria"
            v-model="movement.TypeCategory"
          />
        </v-col>
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <s-select-definition
            :def="1069"
            label="Tipo de Contrato"
            v-model="movement.TypeContract"
          />
        </v-col>
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <s-text
              label="N° de hijos"
              number
              v-model="movement.AmsChildrenQuantity"
              :min="0"
            >
          </s-text>
        </v-col>
      </v-row>
      <v-row v-if="movement.TypeContract == 9">
        <v-col>
          <s-textarea 
          label="Describa en Servicio" 
          :height=80 
          :readonly="extension ? extension : renovation"
          :disabled="extension ? extension : renovation"
          v-model="movement.AmsServiceDescription"
          ></s-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <s-select-definition
            :def="1039"
            :dgr="4"
            label="Tipo de Trabajador"
            v-model="movement.TypeWorker"
          />
        </v-col>
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <s-select-definition
            :def="1481"
            label="Régimen laboral"
            v-model="movement.TypeLaborRegime"
          />
        </v-col>
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <s-select-definition
            :def="1077"
            label="Cat. Ocupacional"
            v-model="movement.TypeOccupationalCategory"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <!--<s-select-definition :def="1376"  label="Profesion" v-model="item.profesion" />-->
          <s-select
            label="Profesión"
            :items="profession"
            autocomplete
            clearable
            item-text="PrnDescription"
            item-value="PrnID"
            v-model="movement.PrnID"
          ></s-select>
        </v-col>
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <!-- <s-select-definition :def="1480"  label="Nivel Educativo" :clearable="true" v-model="item.TypeEducationLevel" /> -->
          <s-select
            label="Nivel Educativo"
            :items="EducationLevels"
            autocomplete
            item-text="LveDescription"
            item-value="LveID"
            v-model="movement.LveID"
          ></s-select>
        </v-col>
        <v-col class="s-col-form" sm="4" md="4">
          <s-select-definition
            :def="1071"
            label="Estado civil"
            v-model="movement.TypeCivilStatus"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <s-select-definition
            :def="1070"
            label="Situación especial"
            v-model="movement.TypeSpecialSituation"
          />
        </v-col>
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <!-- <s-text   v-if="$fun.isAdmin()" label="Sueldo/Jornal" number v-model="item.AgtSalary" :min="0"  :readonly="extension ? extension : renovation">            
           </s-text> -->
          <s-money-input v-if="$fun.isAdmin()"  label="Sueldo/Jornal"  v-model="movement.AmsSalary" readonly> </s-money-input>
          <s-text
              v-else
              label="Sueldo/Jornal"              
              :value="'*****'"
              readonly
            ></s-text>
        </v-col>
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <s-select-definition
            :def="1278"
            label="Turno"
            v-model="movement.TypeTurno"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="s-col-form" sm="4" md="4" lg="4">
          <s-select-definition
            :def="1045"
            label="Situación"
            v-model="movement.TypeSituation"
          />
          
        </v-col>
        <v-col class="s-col-form" sm="4" md="4" lg="4" v-if="movement.TypeSituation == 2 ? true :false">
          <s-select-campaign-se 
                label="Campaña"
                v-model="movement.CmpID"
              />
        </v-col>
        <v-col v-if="movement.TypeSpecialSituation == 4 || movement.TypeSpecialSituation == 5" class="s-col-form" sm="4" md="4" lg="4">
          <s-text
            label="Meses de Prueba"
            v-model="movement.AmsMonthsProbation"
            type="number"
            :readonly="extension ? extension : renovation"
            :disabled="extension ? extension : renovation"
          />
        </v-col>
      </v-row>      
      <v-row>
        <v-col  v-if="!renovation && !extension " class="s-col-form" sm="4" md="4" lg="4">
          <s-date
            v-model="movement.AmsAdmissionDate"
            label="Fecha de Ingreso"
            :clearable="true"
          />
        </v-col>
        <v-col  v-if="renovation || extension " class="s-col-form" sm="4" md="4" lg="4">
          <s-text 
            label="Fecha de Ingreso"
            :value="$fun.formatDateView(movement.AmsAdmissionDate)"
            >
          </s-text> 
        </v-col>
        <v-col  v-if="!renovation && !extension " class="s-col-form" sm="4" md="4" lg="4">
          <s-date
            v-model="movement.AmsBeginDate"
            label="Inicio de Contrato"
            :clearable="true"
          />
        </v-col>
        <v-col  v-if="renovation || extension " class="s-col-form" sm="4" md="4" lg="4">
          <s-text 
            label="Inicio de Contrato"
            :value="$fun.formatDateView(movement.AmsBeginDate)"
            >
          </s-text> 
        </v-col>
        <v-col v-if="!renovation && !extension " class="s-col-form" sm="4" md="4" lg="4">
          <s-date
            v-model="movement.AmsEndDate"
            label="Fin de Contrato"
            :clearable="true"
          />
        </v-col>
        <v-col v-if="renovation || extension " class="s-col-form" sm="4" md="4" lg="4">
          <s-text 
            label="Fin de Contrato"
            :value="$fun.formatDateView(movement.AmsEndDate)"
            >
          </s-text> 
        </v-col>
        
      </v-row>
      <v-row>
        <v-col
          class="s-col-form "
          xs="4"
          sm="2"
          md="4"
          lg="2"
        >
          <s-switch
            label="Jornada Máxima"
            v-model="movement.AmsMaximunDay"
          ></s-switch>
        </v-col>
        <v-col class="s-col-form " sm="2" md="4" lg="2" v-if="movement.RegisterCessation">
          <s-switch
            label="Registrar Cese"
            v-model="movement.RegisterCessation"
            disabled
          ></s-switch>
        </v-col>
        
        <v-col class="s-col-form" sm="4" md="3" lg="4" v-if="movement.RegisterCessation">
          <s-select-definition
            :def="1051"
            label="Motivo de Cese"
            :clearable="true"
            v-model="movement.RegisterCessation"
            
          />
        </v-col>
        <v-col class="s-col-form" sm="4" md="3" lg="4" v-if="movement.RegisterCessation">
          <s-date
            v-model="movement.AgtTerminationDate"
            label="Fecha cese"
          />
        </v-col>
      </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="saveMovement()">Guardar</v-btn>
          <v-btn color="error" @click="closeDialog()">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      
  </v-card>
</template>

<script>
import SSelectArea from "../../../components/Utils/SSelectArea.vue";
import _sQuadriService from "@/services/HumanResource/hmnGang.js";
import _sProfService from "@/services/HumanResource/ProfessionsGen.js";
import SSelectBusinessLine from "../../../components/Utils/SSelectBusinessLine.vue";
import _sCttoService from "@/services/HumanResource/HmnContractService.js";
import _sHmnAgreementMovemt from "@/services/HumanResource/HmnAgreementMovemt.js";
import sToolbarPerson from "@/components/Utils/RecursosHm/sToolbarAgreementPerson";
import sEducationLevel from "@/services/HumanResource/HmnEducationalLevel.js";
import SSelectPosition from "../../../components/Utils/sSelectPosition.vue";
import _constapp from "@/plugins/const";

export default {
  name: "sDatosLaborals",
  components: {
    SSelectArea,
    SSelectBusinessLine,
    sToolbarPerson,
    SSelectPosition,
  },
  props: {
    visibleTitle: {
      type: Boolean,
      default: true,
    },
    full: {
      type: Boolean,
      default: false,
    },
    typeperson: {
      type: Number,
      default: 1,
    },
    addTypeSex: {
      type: Boolean,
      default: false,
    },
    addBirthDate: {
      type: Boolean,
      default: false,
    },
    addDriver: {
      type: Boolean,
      default: false,
    },
    datawork: { type: Object },

    extension: {
      type: Boolean,
      default: false,
    },
    renovation: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      dataWor: {},
      profession: [],
      EducationLevels: [],
      quadrille: [],
      Position: [],
      editing: false,
      item: {
        TypePayroll: null,
        AreID: null,
        // AreName: "",
        // CceName: "",
        // PstName:"",
        PstID: null,
        CmpID: null,
        BslID: null,
        TypeWorker: null,
        PrnID: null,
        GngID: null,
        TypeSituation: null,
        AgtAdmissionDate: null,
        AgtBeginDate: null,
        AgtEndDate: null,
        LveID: null,
        TypeSpecialSituation: null,
        TypeTermination: null,
        TypeCivilStatus: null,
        AgtTerminationDate: null,
        TypeLaborRegime: null,
        //RegisterCessation: false,
        centercost: null,
        TypeCategory: null,
        TypeContract: null,
        TypeTurno: null,
        TypeOccupationalCategory: null,
        AgtSalary: null,
        AgtChildrenQuantity: null,
        AgtStatus: null,
        PrsDocumentNumber: null,
        CceID: 0,
        AgtMaximunDay:null,
        AgtRenovation:null,
        AgtMonthsProbation:null,
        RqpID:null,
        AgreementMovemtsCE:[],
        UsrUpdateID:this.$fun.getUserID(),
        AmsStartDateMovement: null,
        AmsEndDateMovement: null,
        MinDay: null,
      },
      menu3: false,
      hasPermissionSeeSalary:false,
      config: {},
      dialog: false,
      RegisterCessation:null,
      agreementMovemtsHeaders: [ 
        //accion
        //{ text: "Acciones"                      , value: "actions"                        , sortable: false   , width: 30   },
        { text: "ID de Movimiento"                , value: "AmsID"                          , sortable: false   , width: 220,  },
        { text: "ID de Contrato"                  , value: "AgtID"                          , sortable: false   , width: 250, },
        // { text: "Incio de Contrato"               , value: "AgtBeginDate"                   , sortable: false   , width: 20,  },
        // { text: "Fin de Contrato"                 , value: "AgtEndDate"                     , sortable: false   , width: 20,  },       
        { text: "Fecha de Ingreso"                , value: "AmsAdmissionDate"               , sortable: false   , width: 80,  },
        { text: "Fecha de Inicio de Movimiento"   , value: "AmsStartDateMovement"           , sortable: false   , width: 80,  },
        { text: "Fecha de Fin de Movimiento"      , value: "AmsEndDateMovement"             , sortable: false   , width: 80,  }, 
        { text: "Estado del Trabajador"           , value: "AgtStatusName"                  , sortable: false   , width: 80,  },       
        { text: "Tipo de Movimiento"              , value: "TypeAmsName"                    , sortable: false   , width: 80,  }, 
        { text: "Estado del Movimiento"           , value: "AmsStatus"                  , sortable: false   , width: 80,  }, 
        { text: "Campos Modificados"              , value: "changes"                        , sortable: false   , width: 350, },
        { text: "Tipo de Planilla"                , value: "TypePayrollName"                , sortable: false   , width: 100, },
        { text: "Área"                            , value: "AreName"                        , sortable: false   , width: 100, },
        { text: "Centro de Costo"                 , value: "CceName"                        , sortable: false   , width: 100, },
        { text: "Cargo"                           , value: "PstName"                        , sortable: false   , width: 100, },
        { text: "Cuadrilla"                       , value: "GngName"                        , sortable: false   , width: 100, },
        { text: "Línea de Negocio"                , value: "BslName"                        , sortable: false   , width: 80,  },
        { text: "Campaña"                         , value: "CmpName"                        , sortable: false   , width: 80,  },
        { text: "Categoria"                       , value: "TypeCategoryName"               , sortable: false   , width: 80,  },
        { text: "Tipo de Contrato"                , value: "TypeContractName"               , sortable: false   , width: 80,  },
        { text: "Tipo de Trabajador"              , value: "TypeWorkerName"                 , sortable: false   , width: 80,  },
        { text: "Régimen Laboral"                 , value: "TypeLaborRegimeName"            , sortable: false   , width: 80,  },
        { text: "Categoria Ocupacional"           , value: "TypeOccupationalCategoryName"   , sortable: false   , width: 80,  },
        { text: "Profesión"                       , value: "PrnDescription"                 , sortable: false   , width: 80,  },
        { text: "Nivel Educativo"                 , value: "LveDescription"                 , sortable: false   , width: 80,  },
        { text: "Estado Civil"                    , value: "TypeCivilStatusName"            , sortable: false   , width: 80,  },
        { text: "Situación"                       , value: "TypeSituationName"              , sortable: false   , width: 80,  },
        { text: "Situación Especial"              , value: "TypeSpecialSituationName"       , sortable: false   , width: 80,  },
        { text: "Sueldo/Jornal"                   , value: "AmsSalary"                      , sortable: false   , width: 80,  },
        { text: "N° de Hijos"                     , value: "AmsChildrenQuantity"            , sortable: false   , width: 80,  },
        //{ text: "Estado del Trabajador"           , value: "AmsStatusName"                  , sortable: false   , width: 80,  },
        { text: "Turno"                           , value: "TypeTurnoName"                  , sortable: false   , width: 80,  },
        { text: "Descripcion del Servicio"        , value: "AmsServiceDescription"                    , sortable: false   , width: 80,  },
        { text: "Meses De Prueba"                 , value: "AmsMonthsProbation"               , sortable: false   , width: 80,  },
        //{ text: "Fecha de Ingreso"                , value: "AmsAdmissionDate"               , sortable: false   , width: 80,  },
        { text: "Inicio de Contrato"              , value: "AmsBeginDate"                   , sortable: false   , width: 80,  },
        { text: "Fin de Contrato"                 , value: "AmsEndDate"                     , sortable: false   , width: 80,  },
        { text: "Jornada Máxima"                  , value: "AmsMaximunDay"                  , sortable: false   , width: 80,  },
        { text: "Motivo de Cese"                  , value: "TypeTerminationName"            , sortable: false   , width: 80,  },
        { text: "Fecha de Cese"                   , value: "AmsTerminationDate"             , sortable: false   , width: 80,  },          
        // { text: "Estado"                      , value: "SecStatus"                      , sortable: false   , width: 30   },
        // { text: "Acciones"                    , value: "actions"                        , sortable: false   , width: 30   },
      ],
      fieldTranslations: {
        AmsID: "ID Movimiento",
        AgtID: "ID Contrato",
        AmsAdmissionDate: "Fecha Ingreso",
        AmsStartDateMovement: "Fecha Inicio del Movimiento",
        AmsEndDateMovement: "Fecha Fin del Movimiento",
        AmsStatusName: "Estado del Trabajador",
        TypeAmsName: "Tipo de Movimiento",
        AmsStatusName: "Estado del Trabajador",
        TypePayrollName: "Tipo de Planilla",
        AreName: "Área",
        CceName: "Centro de Costo",
        PstName: "Cargo",
        GngName: "Cuadrilla",
        BslName: "Línea de Negocio",
        CmpName: "Campaña",
        TypeCategoryName: "Categoria",
        TypeContractName: "Tipo de Contrato",
        TypeWorkerName: "Tipo de Trabajador",
        TypeLaborRegimeName: "Régimen Laboral",
        TypeOccupationalCategoryName: "Categoria Ocupacional",
        PrnDescription: "Profesión",
        LveDescription: "Nivel Educativo",
        TypeCivilStatusName: "Estado Civil",
        TypeSituationName: "Situación",
        TypeSpecialSituationName: "Situación Especial",
        AmsSalary: "Sueldo/Jornal",
        AmsChildrenQuantity: "N° de Hijos",
        TypeTurnoName: "Turno",
        AmsServiceDescription: "Descripcion del Servicio",
        AmsMonthsProbation: "Meses De Prueba",
        AmsBeginDate: "Inicio de Contrato",
        AmsEndDate: "Fin de Contrato",
        AmsMaximunDay: "Jornada Máxima",
        TypeTerminationName: "Motivo de Cese",
        AmsTerminationDate: "Fecha de Cese",
        AmsMaximunDayName: "Jornada Máxima",
        // AmsSalary: "Salario",
        // StartDate: "Fecha Inicio",
        // EndDate: "Fecha Fin",
        // TypeAmsName : "Tipo de Contrato",
        // AmsTerminationDate: "Fecha de Cese"   ,

      },
      agreementMovemtsDetail:[],
      modifiedMovements: [], // Aquí guardaremos los cambios detectados
      groupByValues: {}, // Objeto para almacenar los valores de AgtBeginDate y AgtEndDate por AgtID
      openDialogMovement: false,
      movement: {
        AmsID: null,
        AgtID: null,
        AmsAdmissionDate: null,
        AmsBeginDate: null,
        AmsEndDate: null,
        AmsStartDateMovement: null,
        AmsEndDateMovement: null,
        AmsTerminationDate: null,
        SecStatus: null,
        TypeAmsName: null,
        AmsStatus: null,
        AmsStatusName: null,
        TypePayroll: null,
        AreID: null,
        AreName: null,
        CceName: null,
        PstName: null,
        PstID: null,
        CceID: null,
        CmpID: null,
        BslID: null,
        TypeCategory: null,
        TypeContract: null,
        TypeWorker: null,
        AmsMonthsProbation: null,
        TypeLaborRegime: null,
        TypeOccupationalCategory: null,
        LveID: null,
        TypeCivilStatus: null,
        TypeSituation: null,
        TypeSpecialSituation: null,
        AgtChildrenQuantity: null,
        TypeTurno: null,
        AgtStatus: null,
        PrnID: null,
        GngID: null,
        AmsMaximunDay: null,
        AmsRenovation: null,
        RqpID: null,
        UsrUpdateID: null,
        RegisterCessation: null,
      },
    };
  },
  methods: {
    dialogOpen(item) {
      //console.log("movementITEM",item);
      this.movement = item;
      this.movement.RegisterCessation = item.AmsTerminationDate ? 1 : false;
      this.movement.AmsSalary = this.$fun.decryptSalary(item.AmsSalary);
      this.openDialogMovement = true;
    },
    saveMovement(){
      this.movement.AmsSalary = this.$fun.encryptSalary(this.movement.AmsSalary);  
      this.movement.AmsMonthsProbation = this.movement.TypeSpecialSituation == 4 || this.movement.TypeSpecialSituation == 5 ? (this.movement.AmsMonthsProbation == 0 ? 3 :this.movement.AmsMonthsProbation) : 0;    
      this.movement.AmsServiceDescription = this.movement.TypeContract == 9 ? this.movement.AmsServiceDescription : null;
      _sHmnAgreementMovemt.save(
        this.movement, this.$fun.getUserID()
      ).then(() => {        
        //secStatus == 1 es activo o si es el ultimo movimiento de todos en el contrato
        //sacar el ultimo movimiento de todos los movimientos
        let islast = this.agreementMovemtsDetail[this.agreementMovemtsDetail.length-1].AmsID == this.movement.AmsID;
        //console.log("islast",islast);
        
        if(this.movement.SecStatus == 1 || islast){
          //refrescar los datos de item 
          this.item.AgtEndDate = this.movement.AmsEndDate;
          this.item.AgtRenovation = this.movement.AmsRenovation;
          this.item.AgtMaximunDay = this.movement.AmsMaximunDay;
          this.item.AgtStatus = this.movement.AmsStatus;
          this.item.AgtTerminationDate = this.movement.AmsTerminationDate;
          this.item.AgtBeginDate = this.movement.AmsBeginDate;
          this.item.AgtAdmissionDate = this.movement.AmsAdmissionDate;
          this.item.AgtSalary = this.$fun.decryptSalary(this.movement.AmsSalary);
          this.item.AgtChildrenQuantity = this.movement.AmsChildrenQuantity;
          this.item.TypeTurno = this.movement.TypeTurno;
          this.item.TypeSituation = this.movement.TypeSituation;
          this.item.TypeSpecialSituation = this.movement.TypeSpecialSituation;
          this.item.TypeCivilStatus = this.movement.TypeCivilStatus;
          this.item.TypeOccupationalCategory = this.movement.TypeOccupationalCategory;
          this.item.TypeLaborRegime = this.movement.TypeLaborRegime;
          this.item.TypeWorker = this.movement.TypeWorker;
          this.item.TypeContract = this.movement.TypeContract;
          this.item.TypeCategory = this.movement.TypeCategory;
          this.item.BslID = this.movement.BslID;
          this.item.CmpID = this.movement.CmpID;
          this.item.GngID = this.movement.GngID;
          this.item.PstID = this.movement.PstID;
          this.item.CceID = this.movement.CceID;
          this.item.AreID = this.movement.AreID;
          this.item.TypePayroll = this.movement.TypePayroll;
          this.item.UsrUpdateID = this.$fun.getUserID();    
        }
        this.closeDialog();
        this.$fun.alert('Actualizado correctamente','success')
      }).catch(()=>{
        //console.log("error",e);
        this.$fun.alert('error al actualizar','error')
      }).finally(()=>{
        //cerrar modal de edicion de contrato
        this.clear();
        this.$emit("closeModals");
      });
    },
    closeDialog(){
      this.movement = {};
      this.openDialogMovement = false;
    },
    initialize() {
      this.getProfession();
      this.getQuadrille();
      this.getEducationLevels();
      //this.getPositiom();
      
        //console.log("DATAWORK", this.datawork);
       

        this.agreementMovemtsDetail = this.datawork.AgreementCE.AgreementMovemtsCE;
        this.getModifiedFields(this.agreementMovemtsDetail);
        // Crear una copia sin AgreementMovemtsCE
        const { AgreementMovemtsCE, ...rest } = this.datawork.AgreementCE;
        

        this.item = { ...rest };
        //console.log("AgtSalary",this.item.AgtSalary);
        this.item.AgtSalary = this.$fun.decryptSalary(this.item.AgtSalary);
        this.MinDay = this.item.AgtEndDate;
        // this.item = { ...this.datawork.AgreementCE };

        // this.item.TypePayroll                 = this.datawork.AgreementCE.TypePayroll 
        // this.item.AreName                     = this.datawork.AgreementCE.AreName 
        // this.item.AreID                       = this.datawork.AgreementCE.AreID   
        // this.item.CceName                     = this.datawork.AgreementCE.CceName 
        // this.item.CceID                       = this.datawork.AgreementCE.CceID   
        // this.item.PstName                     = this.datawork.AgreementCE.PstName 
        // this.item.PstID                       = this.datawork.AgreementCE.PstID   
        // this.item.GngID                       = this.datawork.AgreementCE.GngID   
        // this.item.BslID                       = this.datawork.AgreementCE.BslID   
        // this.item.BslName                     = this.datawork.AgreementCE.BslName 
        // this.item.CmpID                       = this.datawork.AgreementCE.CmpID   
        // this.item.TypeCategory                = this.datawork.AgreementCE.TypeCategory 
        // this.item.TypeContract                = this.datawork.AgreementCE.TypeContract
        // this.item.TypeWorker                  = this.datawork.AgreementCE.TypeWorker
        // this.item.TypeLaborRegime             = this.datawork.AgreementCE.TypeLaborRegimeg
        // this.item.TypeOccupationalCategory    = this.datawork.AgreementCE.TypeOccupationalCategory 
        // this.item.LveID                       = this.datawork.AgreementCE.LveID
        // this.item.TypeCivilStatus             = this.datawork.AgreementCE.TypeCivilStatus
        // this.item.TypeSituation               = this.datawork.AgreementCE.TypeSituation
        // this.item.TypeSpecialSituation        = this.datawork.AgreementCE.TypeSpecialSituation
        // this.item.AgtSalary                   = this.datawork.AgreementCE.AgtSalary
        // this.item.AgtChildrenQuantity         = this.datawork.AgreementCE.AgtChildrenQuantity
        // this.item.AgtStatus                   = this.datawork.AgreementCE.AgtStatus
        // this.item.TypeTurno                   = this.datawork.AgreementCE.TypeTurno
        // this.item.AgtAdmissionDate            = this.datawork.AgreementCE.AgtAdmissionDate
        // this.item.AgtBeginDate                = this.datawork.AgreementCE.AgtBeginDate
        // this.item.AgtEndDate                  = this.datawork.AgreementCE.AgtEndDate     
        // this.item.AgtMaximunDay               = this.datawork.AgreementCE.AgtMaximunDay
        // this.item.TypeTermination             = this.datawork.AgreementCE.TypeTermination
        // this.item.AgtTerminationDate          = this.datawork.AgreementCE.AgtTerminationDate
        // this.item.AgtRenovation               = this.datawork.AgreementCE.AgtRenovation, // Renovacion
        // this.item.RqpID                       = this.datawork.AgreementCE.RqpID, //requerimiento de Personal

        // this.item.SecStatus                   = this.datawork.AgreementCE.SecStatus
        // this.item.UsrCreateID                 = this.datawork.AgreementCE.UsrCreateID
        
        // this.item.RegisterCessation = !!this.datawork.AgreementCE.AgtTerminationDate; // Booleano
        // this.item.AgtMaximunDay = this.datawork.AgtMaximunDay || false;

        this.RegisterCessation                = this.datawork.AgreementCE.AgtTerminationDate ? 1 : false;

        this.item.UsrUpdateID                 = this.$fun.getUserID(),
        this.item.NtpID                       = this.datawork.NtpID  //Persona de Contrato
        this.item.PrsDocumentNumber           = this.datawork.PrsDocumentNumber
        // this.item.AgreementMovemtsCE[0].AmsStatus       = 0
        // this.item.AgreementMovemtsCE[0].UsrUpdateID     = this.$fun.getUserID(),
        this.prepareGroupByValues();
      //console.log("itemm",this.item);
    },

    translateField(field) {
      return this.fieldTranslations[field] || field;
    },

    getModifiedFields(movements) {
      let sortedMovements = [...movements].sort((a, b) => a.AmsID - b.AmsID);
      let modified = [];

      for (let i = 0; i < sortedMovements.length; i++) {
        let current = sortedMovements[i];
        let previous = sortedMovements[i - 1]; // Movimiento anterior

        let changes = {};

        // Solo comparamos si hay un anterior del mismo contrato
        if (previous && previous.AgtID === current.AgtID) {
          const excludeFields = [
            "TypeTermination", 
            "AgtID", 
            "TypeAms",
            "AmsStartDateMovement",
            "AmsEndDateMovement",
            "AmsStatus",
            "AmsID",
            "SecStatus",
            "PrnID",
            "AreID",
            "CceID",
            "BslID",
            "PstID",
            "GngID",
            "CmpID",
            "TypePayroll",
            "TypeCategory",
            "TypeContract",
            "TypeWorker",
            "TypeLaborRegime",
            "TypeOccupationalCategory",
            "LveID",
            "TypeCivilStatus",
            "TypeSituation",
            "TypeSpecialSituation",
            "TypeTurno",        
            "TypeTermination",            
            "AmsRenovation",
            "RqpID",
            "UsrCreateID",
            "UsrUpdateID",
            "AmsMaximunDay"
          ]; // Lista de campos a excluir
          const dateFields = ["AmsBeginDate", "AmsEndDate","AmsTerminationDate","AmsEndDateMovement","AmsStartDateMovement"]; // Lista de campos de fecha a formatear

          Object.keys(current).forEach(key => {
          
            if (!excludeFields.includes(key) && previous[key] !== current[key]) {
              let oldValue = previous[key]  || "Vacío";
              let newValue = current[key]   || "Vacío";

              // Si es salario, desencriptar antes de comparar
              if (key === "AmsSalary") {
                oldValue = this.$fun.decryptSalary(previous[key]);
                newValue = this.$fun.decryptSalary(current[key]);

                if (oldValue === newValue) return; // No agregar si no hubo cambios reales
              }
              // Si es un campo de fecha, formatearlo
              if (dateFields.includes(key)) {
                oldValue = oldValue !== "Vacío" ? this.$fun.formatDateView(oldValue)  : oldValue;
                newValue = newValue !== "Vacío" ? this.$fun.formatDateView(newValue)  : newValue;
              }

              // Si es salario, desencriptarlo
              if (key === "AmsSalary") {
                oldValue = this.$fun.decryptSalary(previous[key]);
                newValue = this.$fun.decryptSalary(current[key]);
              }

              changes[key] = { old: oldValue, new: newValue };
            }
          });
        }

        // Guardamos el movimiento con sus cambios (o vacío si no hay cambios)
        modified.push({ ...current, changes });
      }

      this.modifiedMovements = modified;
      //console.log("this.modifiedMovementssss",this.modifiedMovements);
    },

    prepareGroupByValues() {
      // Crear un mapa para almacenar el AgtID y su AmsTerminationDate si existe
      const terminationDates = {};

      // Primera pasada: Identificar si algún movimiento tiene AmsTerminationDate
      this.agreementMovemtsDetail.forEach(item => {
        if (item.AmsTerminationDate) {
          terminationDates[item.AgtID] = item.AmsTerminationDate; // Guardar la fecha de terminación
        }
      });

      // Segunda pasada: Asignar los valores correctos
      this.groupByValues = this.agreementMovemtsDetail.reduce((acc, item) => {
        if (!acc[item.AgtID]) {
          acc[item.AgtID] = {
            AgtBeginDate: item.AgtBeginDate,
            AgtEndDate: terminationDates[item.AgtID] ?? item.AgtEndDate, // Si hay AmsTerminationDate, usarla
          };
        }
        return acc;
      }, {});
      // this.groupByValues = this.agreementMovemtsDetail.reduce((acc, item) => {
      //   if (!acc[item.AgtID]) {
      //     acc[item.AgtID] = {
      //       AgtBeginDate: item.AgtBeginDate,
      //       AgtEndDate: item.AmsTerminationDate ?? item.AgtEndDate, // Usa AmsTerminationDate si está definida, sino AgtEndDate
      //     };
      //   }
      //   return acc;
      // }, {});
    },

    validateData() {
      let valid = true;
      //console.log("Data Laboral", this.item);

      if (this.item.NtpID == null) {
        this.$fun.alert("Por favor seleccionar una Persona", "warning");
        valid = false;
      }

      if (this.item.AreID == null) {
        this.$fun.alert("El campo Area es obligatorio", "warning");
        valid = false;
      }

      if (this.item.CceID == null) {
        this.$fun.alert("El campo Centro de Costo es obligatorio", "warning");
        valid = false;
      }

      if (this.item.BslID == null) {
        this.$fun.alert("El campo Línea de Negocio es obligatorio", "warning");
        valid = false;
      }

      if (this.item.PstID == null) {
        this.$fun.alert("El campo Cargo es obligatorio", "warning");
        valid = false;
      }

      if (this.item.LveID == null) {
        this.$fun.alert("El campo Nivel Educativo es obligatorio", "warning");
        valid = false;
      }

      if (this.item.AgtAdmissionDate == null) {
        this.$fun.alert(
          "Fecha inválida del campo Fecha de Ingreso ",
          "warning"
        );
        valid = false;
      }

      if (this.item.AgtBeginDate == null) {
        this.$fun.alert(
          "Fecha inválida del campo Incio de Contrato ",
          "warning"
        );
        valid = false;
      }

      if (this.item.AgtEndDate == null) {
        this.$fun.alert("Fecha inválida del campo Fin de Contrato ", "warning");
        valid = false;
      }

      // if (this.item.AmsStartDateMovement == null) {
      //   this.$fun.alert("Fecha inválida del campo Fecha Inico del Movimiento Actual ", "warning");
      //   valid = false;
      // }

      // if (this.item.AmsEndDateMovement == null) {
      //   this.$fun.alert("Fecha inválida del campo Fecha Fin del Movimiento Anterior ", "warning");
      //   valid = false;
      // }

      return valid;
    },

    clear() {},

    close() {
      this.$refs.document.focus();
      this.$emit("toclose", 2);
      this.clear();
    },
    save() {

    },
    getProfession() {
      let obj = {
        prnStatus: 1,
      };
      _sProfService.list(obj, this.$fun.getUserID()).then((resp) => {
        if (resp.status == 200) {
          //console.log("tag", resp.data);
          this.profession = resp.data;
        }
      });
    },
    getEducationLevels() {
      sEducationLevel.list(this.$fun.getUserID()).then((resp) => {
        if (resp.status == 200) {
         // console.log("tag", resp.data);
          this.EducationLevels = resp.data;
          this.TypeEducationLevel = 1;
        }
      });
    },
    getQuadrille() {
      let obj = {
        SecStatus: 1,
      };
      _sQuadriService.list(obj, this.$fun.getUserID()).then((resp) => {
        if (resp.status == 200) {
         // console.log("tag", resp.data);
          this.quadrille = resp.data;
        }
      });
    },
    getPositiom() {
      let obj = { AreID: null, TypePosition: 1 };
      _sCttoService.Positionlist(obj, this.$fun.getUserID()).then((resp) => {
        if (resp.status == 200) {
         // console.log("tag", resp.data);
          this.Position = resp.data;
        }
      });
    },
    getData() {
      if (this.validateData()) {

        this.item.AgtTerminationDate  = this.RegisterCessation ? this.item.AgtTerminationDate   : null;
        this.item.TypeTermination     = this.RegisterCessation ? this.item.TypeTermination      : null;
        this.item.CmpID               = this.item.TypeSituation == 2 ? this.item.CmpID          : null;
        this.item.Supervision         = this.item.TypeSpecialSituation == 4 ? 0 : 1;

        if(this.$fun.isAdmin()){
          //console.log("Data Laboral",this.item.AgtSalary);
          this.item.AgtSalary                = this.encryptSalary(this.item.AgtSalary);          
        }

        if(this.renovation){
          this.item.AgtTerminationDate  = null;
          this.item.TypeTermination     = null;
          this.item.AgtStatus = 1;
          this.item.SecStatus = 1;
        }

        if(this.item.AgtTerminationDate && this.item.TypeTermination){
          this.item.AgtStatus = 2;
        }


        //console.log("Data Laboral",this.item);

         // return null;
        return  this.item //{

          //AgtID : null , //this.AgtID,
         
          // AgtChildrenQuantity: this.item.AgtChildrenQuantity, // Num Hijos
          // AgtBeginDate: this.item.AgtBeginDate,
          // AgtEndDate: this.item.AgtEndDate,
          // AgtTerminationDate: this.item.RegisterCessation
          //   ? this.item.AgtTerminationDate
          //   : null,
          // AgtMaximunDay: this.item.AgtMaximunDay,
          // AgtSalary: this.item.AgtSalary,
          // AgtAdmissionDate: this.item.AgtAdmissionDate,
          // AgtStatus: this.item.AgtStatus,
          // //WkrID:null,
          // TypePayroll: this.item.TypePayroll,
          // AreID: this.item.AreID,
          // CceID: this.item.CceID,
          // TypeCategory: this.item.TypeCategory,
          // CmpID: this.item.CmpID,
          // GngID: this.item.GngID,
          // BslID: this.item.BslID,
          // TypeSituation: this.item.TypeSituation,
          // TypeSpecialSituation: this.item.TypeSpecialSituation,
          // TypeCivilStatus: this.item.TypeCivilStatus,
          // TypeWorker: this.item.TypeWorker,
          // TypeLaborRegime: this.item.TypeLaborRegime,
          // TypeOccupationalCategory: this.item.TypeOccupationalCategory,
          // LveID: this.item.LveID,
          // PstID: this.item.PstID,
          // PrnID: this.item.PrnID,
          // TypeContract: this.item.TypeContract,
          // TypeTermination: this.item.RegisterCessation
          //   ? this.item.TypeTermination
          //   : null,
          // TypeTurno: this.item.TypeTurno,
          // AgtRenovation: 0, // Renovacion
          // RqpID: 0, //requerimiento de Personal
          // //ceseActivo: this.item.ceseActivo,
          // //AmsID:null,//Movimiento de Contrato
          // SecStatus: 1,
          // UsrCreateID: this.$fun.getUserID(),
          // UsrUpdateID: this.$fun.getUserID(),

          // //Persona de Contrato
          // NtpID: this.item.NtpID,
        //};
      } else {
        return null;
      }
    },
    returnPerson(value, item) {
      if (value != null) {
        item.NtpFullName = value.NtpFullName;
        item.NtpID = value.NtpID;
      } else {
        //item.NtpFullName = null;
        item.NtpID = null;
      }
    },
  },
  watch: {
    'item.AgtSalary'(){
      //console.log("PRUEBA sadf sf",this.item.AgtSalary);
    }

  },
  created() {
    if (this.datawork) {
        this.initialize();
    } else {
        console.warn("datawork aún no está definido. Esperando su asignación.");
    }
    //this.initialize();
  },
  mounted(){
    this.$refs.PersonRef.toggleSaveData(this.item.PrsDocumentNumber)
    //this.item.RegisterCessation = false; // Booleano
  }
};
</script>
