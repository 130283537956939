import Vue from "vue";
import Service from "../Service";

const menu = "position/";

export default {
    pagination(dtr) {
        console.log('datrr', dtr);
        return Vue.prototype.$http.post(menu + "positions", dtr);
    },
    laborRegimeList() {
        return Vue.prototype.$http.post(menu + "labor_regime_list", {});
    },
    save(obj, usr) {
        return Vue.prototype.$http.post(menu + "create", obj, {
            params: { usrID: usr },
        });
    },
    PDF(PstID, image) {
        const formData = new FormData();
        formData.append("file", image);
        return Vue.prototype.$http.post(menu + "reportpdf", formData, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { PstID: PstID },
        });
    },
    excel() {
        return Vue.prototype.$http.post(
            menu + "positionexport", {}, {
                responseType: "blob",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
    },
    report(obj, requestID) {
        return Vue.prototype.$http.post(menu + "reportexport", obj, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                typepayroll: obj.typepayroll,
                typecritic: obj.typecritict,
                typeoption: obj.typeoption,
                datebegin: obj.begindate,
                dateend: obj.enddate,
            },
        });
    },
    searchByPstID(PstID, requestID) {
        return Service.post(
            menu + "positionByID", {}, {
                params: { pst: PstID, requestID: requestID },
            }
        );
    },
};