<template>
  <div>
    <s-text
      :label="label"
      :disabled="disabled"
      :readonly="readonly"
      ref="textPerson"
      v-model="prs.PrsDocumentNumber"
      :autofocus="autofocus"
      type="tel"
      @keyupEnter="toggleSaveData(prs.PrsDocumentNumber)"
      @input="nofoundPerson()"
      @keypress="validation($event)"
    >
      <template v-if="!noCamera" v-slot:prepend-inner>
        <div style="margin-top:4px;">
          <v-icon style="cursor:pointer" @click="dialogCamera = true">
            mdi-camera
          </v-icon>
        </div>
      </template>
      <template v-slot:append>
        <v-icon style="cursor:pointer;margin-top:4px;" @click="dialogSearchPerson = true">
          mdi-magnify
        </v-icon>
      </template>
      <template v-if="!noEdit" v-slot:append-outer>
        <v-icon style="cursor:pointer;margin-top:4px;" @click="addOrEdit()" color="success darken-1">
          {{ prs.PrsID != null && prs.PrsID != 0 ? "mdi-pencil" : "mdi-plus-circle-outline" }}
        </v-icon>
      </template>
    </s-text>

    <!--MODAL PARA BUSCAR PERSONAS-->
    <v-dialog v-if="dialogSearchPerson" v-model="dialogSearchPerson" persistent max-width="850" transition="dialog-bottom-transition">
      <v-card rounded="">
        <v-toolbar text dense dark color="primary">
          <v-btn icon dark @click="dialogSearchPerson = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Listado de Personas Registradas</v-toolbar-title>
        </v-toolbar>
        <v-col lg="12">
          <s-search-person
            :addBirthDate="addBirthDate"
            :addTypeSex="addTypeSex"
            :typeperson="typeperson"
            :addDriver="addDriver"
            @returnPerson="returnPerson($event)"
          />
        </v-col>
      </v-card>
    </v-dialog>
    <!--MODAL AGREGAR O EDITAR PERSONA-->
    <v-dialog v-model="dialogEditPerson" persistent max-width="550px" transition="dialog-bottom-transition">
      <s-add-person
        ref="textAddPerson"
        @personSaved="personSaved($event)"
        @toclose="toclose(2)"
        :predetermined="predetermined"
        :typeperson="typeperson"
        :propPerson="personEdit"
        :addBirthDate="addBirthDate"
        :addTypeSex="addTypeSex"
        :addDriver="addDriver"
      />
    </v-dialog>
    <!-- MODAL PARA CAMARA -->
    <v-dialog v-if="!noCamera" v-model="dialogCamera" max-width="700">
      <v-card>
        <v-col>
          <qrcode-stream @decode="onDecode" @init="onInit" />
        </v-col>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import Service from "@/services/General/PersonService";
import sAddPerson from "@/components/Utils/Person/sAddPerson";
import sSearchPerson from "@/components/Utils/Person/sSearchPerson";
export default {
  name: "sToolbarPerson",
  components: { sAddPerson, sSearchPerson, QrcodeStream },
  props: {
    addTypeSex: {
      type: Boolean,
      default: false,
    },
    addBirthDate: {
      type: Boolean,
      default: false,
    },
    addDriver: {
      type: Boolean,
      default: false,
    },
    defaultDocument: {
      type: String,
      default: "",
    },
    searchDefault: {
      type: Boolean,
      default: false,
    },
    value: null,
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "Documento",
    },
    typeperson: {
      type: Number,
      default: 1,
    },
    isValidation: {
      type: Number,
      default: 0,
    },
    predetermined: {
      type: Boolean,
      default: false,
    },
    noCamera: {
      type: Boolean,
      default: false,
    },
    noEdit: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    if (this.value != null) {
      this.prs.PrsDocumentNumber = this.value;
      if (this.searchDefault) this.toggleSaveData(this.prs.PrsDocumentNumber);
    }
  },
  watch: {
    value(val) {
      this.prs.PrsID = 0;
      this.prs.PrsDocumentNumber = val;
    },
    "prs.PrsDocumentNumber"() {
      this.$emit("input", this.prs.PrsDocumentNumber);
    },
  },
  methods: {
    onDecode(result) {
      this.prs.PrsDocumentNumber = result;
      this.toggleSaveData(this.prs.PrsDocumentNumber);
      this.dialogCamera = false;
    },

    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permisson";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        }
      }
    },
    clickCamera() {
      this.dialogCamera = true;
    },
    validation(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    personSaved(item) {
      this.prs.PrsDocumentNumber = item.GenPerson.PrsDocumentNumber;
      this.returnPerson(this.prs);
    },
    focus() {
      this.$refs.textPerson.focus();
    },
    addOrEdit() {
      if (this.prs.PrsID == null || this.prs.PrsID == 0) {
        this.$emit("returnPerson", null);
        this.nofoundPerson();
        this.dialogEditPerson = true;
      } else {
        this.personEdit = this.prs;
        this.dialogEditPerson = true;
        this.$nextTick(() => {
          this.$refs.textAddPerson.search();
        });
        // Service.getPerson(this.prs.PrsID, this.typeperson, this.$fun.getUserID()).then((r) => {
        //   console.log("DATAAAAA", r.data);
        //   this.personEdit = r.data;

        //   this.dialogEditPerson = true;
        // });
      }
    },
    returnPerson(item) {
      if (item != null)
        // this.foundvalue = true;

        this.toggleSaveData(item.PrsDocumentNumber);
      //  this.prs=item;
      this.dialogSearchPerson = false;
    },

    toggleSaveData(value) {
      this.person = null;
      if (value == null) return;
      if (value.length > 3) {
        let obj = {
          TypePerson: this.typeperson,
          PrsDocumentNumber: value,
          UsrID: this.$fun.getUserID(),
          IsValidation: this.isValidation,
        };

        Service.personSearch(obj, this.$fun.getUserID()).then((response) => {
          if (response.status == 200) {
            if (response.data != null && response.data.StatusPerson != 1) {
              //este valor response.data puedo obtenerlo aca
              // this.foundvalue = true;

              this.prs = Object.assign(response.data, {});
              // console.log('DDDDDDDDDDDD ', response.data);
              this.$emit("returnPerson", this.prs);
              if (response.data.StatusPerson == 2) {
                this.marker = true;
                this.color = "orange darken-2";
              } else this.marker = false;
            } else {
              this.$emit("returnPerson", null);
              this.nofoundPerson();

              this.dialogEditPerson = true;
            }
          }
        });
      }
    },
    nofoundPerson() {
      this.prs.PrsID = 0;
      this.$emit("returnPerson", null);
      this.personEdit = {
        PrsDocumentNumber: this.prs.PrsDocumentNumber,
        TypePerson: this.typeperson, // ? 12 : 222, // Consideraciones P.Natural {:typeperson="1", :dgr="2"} P.J {:typeperson="2", :dgr="22"}
        PrsID: null,
      };
      this.foundvalue = false;
    },

    toclose(typeperson) {
      this.dialogEditPerson = false;
      this.personEdit = null;
    },
  },
  data: () => ({
    dialogCamera: false,
    dialogSearchPerson: false,
    personEdit: null,
    dialogEditPerson: false,
    dialog3: false,
    foundvalue: false,
    color: "success",
    prs: { PrsDocumentNumber: "" },
    config: {
      model: {
        PrsID: "ID",
        PrsDocumentNumber: "string",
        TypePersonDocument: "int",
      },
    },
  }),
};
</script>
