<template>
  <v-card elevation="0">
    <s-toolbar v-if="visibleTitle" label="Datos Asegurados" />
    <v-card-text>
      <v-row>
        <!-- Contenedor para SCTR Pensión -->
        <v-col class="s-col-form" sm="6" md="6">
          <v-card dense>
            <v-card-title class="text-h7" style="font-weight: bold;">
              <v-row>
                <v-col>
                  <h5>Nuevos Datos del Sistema Pensionario</h5>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col class="s-col-form" cols="6" sm="6" lg="6">
                  <s-select-definition
                    :def="1499"
                    label="Régimen pensionario"
                    v-model="item.TypePensionRegimen"
                    :add="$fun.isAdmin()"
                    ref="TypePensionRegimenRef"
                  />
                </v-col>
                <v-col class="s-col-form" cols="6" sm="6" lg="6">
                  <s-text
                    label="Código de Afiliado (C.U.S.P.P)"
                    v-model="item.CUSPP"
                    :maxlength="12"
                    :showCounter="true"
                    :rules="[
                      //value => !!value || 'aaa',
                      (value) =>
                        (value && value.length >= 12) ||
                        'El Código de Afiliado debe de tener 12 digitos',
                    ]"
                    :uppercase="true"
                    @keydownSpace="clear()"
                  >
                  </s-text>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="s-col-form" cols="6" sm="6" lg="6">
                  <s-date
                    v-model="item.PsnBeginDate"
                    label="Inicio de Aportación"
                  />
                </v-col>
                <v-col class="s-col-form" cols="6" sm="6" lg="6">
                  <s-date v-model="item.PsnEndDate" label="Fin de Aportación" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" lg="12">
                  <v-file-input
                    accept=".pdf"
                    label="Anexo"
                    v-model="Attach"
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- Contenedor para SCTR Salud -->
        <v-col class="s-col-form" sm="6" md="6">
          <v-card dense>
            <v-card-title class="text-h7" style="font-weight: bold;">
              <v-row>
                <v-col>
                  <h5>Nuevos Datos del Sistema Salud</h5>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col
                  sm="12"
                  md="12"
                  :lg="itemHealth.TypeHealthRegimen == 2 ? 6 : 12"
                >
                  <s-select-definition
                    :def="1509"
                    label="Seguro"
                    v-model="itemHealth.TypeHealthRegimen"
                    :add="$fun.isAdmin()"
                    ref="TypeHealthRegimenRef"
                  />
                </v-col>
                <v-col
                  sm="12"
                  md="12"
                  lg="6"
                  v-if="itemHealth.TypeHealthRegimen == 2"
                >
                  <s-select-definition
                    :def="1529"
                    label="Compañía"
                    v-model="itemHealth.TypeCompany"
                    :add="$fun.isAdmin()"
                    ref="TypeCompanyRef"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="12" md="12" lg="6">
                  <s-date
                    label="Fecha de Inicio"
                    v-model="itemHealth.HthStartDate"
                  />
                </v-col>
                <v-col sm="12" md="12" lg="6">
                  <s-date
                    label="Fecha de Fin"
                    v-model="itemHealth.HthEndDate"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import _sGeneral from "@/services/HelperService.js";

export default {
  name: "sDatosAsegurados",
  components: {  },
  props: {
    visibleTitle: {
      type: Boolean,
      default: true,
    },
    full: {
      type: Boolean,
      default: false,
    },
    typeperson: {
      type: Number,
      default: 1,
    },
    addTypeSex: {
      type: Boolean,
      default: false,
    },
    addBirthDate: {
      type: Boolean,
      default: false,
    },
    addDriver: {
      type: Boolean,
      default: false,
    },
    datawork: { type: Object },
  },
  data() {
    return {
      dataWor: {},
      editing: false,
      item: {
        TypePensionRegimen: 6,
        CUSPP: "",
        PsnBeginDate: this.$fun.getDate(""),
        TypeHealthRegimen: 1,
        HthCompany: 1,
        PsnEndDate: null,
        PsnAttach: null,
      },
      Attach: null,
      itemHealth: {
        HthScireID: 0,
        TypeHealthRegimen: 1,
        TypeCompany: 1,
        HthStartDate: null,
        HthEndDate: null,
        SecStatus: 1,
        UsrCreateID: this.$fun.getUserID(),
      },
      selectedCenterCost: 0,
      selectedBusinessLine: null,
      menu3: false,
      config: {},
      dialog: false,
      editedIndex: -1,
      title: -1,
      healthHeaders: [
        { text: "ID", value: "HthID", sortable: false, width: 20 },
        {
          text: "Seguro",
          value: "TypeHealthRegimenName",
          sortable: false,
          width: 50,
        },
        {
          text: "Compañía",
          value: "HthCompanyName",
          sortable: false,
          width: 20,
        },
        {
          text: "Fecha de Inicio",
          value: "HthStartDate",
          sortable: false,
          width: 20,
        },
        {
          text: "Fecha de Fin",
          value: "HthEndDate",
          sortable: false,
          width: 20,
        },
        { text: "Estado", value: "SecStatus", sortable: false, width: 30 },
        { text: "Acciones", value: "actions", sortable: false, width: 30 },
      ],
      healthDetail: [],
    };
  },
  inject: ['contractDates'], // Recibe las fechas
  methods: {
    uploadFile() {
      var formData = new FormData();
      let i = 0;

      formData.append(i.toString(), this.Attach);
      i++;

      _sGeneral.uploadfile("123", this.$fun.getUserID(), formData, 3);
      this.$fun.alert("Guardado Correctamente", "success");
      this.$emit("onClose");
    },

    openFile(url) {
      // console.log("Nombre de url", url);
      _sGeneral.download(url.name, this.$fun.getUserID()).then((r) => {
        // console.log("rtpa",r);
        this.$fun.download(r.data, url.name);
        // if(r.status === 200){

        // 	this.$fun.downloadHttp(r);
        // }
      });
    },

    saveFile() {
      // console.log("Nombre del Archivo: " , this.Attach);
      // console.log("Item:" , this.selected);  
      this.uploadFile();
    },
    initialize() {
      this.item = this.item;
    },
    clear(event) {
      console.log("evento", event);
    },

    close() {
      this.$refs.document.focus();
      this.$emit("toclose", 2);
      this.clear();
    },
    save() {},
    getData() {
      if (this.validateData()) {
        return {
          HmnPension: {
            CUSPP: this.item.CUSPP.toUpperCase(),
            PsnBeginDate: this.item.PsnBeginDate,
            PsnEndDate: this.item.PsnEndDate,
            PsnAttach: this.Attach?.name || null,
            TypePensionRegimen: this.item.TypePensionRegimen,
            UsrCreateID: this.$fun.getUserID(),
            //UsrUpdateID: this.$fun.getUserID(),
          },
          HmnHealth: {
            //HthID             : this.itemHealth.HthID || 0,
            HthScireID: 0,
            TypeHealthRegimen: this.itemHealth.TypeHealthRegimen,
            TypeCompany:
              this.itemHealth.TypeHealthRegimen == 2
                ? this.itemHealth.TypeCompany
                : null,
            HthStartDate: this.itemHealth.HthStartDate,
            HthEndDate: this.itemHealth.HthEndDate,
            SecStatus: 1,
            UsrCreateID: this.$fun.getUserID(),
          },
        };
      } else {
        return false;
      }
    },

    validateData() {
      let valid = true;
      //console.log("Data Asegurados",this.item);

      if (this.item.CUSPP == null || this.item.CUSPP == "") {
        this.$fun.alert(
          "El Código de Afiliado C.U.S.P.P es obligatorio",
          "warning"
        );
        valid = false;
      }
      if (!this.$fun.isValidDate(this.item.PsnBeginDate)) {
        this.$fun.alert(
          "Fecha no Valida del Campo Inicio de Aportación ",
          "warning"
        );
        valid = false;
      }

      if (this.item.CUSPP) {
        this.item.CUSPP = this.item.CUSPP.replace(/\s+/g, "");
        if (this.item.CUSPP.length < 12) {
          this.$fun.alert(
            "El Código de Afiliado C.U.S.P.P es de 12 dígitos ",
            "warning"
          );
          valid = false;
        }
      }

      if (!this.$fun.isValidDate(this.itemHealth.HthStartDate)) {
        this.$fun.alert(
          "Fecha no Valida del Campo Fecha de Inicio ",
          "warning"
        );
        valid = false;
      }

      if (!this.$fun.isValidDate(this.itemHealth.HthEndDate)) {
        this.$fun.alert("Fecha no Valida del Campo Fecha de Fin ", "warning");
        valid = false;
      }

      return valid;
    },
    updateDate(){
      this.item.PsnEndDate = this.contractDates.AgtEndDate || null; // Actualiza automáticamente
      this.itemHealth.HthEndDate  = this.contractDates.AgtEndDate || null;

      this.item.PsnBeginDate = this.contractDates.AgtAdmissionDate || null;
      this.itemHealth.HthStartDate  = this.contractDates.AgtAdmissionDate || null;
    }
  },
  watch: {
    'contractDates.AgtAdmissionDate'() {
      this.updateDate();
    },
    'contractDates.AgtEndDate'() {
      this.updateDate();
    },
  },
  created() {
    this.initialize();
    this.updateDate();
  },
};
</script>
