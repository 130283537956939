import Service from "../Service";

const resource = "hmnAgreement/";

export default {
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: {
                requestID: requestID,
            },
        });
    },
    workerPerson(WkrID, AgtID,requestID) {
        return Service.post(
            resource + "workerPerson", {}, {
                params: { WkrID: WkrID,AgtID: AgtID, requestID: requestID },
            }
        );
    },
    workerByNtpID(NtpID, requestID) {
        return Service.post(
            resource + "workerByNtpID", {}, {
                params: { NtpID: NtpID, requestID: requestID },
            }
        );
    },
    searchContractByWkrID(WkrID,requestID){
        return Service.post(resource + "contractByWkrID", {}, {
            params: { WkrID: WkrID, requestID: requestID },
            }
        );
    },

    create(ntp, requestID) {
        return Service.post(resource + "create", ntp, {
            params: { usrID: requestID },
        });
    },
    Positionlist(parameters,requestID) {
        return Service.post(resource + "Positionlist", parameters, {
            params: { requestID: requestID },
        });
    },
    captureFingerprint() {
        return Service.post(resource + "startfingerprintcapture")
            .then(response => {

                return response.data; 
            })
            .catch(error => {
                console.error("Error capturing fingerprint:", error);
                throw error;
            });
    },
    InitialFingerprint(parameters,requestID) {
        return Service.post(resource + "initializefingerprint", parameters, {
            params: { requestID: requestID },
        });
    },
    closeDevice() {
        return Service.post(resource + "close")
            .then(response => {
                return response.data; 
            })
            .catch(error => {
                console.error("Error closing the device:", error);
                throw error;
            });
    },
    FingerPrintListBy(document, requestID) {
        return Service.post(
            resource + "FingerprintTempByDni", {}, {
                params: { document: document, requestID: requestID },
            }
        );
    },
    FingerprintByNtp(NtpID, requestID) {
        return Service.post(
            resource + "FingerprintByNtpID", {}, {
                params: { NtpID: NtpID, requestID: requestID },
            }
        );
    },
    IPListByUsrID(UsrID, requestID) {
        return Service.post(
            resource + "IPListByUsrID", {}, {
                params: { UsrID: UsrID, requestID: requestID },
            }
        );
    },
    saveUserIp(obj, requestID) {
        return Service.post(resource + "saveUserIp", obj, {
            params: { requestID: requestID },
        });
    },
    saveFingerTemp(obj, requestID) {
        return Service.post(resource + "saveFingerTemp", obj, {
            params: { requestID: requestID },
        });
    },

    searchContract(AgtID, requestID) {
        return Service.post(
            resource + "searchContract", {}, {
                params: { AgtID: AgtID, requestID: requestID },
            }
        );
    },

    savePrint(items, requestID) {
        return Service.post(
            resource + "savePrint", items, {
                params: { requestID: requestID },
            }
        );
    },

    SaveFingerprints(obj, requestID) {
        return Service.post(resource + "SaveFingerprints", obj, {
            params: { requestID: requestID },
        });
    },

    closefingerprint(requestID) {
        return Service.post(resource + "closefingerprint", {}, {
            params: { requestID: requestID },
        });
    },
};