<template>
	<div>
		<!-- <v-row justify="center"> -->
			<!-- <v-col align="center" cols="12" lg="8" md="12"> -->
				<v-btn v-if="!notBtn" v-bind="btnProps" :color="color" @click="captureFingerprint">
					<v-icon left>mdi-fingerprint</v-icon>
					{{ text }}
				</v-btn>
				<div v-else style="display: flex; justify-content: center; align-items: center;" :style="stylesDiv" @click="captureFingerprint">
					<img :src="srcImg" v-if="contentIMG && srcImg"
						alt="Huella Dactilar" 
						style="max-width: calc(100% - 2px); max-height: calc(100% - 2px); object-fit: contain;" />
				</div>
			<!-- </v-col> -->
		<!-- </v-row> -->

		<!-- Modal de captura de huella -->
		<v-dialog v-model="showModal" max-width="300px" persistent>
            <v-card>
                <v-card-title class="d-flex justify-center" style="background-color: #fadf9b">
                    <span class="headline">{{ statusOpen ? "Captura Huella" : "Huella Capturada" }}</span>
                </v-card-title>
                <v-card-text style="margin-top: 5px">
                    <div class="d-flex justify-center">
                        <div class="fingerprint-icon-container">
                            <v-img
                                src="@/assets/FingrPrint.png"
                                alt="Fingerprint Icon"
                                class="fingerprint-icon"
                                max-height="150"
                                max-width="150"
                                v-if="scanning"
                                :style="{
                                    backgroundColor: statusOpen ? '#ffcec7' : captured ? '#d5fb9f' : 'transparent',
                                }"
                            >
                                <v-progress-linear v-if="scanning" indeterminate color="red" class="scanning-line" :style="{ height: '10px' }" />
                            </v-img>
                            <v-progress-linear v-if="scanning" indeterminate color="red" class="scanning-line" :style="{ height: '10px' }" />
                            <img :src="capturedFingerprint" v-if="captured" alt="Huella Dactilar" />
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions class="d-flex justify-center">
                    <v-btn color="" @click="CloseFootprint" v-if="!captured">Cerrar</v-btn>
                    <v-btn v-if="captured" small color="" class="mr-2" @click="SaveFingerprint">
                        <v-icon small>mdi-content-save</v-icon>
                        <span class="ml-1">Guardar</span>
                    </v-btn>
                    <v-btn v-if="captured" small color="" @click="RetakeFingerprint">
                        <v-icon small>mdi-refresh</v-icon>
                        <span class="ml-1">Reintentar</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
	</div>
</template>

<script>
	import _sContracthmRs from "@/services/HumanResource/HmnContractService";

	export default {
		props: {
			text: {
			type: String,
			default: "Capturar Huella"
		},
		color: {
			type: String,
			default: "default"
		},
		btnProps: {
			type: Object,
			default: () => ({})
		},
		notBtn:{
			type: Boolean,
			default: false
		},
		stylesDiv:{
			type: Object,
			default: () => ({})
		},
		contentIMG:{
			type: Boolean,
			default: false
		},
		srcImg:{
			type: String,
			default: ""
		}
	},
		data() {
			return {
				showModal: false,  // El modal se maneja solo dentro del componente
				captured: false,
				scanning: false,
				capturedFingerprint: "",
				statusOpen: false,
				capturedFingerTemplate: "",
			};
		},
		methods: {
			// Al hacer clic en el botón, se abre el modal
			captureFingerprint() {
				this.showModal = true;
				this.captured = false;
				this.scanning = true;
				this.capturedFingerprint = "";
				this.initializeDevice();
			},
			initializeDevice() {
				let obj = {
					WftID: 0,
					DNI: 0,
					WftStatus: 1,
					WfpTypeFinger: 0,
					UsrCreateID: this.$fun.getUserID(),
					NtpValue: "",
				};

				setTimeout(() => {
					this.statusOpen = true;
				}, 500);

				_sContracthmRs
					.InitialFingerprint(obj, this.$fun.getUserID())
					.then((response) => {
						this.captured = true;
						this.scanning = false;
						this.statusOpen = false;
						this.capturedFingerprint = "data:image/png;base64," + response.data.data.image;
					})
					.catch((error) => {
						console.error("Error initializing device:", error);
					});
			},
			RetakeFingerprint() {
				this.captureFingerprint();
			},
			SaveFingerprint() {
                this.$emit("fingerprintSaved", this.capturedFingerprint);
				this.showModal = false;
			},
			CloseFootprint() {
				this.showModal = false;
				this.captured = false;
				this.scanning = false;
				this.statusOpen = false;
			},
		},
	};
</script>
