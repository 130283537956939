import Service from "../Service";
import Vue from "vue";
const resource = "/worker/";

export default {
  searchworker() {
    return Vue.prototype.$http.defaults.baseURL + "/worker/workerce";
  },
  searchworkertByNtpID(NtpID, requestID) {
    return Service.post(resource + "workerByNtpID", {}, {
            params: { NtpID: NtpID, requestID: requestID },
        }
    );
  },

  BankAccountCorrelative(requestID) {
    return Service.post(resource + "BankAccountCorrelative", {}, {
            params: { requestID: requestID },
        }
    );
  },
  pagination(dtr, requestID) {
    return Service.post(resource + "pagination", dtr, {
      params: { requestID: requestID },
    });
  },
  connectFingerPrint(dataIP, dataPort, dataType, requestID) {
    return Service.post(
      resource + "connectFingerPrint",
      {},
      {
        params: {
          dataIP: dataIP,
          dataPort: dataPort,
          dataType: dataType,
          requestID: requestID,
        },
      }
    );
  },

  addFingerPrint(data, requestID) {
    return Service.post(resource + "addFingerPrint", data, {
      params: { requestID: requestID },
    });
  },
  disconnectFingerPrint(dataIP, dataPort, dataType, requestID) {
    return Service.post(
      resource + "disconnectFingerPrint",
      {},
      {
        params: {
          dataIP: dataIP,
          dataPort: dataPort,
          dataType: dataType,
          requestID: requestID,
        },
      }
    );
  },
  deleteUserFingerPrint(dataType, MachineNumber, EnrollNumber, requestID) {
    return Service.post(
      resource + "deleteUserFingerPrint",
      {},
      {
        params: {
          type: dataType,
          MachineNumber: MachineNumber,
          EnrollNumber: EnrollNumber,
          requestID: requestID,
        },
      }
    );
  },
  addUserFingerPrint(
    dataType,
    MachineNumber,
    EnrollNumber,
    Name,
    Password,
    Privilege,
    Enabled,
    requestID
  ) {
    return Service.post(
      resource + "addUserFingerPrint",
      {},
      {
        params: {
          dataType: dataType,
          MachineNumber: MachineNumber,
          EnrollNumber: EnrollNumber,
          Name: Name,
          Password: Password,
          Privilege: Privilege,
          Enabled: Enabled,
          requestID: requestID,
        },
      }
    );
  },

  save(obj, requestID) {
    //console.log('saveeee', obj);
    return Service.post(resource + "save", obj, {
      params: { requestID: requestID },
    });
  },

  reconectFingerPrint(dataIP, dataPort, dataType, requestID) {
    return Service.post(
      resource + "reconectFingerPrint",
      {},
      {
        params: {
          dataIP: dataIP,
          dataPort: dataPort,
          dataType: dataType,
          requestID: requestID,
        },
      }
    );
  },

  listFingerPrintUserInBiometric(WrkID, type, requestID) {
    return Service.post(
      resource + "listFingerPrintUserInBiometric",
      {},
      {
        params: { type: type, WrkID: WrkID, requestID: requestID },
      }
    );
  },
  async readFingerPrint(type, machineNumber, wrkID, finger, requestID) {
    return Service.post(
      resource + "readFingerPrint",
      {},
      {
        params: {
          type: type,
          machineNumber: machineNumber,
          wrkID: wrkID,
          finger: finger,
          requestID: requestID,
        },
      }
    );
  },
  async deleteFingerPrint(type, machineNumber, wrkID, finger, requestID) {
    return Service.post(
      resource + "deleteFingerPrint",
      {},
      {
        params: {
          type: type,
          machineNumber: machineNumber,
          wrkID: wrkID,
          finger: finger,
          requestID: requestID,
        },
      }
    );
  },
  async getFingerPrint(type, machineNumber, wrkID, finger, requestID) {
    return Service.post(
      resource + "getFingerPrint",
      {},
      {
        params: {
          type: type,
          machineNumber: machineNumber,
          wrkID: wrkID,
          finger: finger,
          requestID: requestID,
        },
      }
    );
  },
  async saveFingerPrint(data,datatmp, requestID) {
    return Service.post(
      resource + "saveFingerPrint",
      data,
      {
        params: {
          workerFingerprint: data,
          datatemp: datatmp,//hacer el endpoint de guardado
          requestID: requestID,
        },
      }
    );
  },
  DetailByWkrID(WkrID, requestID) {
    return Service.post(
      resource + "detailByWkrID",
      {},
      {
        params: {
          WkrID: WkrID,
          requestID: requestID,
        },
      }
    );
  },
  DetailByNtpID(NtpID, requestID) {
    return Service.post(
      resource + "DetailByNtpID",
      {},
      {
        params: {
          NtpID: NtpID, 
          requestID: requestID,
        },
      }
    );
  }
};
