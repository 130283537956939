<template>
  <v-card elevation="0">
    <s-toolbar v-if="visibleTitle" label="Datos Asegurados" />
    <v-card-text>
      <v-row>
        <!-- Contenedor para SCTR Pensión -->
        <v-col class="s-col-form" sm="6" md="6" lg="6">         
          <v-card dense>
            <v-card-title class="text-h7" style="font-weight: bold;">
              <v-row>
                <v-col>
                  <h5>
                    {{ editedPensionIndex != -1 ? "EDITAR" : "NUEVOS" }} DATOS
                    DEL SISTEMA PENSIONARIO
                    <!-- Datos del Sistema Pensionario -->
                  </h5>
                </v-col>
                <div style="text-align: end;" v-if="editedIndex != -1">
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    @click="cancelItemDetail(0)"
                    color="error"
                  >
                    <v-icon dark>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </div>
                <div style="text-align: end;">
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    @click="addItemDetail(0)"
                    color="success"
                  >
                    <v-icon dark>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </div>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col class="s-col-form" cols="6" sm="6" lg="6">
                  <s-select-definition
                    :def="1499"
                    label="Régimen pensionario"
                    v-model="itemPension.TypePensionRegimen"
                    :add="$fun.isAdmin()"
                    ref="TypePensionRegimenRef"
                  />
                </v-col>
                <v-col class="s-col-form" cols="6" sm="6" lg="6">
                  <s-text
                    label="Código de Afiliado (C.U.S.P.P)"
                    v-model="itemPension.CUSPP"
                    :maxlength="12"
                    :showCounter="true"
                    :rules="[
                      //value => !!value || 'aaa',
                      (value) =>
                        (value && value.length >= 12) ||
                        'El C.U.S.P.P debe de tener 12 digitos',
                    ]"
                    :uppercase="true"
                    @keydownSpace="clear()"
                  >
                  </s-text>                  
                </v-col>
              </v-row>
              <v-row>
                <v-col class="s-col-form" cols="6" sm="6" lg="6">
                  <s-date
                    v-model="itemPension.PsnBeginDate"
                    label="Inicio de Aportación"
                  />
                </v-col>
                <v-col class="s-col-form" cols="6" sm="6" lg="6">
                  <s-date
                    v-model="itemPension.PsnEndDate"
                    label="Fin de Aportación"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="10" sm="10" lg="10">
                  <v-file-input
                    accept=".pdf"
                    label="Anexo"
                    v-model="Attach"
                    hide-details
                  />
                </v-col>
                <v-col cols="2" sm="2" lg="2" >
                <v-btn
                  color="success"
                  class="mt-5"
                  :disabled="!Attach"
                  icon
                  x-small
                  @click="openFile(Attach)"
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </v-col>
              </v-row>
              <v-row>
                <v-col sm="12" md="12" lg="12">
                  <template>
                    <v-data-table
                      :headers="PensionHeaders"
                      class="elevation-1"
                      :items="pensionDetail"
                      :sort-by="['SecStatus', 'PsnID','index']"
                      :sort-desc="[true]"
                    >
                      <template v-slot:[`item.PsnBeginDate`]="{ item }">
                        {{
                          item.PsnBeginDate
                            ? $moment(item.PsnBeginDate).format(
                                $const.FormatDateView
                              )
                            : ""
                        }}
                      </template>
                      <template v-slot:[`item.PsnEndDate`]="{ item }">
                        {{
                          item.PsnEndDate
                            ? $moment(item.PsnEndDate).format(
                                $const.FormatDateView
                              )
                            : ""
                        }}
                      </template>
                      <template v-slot:[`item.SecStatus`]="{ item }">
                        <v-chip
                          small
                          :color="item.SecStatus ? 'success' : ''"
                          :text-color="item.SecStatus ? 'white' : ''"
                        >
                          {{ item.SecStatus ? "Activo" : "Inactivo" }}
                        </v-chip>
                      </template>
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-icon small class="mr-2" @click="editItem(item, 0)">
                          mdi-pencil
                        </v-icon>
                        <v-icon small @click="deleteItem(item, 0)">
                          mdi-delete
                        </v-icon>
                      </template>
                    </v-data-table>
                  </template>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Contenedor para SCTR Salud -->
        <v-col class="s-col-form" sm="6" md="6" lg="6">
          <v-card dense>
            <v-card-title class="text-h7" style="font-weight: bold;">
              <v-row>
                <v-col>
                  <h5>
                    {{ editedHealthIndex != -1 ? "EDITAR" : "NUEVOS" }} DATOS
                    DEL SISTEMA SALUD
                    <!-- Datos del Sistema de Salud -->
                  </h5>
                </v-col>
                <div style="text-align: end;" v-if="editedHealthIndex != -1">
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    @click="cancelItemDetail(1)"
                    color="error"
                  >
                    <v-icon dark>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </div>
                <div style="text-align: end;">
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    @click="addItemDetail(1)"
                    color="success"
                  >
                    <v-icon dark>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </div>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col
                  sm="12"
                  md="12"
                  :lg="itemHealth.TypeHealthRegimen == 2 ? 6 : 12"
                >
                  <s-select-definition
                    :def="1509"
                    label="Seguro"
                    v-model="itemHealth.TypeHealthRegimen"
                    :add="$fun.isAdmin()"
                    ref="TypeHealthRegimenRef"
                  />
                </v-col>
                <v-col
                  sm="12"
                  md="12"
                  lg="6"
                  v-if="itemHealth.TypeHealthRegimen == 2"
                >
                  <s-select-definition
                    :def="1529"
                    label="Compañía"
                    v-model="itemHealth.TypeCompany"
                    :add="$fun.isAdmin()"
                    ref="TypeCompanyRef"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="12" md="12" lg="6">
                  <s-date
                    label="Fecha de Inicio"
                    v-model="itemHealth.HthStartDate"
                  />
                </v-col>
                <v-col sm="12" md="12" lg="6">
                  <s-date
                    label="Fecha de Fin"
                    v-model="itemHealth.HthEndDate"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="12" md="12" lg="12">
                  <template>
                    <v-data-table
                      :headers="healthHeaders"
                      class="elevation-1"
                      :items="healthDetail"
                      :sort-by="['SecStatus', 'index']"
                      :sort-desc="[true]"
                    >
                      <template v-slot:[`item.HthStartDate`]="{ item }">
                        {{
                          item.HthStartDate
                            ? $moment(item.HthStartDate).format(
                                $const.FormatDateView
                              )
                            : ""
                        }}
                      </template>
                      <template v-slot:[`item.HthEndDate`]="{ item }">
                        {{
                          item.HthEndDate
                            ? $moment(item.HthEndDate).format(
                                $const.FormatDateView
                              )
                            : ""
                        }}
                      </template>
                      <template v-slot:[`item.SecStatus`]="{ item }">
                        <v-chip
                          small
                          :color="item.SecStatus ? 'success' : ''"
                          :text-color="item.SecStatus ? 'white' : ''"
                        >
                          {{ item.SecStatus ? "Activo" : "Inactivo" }}
                        </v-chip>
                      </template>
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-icon small class="mr-2" @click="editItem(item, 1)">
                          mdi-pencil
                        </v-icon>
                        <v-icon small @click="deleteItem(item, 1)">
                          mdi-delete
                        </v-icon>
                      </template>
                    </v-data-table>
                  </template>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import _sGeneral from "@/services/HelperService.js";

export default {
  name: "sDatosAsegurados",
  components: {  },
  props: {
    visibleTitle: {
      type: Boolean,
      default: true,
    },
    full: {
      type: Boolean,
      default: false,
    },
    typeperson: {
      type: Number,
      default: 1,
    },
    addTypeSex: {
      type: Boolean,
      default: false,
    },
    addBirthDate: {
      type: Boolean,
      default: false,
    },
    addDriver: {
      type: Boolean,
      default: false,
    },
    datawork: { type: Object },
  },
  data() {
    return {
      //dataWor: {},
      editing: false,
      itemPension: {
        PsnID: 0,
        TypePensionRegimen: 6,
        TypePensionRegimenName: "",
        CUSPP: "",
        PsnBeginDate: this.$fun.getDate(""),
        PsnEndDate: null,
        PsnAttach: null,
        UsrCreateID: this.$fun.getUserID(),
      },
      itemHealth: {
        HthID: 0,
        HthScireID: 0,
        WkrID: 0,
        TypeHealthRegimen: 1,
        TypeHealthRegimenName: "",
        TypeCompany: 1,
        TypeCompanyName: "",
        HthStartDate: null,
        HthEndDate: null,
        SecStatus: 1,
        UsrCreateID: this.$fun.getUserID(),
      },
      itemPensionDefault: {
        PsnID: 0,
        TypePensionRegimen: 6,
        TypePensionRegimenName: "",
        CUSPP: "",
        PsnAttach: null,
        PsnBeginDate: this.$fun.getDate(""),
        PsnEndDate: null,
      },
      itemHealthDefault: {
        HthID: 0,
        HthScireID: 0,
        WkrID: 0,
        TypeHealthRegimen: 1,
        TypeHealthRegimenName: "",
        TypeCompany: 1,
        TypeCompanyName: "",
        HthStartDate: null,
        HthEndDate: null,
        SecStatus: 1,
        UsrCreateID: this.$fun.getUserID(),
      },
      editedIndex: -1,
      editedHealthIndex: -1,
      editedPensionIndex: -1,
      PensionHeaders: [
        { text: "ID", value: "PsnID", sortable: false, width: 10 },
        {
          text: "C.U.S.P.P.",
          value: "CUSPP",
          sortable: false,
          width: 50,
        },
        {
          text: "Inicio Aportación",
          value: "PsnBeginDate",
          sortable: false,
          width: 50,
        },
        {
          text: "Fin Aportación",
          value: "PsnEndDate",
          sortable: false,
          width: 50,
        },
        {
          text: "Régimen Pensionario",
          value: "TypePensionRegimenName",
          sortable: false,
          width: 50,
        },
        {
          text: "Anexo",
          value: "PsnAttach",
          sortable: false,
          width: 50,
        },
        { text: "Estado", value: "SecStatus", sortable: false, width: 20 },
        { text: "Acciones", value: "actions", sortable: false, width: 20 },
      ],
      pensionDetail: [],
      pensionDetailDelete: [],
      healthHeaders: [
        { text: "ID", value: "HthID", sortable: false, width: 10 },
        {
          text: "Seguro",
          value: "TypeHealthRegimenName",
          sortable: false,
          width: 50,
        },
        {
          text: "Compañia",
          value: "TypeCompanyName",
          sortable: false,
          width: 50,
        },
        {
          text: "Fecha de Inicio",
          value: "HthStartDate",
          sortable: false,
          width: 50,
        },
        {
          text: "Fecha de Fin",
          value: "HthEndDate",
          sortable: false,
          width: 50,
        },
        { text: "Estado", value: "SecStatus", sortable: false, width: 20 },
        { text: "Acciones", value: "actions", sortable: false, width: 20 },
      ],
      healthDetail: [],
      healthDetailDelete: [],
      title: -1,
      fileTypes: ["application/pdf"],
      Attach: null,
    };
  },
  inject: ['contractDates'], // Recibe las fechas
  methods: {
    uploadFile() {
      // var formData = new FormData();
      // let i = 0;

      // formData.append(i.toString(), this.Attach);
      // i++;
      let files = [];

      let Attachs = this.pensionDetail.filter((x)=> x.Attach?.size > 0 );

      //console.log("Anexos",Attachs);

      if(Attachs.length == 0) return;

      //console.log("Anexos2222",Attachs);

      Attachs.forEach( x=> {
        files.push(x.Attach)
      })
      //files.push(this.Attach);
      // if (this.Attach1) files.push(this.Attach1);
      // if (this.Attach2) files.push(this.Attach2);
      // if (this.Attach3) files.push(this.Attach3);
      // if (this.Attach4) files.push(this.Attach4);
      var formData = new FormData();
      let i = 0;
      files.forEach((element) => {
        formData.append(i.toString(), element);
        i++;
      });
      console.log("Anexos",formData);

      _sGeneral.uploadfile("123", this.$fun.getUserID(), formData, 3);
      //this.$fun.alert("Guardado Correctamente", "success");
      //this.$emit("onClose");
    },

    openFile(url) {
      console.log("Nombre de url", url);
      _sGeneral.download(url.name, this.$fun.getUserID()).then((r) => {
        console.log("rtpa",r);        
        if(r.status === 200){
          this.$fun.download(r.data, url.name);
        // 	this.$fun.downloadHttp(r);
        }
        
      });
    },

    saveFile() {
      this.uploadFile();
    },
    validFileType(file) {
      return this.fileTypes.includes(file.type) || file.name != null;
    },
    deleteItem(item, detail) {
      switch (detail) {
        case 0:
          if (item.PsnID == 0) {
            this.editedPensionIndex = this.pensionDetail.indexOf(item);
            this.pensionDetail.splice(this.editedPensionIndex, 1);
          } else {
            item.SecStatus = 0;
            item.UsrUpdateID = this.$fun.getUserID();
          }
          break;
        case 1:
          if (item.HthID == 0) {
            this.editedHealthIndex = this.healthDetail.indexOf(item);
            this.healthDetail.splice(this.editedHealthIndex, 1);
          } else {
            item.SecStatus = 0;
            item.UsrUpdateID = this.$fun.getUserID();
          }
          break;
        default:
          break;
      }

      this.clearDetail(detail);
      //this.editedIndex = -1;
    },

    editItem(item, detail) {
      switch (detail) {
        case 0:
          this.isInitializing = true;
          this.editedPensionIndex = this.pensionDetail.indexOf(item);
          this.itemPension = {
            ...item,
          };
          this.Attach = {name : item.PsnAttach}
          break;
        case 1:
          this.isInitializing = true;
          this.editedHealthIndex = this.healthDetail.indexOf(item);
          this.itemHealth = {
            ...item,
          };
          break;
        default:
          break;
      }
    },
    cancelItemDetail(detail) {
      switch (detail) {
        case 0:
          this.editedPensionIndex = -1;
          this.itemPension = {
            ...this.itemPensionDefault,
          };
          break;
        case 1:
          this.editedHealthIndex = -1;
          this.itemHealth = {
            ...this.itemHealthDefault,
          };
          break;
        default:
          break;
      }
    },

    addItemDetail(detail) {
      if (!this.validateItemDetail(detail)) return;

      switch (detail) {
        case 0:
          let pension = {
            PsnID: this.itemPension.PsnID || 0,
            WkrID: this.datawork.WkrID || 0,
            CUSPP: this.itemPension.CUSPP,
            PsnBeginDate: this.itemPension.PsnBeginDate,
            PsnEndDate: this.itemPension.PsnEndDate,
            TypePensionRegimen: this.itemPension.TypePensionRegimen,
            TypePensionRegimenName: this.itemPension.TypePensionRegimen
              ? this.$refs.TypePensionRegimenRef.items.find(
                  (x) => x.DedValue === this.itemPension.TypePensionRegimen
                ).DedDescription
              : "",
            SecStatus: 1,
            PsnAttach: this.Attach?.name || "",
            Attach: this.Attach,
            UsrCreateID: this.itemPension.PsnID
              ? this.itemPension.UsrCreateID
              : this.$fun.getUserID(),
            UsrUpdateID: this.itemPension.PsnID ? this.$fun.getUserID() : 0,
          };
          const itemPensionDetail = this.pensionDetail.find(
            (element) =>
              element.SecStatus == 1 &&
              this.pensionDetail.indexOf(element) != this.editedPensionIndex
          );
          if (itemPensionDetail) {
            let indexItemPension = this.pensionDetail.indexOf(
              itemPensionDetail
            );
            // this.pensionDetail.find(element => element.SecStatus ==1).SecStatus = 0;
            if (itemPensionDetail.PsnID > 0) {
              itemPensionDetail.SecStatus = 0;
              Object.assign(
                this.pensionDetail[indexItemPension],
                itemPensionDetail
              );
            } else {
              this.pensionDetail.splice(indexItemPension, 1);
            }
          }
          if (this.editedPensionIndex > -1) {
            Object.assign(this.pensionDetail[this.editedPensionIndex], pension);
          } else {
            this.pensionDetail.push(pension);
          }
          this.pensionDetail = this.pensionDetail.map((item, index) => ({
            ...item,
            index,
          }));
          break;
        case 1:
          //console.log("Itemsss", this.$refs.TypeHealthRegimenRef.items);

          let health = {
            HthID: this.itemHealth.HthID || 0,
            WkrID: this.datawork.WkrID || 0,
            HthScireID: this.itemHealth.HthScireID || 0,
            TypeHealthRegimen: this.itemHealth.TypeHealthRegimen,
            TypeHealthRegimenName: this.itemHealth.TypeHealthRegimen
              ? this.$refs.TypeHealthRegimenRef.items.find(
                  (x) => x.DedValue === this.itemHealth.TypeHealthRegimen
                ).DedDescription
              : "",
            TypeCompany:
              this.itemHealth.TypeHealthRegimen == 1
                ? null
                : this.itemHealth.TypeCompany,
            TypeCompanyName:
              this.itemHealth.TypeHealthRegimen == 1
                ? null
                : this.itemHealth.TypeCompany
                ? this.$refs.TypeCompanyRef.items.find(
                    (x) => x.DedValue === this.itemHealth.TypeCompany
                  ).DedDescription
                : "",
            HthStartDate: this.itemHealth.HthStartDate,
            HthEndDate: this.itemHealth.HthEndDate,
            SecStatus: 1,
            UsrCreateID: this.itemHealth.HthID
              ? this.itemPension.UsrCreateID
              : this.$fun.getUserID(),
            UsrUpdateID: this.itemHealth.HthID ? this.$fun.getUserID() : 0,
          };

          const itemHealthDetail = this.healthDetail.find(
            (element) =>
              element.SecStatus == 1 &&
              this.healthDetail.indexOf(element) != this.editedHealthIndex
          );
          if (itemHealthDetail) {
            let indexItemHealthDetail = this.healthDetail.indexOf(
              itemHealthDetail
            );
            //this.healthDetail.find(element => element.SecStatus ==1).SecStatus = 0;
            if (itemHealthDetail.HthID > 0) {
              itemHealthDetail.SecStatus = 0;
              Object.assign(
                this.healthDetail[indexItemHealthDetail],
                itemHealthDetail
              );
            } else {
              this.healthDetail.splice(indexItemHealthDetail, 1);
            }
          }
          if (this.editedHealthIndex > -1) {
            Object.assign(this.healthDetail[this.editedHealthIndex], health);
          } else {
            this.healthDetail.push(health);
          }
          this.healthDetail = this.healthDetail.map((item, index) => ({
            ...item,
            index,
          }));
          break;
        default:
          break;
      }

      this.clearDetail(detail);
    },

    clearDetail(detail) {
      //console.log("Item default111", this.itemDefault);

      switch (detail) {
        case 0:
          this.editedPensionIndex = -1;
          this.itemPension = {
            ...this.itemPensionDefault,
          };
          this.Attach = null;
          break;
        case 1:
          this.editedHealthIndex = -1;
          this.itemHealth = {
            ...this.itemHealthDefault,
          };
          break;
        default:
          break;
      }
    },
    initialize() {
      console.log("DataTrabajador", this.datawork);
      // this.itemHealth = {
      //   ...this.datawork.HealthCE[0],
      //   //...this.datawork.HealthCE[0],
      // };

      this.datawork.HealthCE.forEach((element) => {
        element.UsrUpdateID = this.$fun.getUserID();
        this.healthDetail.push(element);
      });

      this.datawork.PensionCE.forEach((element) => {
        element.UsrUpdateID = this.$fun.getUserID();
        this.pensionDetail.push(element);
      });

      //console.log("Asegurados", this.itemHealth, this.itemPension);
    },
    clear() {},

    close() {
      this.$refs.document.focus();
      this.$emit("toclose", 2);
      this.clear();
    },
    save() {},
    getData() {
      if (this.validateData()) {
        return {
          HmnPension: this.pensionDetail,
          // HmnPension: {
          //   PsnID: this.itemPension.PsnID || 0,
          //   CUSPP: this.itemPension.CUSPP.toUpperCase(),
          //   PsnBeginDate: this.itemPension.PsnBeginDate,
          //   TypePensionRegimen: this.itemPension.TypePensionRegimen,
          //   WkrID: this.itemPension.WkrID,
          //   SecStatus: this.itemPension.SecStatus,
          //   UsrCreateID: this.itemPension.PsnID ? this.itemPension.UsrCreateID : this.$fun.getUserID(),
          //   UsrUpdateID: this.itemPension.PsnID ? this.$fun.getUserID() : 0,
          // },
          HmnHealth: this.healthDetail,
          // HmnHealth: {
          //   HthID             : this.itemHealth.HthID || 0,
          //   HthScireID        : this.itemHealth.HthScireID,
          //   TypeHealthRegimen : this.itemHealth.TypeHealthRegimen,
          //   WkrID             : this.itemHealth.WkrID,
          //   TypeCompany       : this.itemHealth.TypeHealthRegimen == 2 ? this.itemHealth.TypeCompany  : null,
          //   HthStartDate      : this.itemHealth.HthStartDate ,
          //   HthEndDate        : this.itemHealth.HthEndDate   ,
          //   SecStatus         : this.itemHealth.SecStatus,
          //   UsrCreateID       : this.itemHealth.HthID ? this.itemHealth.UsrCreateID : this.$fun.getUserID(),
          //   UsrUpdateID       : this.itemHealth.HthID ? this.$fun.getUserID() : 0,
          // },
        };
      } else {
        return false;
      }
    },

    validateItemDetail(detail) {
      let valid = true;
      let message = "";

      switch (detail) {
        case 0:
          if (this.itemPension.CUSPP == null || this.itemPension.CUSPP == "") {
            message = "El campo del Afiliado C.U.S.P.P es obligatorio";
            break;
          } else {
            this.itemPension.CUSPP = this.itemPension.CUSPP.replace(/\s+/g, "");
            if (this.itemPension.CUSPP.length < 12) {
              message = "El codigo del Afiliado C.U.S.P.P es de 12 dígitos";
              break;
            }
          }

          if (!this.$fun.isValidDate(this.itemPension.PsnBeginDate)) {
            message = "Fecha no Valida del Campo Inicio de Aportación ";
            break;
          }

          if (!this.$fun.isValidDate(this.itemPension.PsnEndDate)) {
            message = "Fecha no Valida del Campo Fin de Aportación ";
            break;
          }

          break;
        case 1:
          if (!this.$fun.isValidDate(this.itemHealth.HthStartDate)) {
            message =
              "Fecha no Valida del Campo Fecha de Inico de los Datos del Sistema de Salud";
            break;
          }

          if (!this.$fun.isValidDate(this.itemHealth.HthEndDate)) {
            message =
              "Fecha no Valida del Campo Fecha de Fin de los Datos del Sistema de Salud";
            break;
          }
          break;
        default:
          break;
      }

      if (message) {
        this.$fun.alert(message, "warning");
        valid = false;
      }

      return valid;
    },

    validateData() {
      let valid = true;
      //console.log("Data Asegurados", this.item);

      if (this.pensionDetail.length == 0) {
        this.$fun.alert(
          "Los Datos de un Sitema Pensionario es obligatorio ",
          "warning"
        );
        valid = false;
      }

      if (!this.$fun.isValidDate(this.itemHealth.HthStartDate)) {
        this.$fun.alert(
          "Fecha no Valida del Campo Fecha de Inicio ",
          "warning"
        );
        valid = false;
      }

      if (!this.$fun.isValidDate(this.itemHealth.HthEndDate)) {
        this.$fun.alert("Fecha no Valida del Campo Fecha de Fin ", "warning");
        valid = false;
      }

      return valid;
    },

    updateDate(){
      this.itemPension.PsnEndDate = this.contractDates.AgtEndDate || null; // Actualiza automáticamente
      this.itemHealth.HthEndDate  = this.contractDates.AgtEndDate || null;

      this.itemPension.PsnBeginDate = this.contractDates.AgtAdmissionDate || null;
      this.itemHealth.HthStartDate  = this.contractDates.AgtAdmissionDate || null;
    }
  },
  watch: {
    Attach() {
      //console.log("AQUIII", this.Attach);
      if (this.Attach) {
        if (this.validFileType(this.Attach)) {
          console.log("Si");
        } else {
          this.$fun.alert("Solo se permite archivos de tipo PDF", "warning");
          console.log("AQUIII1111", this.Attach);
          // const para = document.createElement("p");
          // para.textContent = `File name ${file.name}: Not a valid file type. Update your selection.`;
          //this.itemPension.PsnAttach = undefined;
          this.Attach = null;
          //console.log("AQUIII2222", this.Attach);
          console.log("No");
        }
      }
    },
    'contractDates.AgtAdmissionDate'(newVal) {
      this.itemPension.PsnBeginDate = newVal; // Actualiza automáticamente
      this.itemHealth.HthStartDate  = newVal;

      this.itemPension.PsnEndDate = this.contractDates.AgtEndDate || null; // Actualiza automáticamente
      this.itemHealth.HthEndDate  = this.contractDates.AgtEndDate || null;
    },
    'contractDates.AgtEndDate'(newVal) {
      this.itemPension.PsnEndDate = newVal; // Actualiza automáticamente
      this.itemHealth.HthEndDate  = newVal;

      this.itemPension.PsnBeginDate = this.contractDates.AgtAdmissionDate || null;
      this.itemHealth.HthStartDate  = this.contractDates.AgtAdmissionDate || null;
    },
  },
  created() {
    if (this.datawork) {
      this.initialize();
      this.updateDate();
    } else {
      console.warn("datawork aún no está definido. Esperando su asignación.");
    }
  },
};
</script>
