let children = [];
//SST
children.push({
    path: "/Recursos_Humanos/RestriccionAccesos",
    name: "HMN_ACCESS_RESTRICTION",
    component: () =>
        import ("../views/HumanResource/AccessRestriction/AccessRestriction.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos  Humanos", disabled: true },
            {
                text: "Restricción de Accesos",
                href: "/Recursos_Humanos/RestriccionAccesos",
            },
        ],
    },
});

children.push({
    path: "/Recursos_Humanos/Salud_Ocupacional/Historias",
    name: "MEDICAL_RECORD",
    component: () =>
        import ("../views/HumanResource/OccupationalHealth/HistoryRecord.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos  Humanos", disabled: true },
            { text: "Salud Ocupacional", disabled: true },
            {
                text: "Historias Médicas",
                href: "/Recursos_Humanos/Salud_Ocupacional/Historias",
            },
        ],
    },
});
children.push({
    path: "/recursoshumanos/fotocheck",
    name: "FOTOCHECK",
    component: () =>
        import ("../views/HumanResource/FotoCheck.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Fotocheck", href: "/recursoshumanos/fotocheck" },
        ],
    },
});
children.push({
    path: "/recursoshumanos/cargo",
    name: "GEN_POSITION",
    component: () =>
        import ("../views/General/Position.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Cargo", href: "/recursoshumanos/cargo" },
        ],
    },
});
children.push({
    path: "/recursoshumanos/organigrama",
    name: "GEN_ORGANIZATION",
    component: () =>
        import ("../views/General/Organization.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            {
                text: "Organigrama",
                href: "/recursoshumanos/organigrama",
            },
        ],
    },
});
children.push({
    path: "/recursoshumanos/programacionaccesos",
    name: "MNG_ACCESSPROGRAMMING",
    component: () =>
        import ("../views/HumanResource/AccessProgramming.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            {
                text: "Programación de Accesos",
                href: "/recursoshumanos/programacionaccesos",
            },
        ],
    },
});
children.push({
    path: "/recursoshumanos/reporte_de_horas",
    name: "HMN_HOURS_REPORT",
    component: () =>
        import ("../views/HumanResource/HoursReport.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            {
                text: "Reporte de Horas",
                href: "/recursoshumanos/reporte_de_horas",
            },
        ],
    },
});
children.push({
    path: "/recursoshumanos/amonestacion",
    name: "HMN_ADMONITION",
    component: () =>
        import ("../views/HumanResource/Admonition.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Amonestaciones", href: "/recursoshumanos/amonestacion" },
        ],
    },
});

children.push({
    path: "/recursoshumanos/Personal_no_Sujetos_a_Ficalizacion",
    name: "HMN_PERSONNEL_NOT_SUBJECTS",
    component: () =>
        import ("../views/HumanResource/PersonnelNotSubjects.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Personal no Sujetos a Ficalización", href: "/recursoshumanos/Personal_no_Sujetos_a_Ficalizacion" },
        ],
    },
});

children.push({
    path: "/recursoshumanos/reporte_de_contratos",
    name: "HMN_CONTRACT_REPORT",
    component: () =>
        import ("../views/HumanResource/ContractReport.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Reporte de Contratos", href: "/recursoshumanos/reporte_de_contratos" },
        ],
    },
});

children.push({
    path: "/recursoshumanos/creacion_de_periodos",
    name: "HMN_CREATION_PERIODS",
    component: () =>
        import ("../views/HumanResource/CreationPeriods.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Creación de Periodos", href: "/recursoshumanos/creacion_de_periodos" },
        ],
    },
});

children.push({
    path: "/recursoshumanos/equipo_biometrico",
    name: "HMN_BIOMETRIC_EQUIPMENT",
    component: () =>
        import ("../views/HumanResource/BiometricEquipment.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Equipo Biometrico", href: "/recursoshumanos/equipo_biometrico" },
        ],
    },
});
children.push({
    path: "/recursoshumanos/bienestarsocial/asignacionfamiliar",
    name: "HMN_HOUSEHOLDALLOWANCE",
    component: () =>
        import ("../views/HumanResource/HouseHoldAllowance.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Bienestar Social", disabled: true },
            {
                text: "Asignación Familiar",
                href: "/recursoshumanos/bienestarsocial/asignacionfamiliar",
            },
        ],
    },
});
children.push({
    path: "/recursoshumanos/bienestarsocial/contingeciaslaborales",
    name: "HMN_WORKERALLOWANCE",
    component: () =>
        import ("../views/HumanResource/WorkerAllowance.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Bienestar Social", disabled: true },
            {
                text: "Contingencias Laborales",
                href: "/recursoshumanos/bienestarsocial/contingeciaslaborales",
            },
        ],
    },
});
children.push({
    path: "/recursoshumanos/adminpersonal/registrotrabajadores",
    name: "CONTRCNS_CONTTO",
    component: () =>
        import ("../views/HumanResource/contract.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Adm Personal", disabled: true },
            {
                text: "Registro de Trabajadores",
                href: "/recursoshumanos/adminpersonal/registrotrabajadores",
            },
        ],
    },
});
children.push({
    path: "/recursoshumanos/bienestarsocial/reportesasignacionfamiliar",
    name: "HMN_HHA_REPORT",
    component: () =>
        import ("../views/HumanResource/ReportHouseHoldWorker.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Bienestar Social", disabled: true },
            {
                text: "Reportes de Asignación Familiar",
                href: "/recursoshumanos/bienestarsocial/reportesasignacionfamiliar",
            },
        ],
    },
});
children.push({
    path: "/recursoshumanos/reportes",
    name: "HMN_REPORT_POSITION",
    component: () =>
        import ("../views/HumanResource/ReportHumanResource.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Reportes", href: "/recursoshumanos/reportes" },
        ],
    },
});
children.push({
    path: "/recursoshumanos/sst/induccion",
    name: "SST_INDUCTION",
    component: () =>
        import ("../views/Management/Instruction.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "SST", disabled: true },
            {
                text: "Inducción de Personal",
                href: "/recursoshumanos/sst/induccion",
            },
        ],
    },
});

children.push({
    path: "/recursoshumanos/sst/induccion_list",
    name: "SST_INDUCTION_LIST",
    component: () =>
        import ("../views/Management/Instruction/InstructionList.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "SST", disabled: true },
            {
                text: "Inducción de Personal",
                href: "/recursoshumanos/sst/induccion_list",
            },
        ],
    },
});

children.push({
    path: "/recursoshumanos/mantenimiento/profesiones",
    name: "PFNSIONS",
    component: () =>
        import ("../views/HumanResource/Profession.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Profesiones", disabled: true },
            {
                text: "Profesiones",
                href: "/recursoshumanos/mantenimiento/profesiones",
            },
        ],
    },
});
children.push({
    path: "/recursoshumanos/mantenimiento/cuadrillas",
    name: "CDRILLS",
    component: () =>
        import ("../views/HumanResource/hmnGang.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Cuadrillas", disabled: true },
            {
                text: "Profesiones",
                href: "/recursoshumanos/mantenimiento/cuadrillas",
            },
        ],
    },
});
children.push({
    path: "/recursoshumanos/compensaciones/AsientoScire",
    name: "HMN_JOURNALENTRY",
    component: () =>
        import ("../views/HumanResource/JournalEntrySCIRE.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Compensaciones", disabled: true },
            {
                text: "Asiento SCIRE SAP",
                href: "/recursoshumanos/compensaciones/AsientoScire",
            },
        ],
    },
});
children.push({
    path: "/recursoshumanos/utilidades",
    name: "UTILITY_SCHEDULING",
    component: () =>
        import ("../views/Management/UtilityScheduling.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            {
                text: "Programación de Utilidades",
                href: "/recursoshumanos/utilidades",
            },
        ],
    },
});
children.push({
    path: "/recursoshumanos/vaccine",
    name: "HMN_VACCINE",
    component: () =>
        import ("../views/HumanResource/Vaccine/Vaccine.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            {
                text: "Registro de Vacunas",
                href: "/recursoshumanos/Vacunas",
            },

        ],
    },
});

/// ------- Gestion Humnana
children.push({
    path: "/recursoshumanos/gestionhumana/programaciones",
    name: "HMN_INDUCTION_SCHEDULING",
    component: () =>
        import ("../views/HumanResource/HumanManagement/InstructionScheduling.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "GESTIÍON HUMANA", disabled: true },
            {
                text: "Inducción de Personal",
                href: "/recursoshumanos/gestionhumana/programaciones",
            },
        ],
    },
});
children.push({
    path: "/administracion/temas",
    name: "MNG_INDUCTION_SUBJECT",
    component: () =>
        import ("../views/Management/InstructionSubject.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Administración", disabled: true },
            { text: "Temas", href: "/administracion/temas" },
        ],
    },
});
children.push({
    path: "/recursoshumanos/gestionhumana/capacitaciones",
    name: "HMN_INDUCTION",
    component: () =>
        import ("../views/HumanResource/HumanManagement/Instruction.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "GESTIÍON HUMANA", disabled: true },
            {
                text: "Inducción de Personal",
                href: "/recursoshumanos/gestionhumana/capacitaciones",
            },
        ],
    },
});
children.push({
    path: "/recursoshumanos/preregistro",
    name: "HMN_PRE_REGISTRATION",
    component: () =>
        import ("../views/HumanResource/PreRegistration/PreRegistration.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Pre Registro", disabled: true },
            {
                text: "Pre Registro del Personal",
                href: "/recursoshumanos/preregistro",
            },
        ],
    },
});
children.push({
    path: "/recursoshumanos/asignarbus",
    name: "HMN_ASSIGN_BUS_PERSON",
    component: () =>
        import ("../views/HumanResource/BusControl/AssignBus.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Asignar Bus", disabled: true },
            {
                text: "Asignar Bus",
                href: "/recursoshumanos/asignarbus",
            },
        ],
    },
});
children.push({
    path: "/recursoshumanos/motivoamonestacion",
    name: "HMN_REASON_FOR_LIVELIHOOD",
    component: () =>
        import ("../views/HumanResource/Admonition/ReasonForLivelihood.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Motivo de Amonestacion", disabled: true },
            {
                text: "Motivo de Amonestacion",
                href: "/recursoshumanos/motivoamonestacion",
            },
        ],
    },
});


children.push({
    path: "/recursoshumanos/manejobuss",
    name: "HMN_BUSS",
    component: () =>
        import ("../views/HumanResource/BusControl/Buss.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Manejo Buss", disabled: true },
            {
                text: "Manejo Buss",
                href: "/recursoshumanos/manejobuss",
            },
        ],
    },
});

children.push({
    path: "/recursoshumanos/preregistrotopico",
    name: "HMN_PRE_REGISTRATION_TOPIC",
    component: () =>
        import ("../views/HumanResource/PreRegistration/PreRegistrationTopic.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Pre Registro Topico", disabled: true },
            {
                text: "Pre Registro del Personal",
                href: "/recursoshumanos/preregistrotopico",
            },
        ],
    },
});

children.push({
    path: "/recursoshumanos/primerllamado",
    name: "RECRUITMENT_CALL",
    component: () =>
        import ("../views/HumanResource/Recruitment/RecruitmentCall.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Mensaje de Texto", href: "/recursoshumanos/primerllamado" },
        ],
    },
});

children.push({
    path: "/recursoshumanos/feriados",
    name: "HMN_HOLIDAYS",
    component: () =>
        import ("../views/HumanResource/Configuration/Holiday.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Configuración", disabled: true },
            {
                text: "Feriados",
                href: "/recursoshumanos/feriados",
            },
        ],
    },
});
children.push({
    path: "/recursoshumanos/niveleducativo",
    name: "HMN_EDUCATIONAL_LEVEL",
    component: () =>
        import ("../views/HumanResource/EducationalLevel.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Nivel Educativo", href: "/recursoshumanos/niveleducativo" },
        ],
    },
});

children.push({
    path: "/recursoshumanos/vacaciones",
    name: "HMN_VACATIONS",
    component: () =>
        import ("../views/HumanResource/Vacations.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Vacaciones", href: "/recursoshumanos/vacaciones" },
        ],
    },
});

children.push({
    path: "/recursoshumanos/Certificaciones",
    name: "HMN_LIST_OF_CONTRACTS",
    component: () =>
        import ("../views/HumanResource/ContractList/ContractList.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Certificados", href: "/recursoshumano/Certificaciones" },
        ],
    },
});

children.push({
    path: "/recursoshumanos/compensaciones/horarios",
    name: "HMN_SCHEDULES",
    component: () =>
        import ("../views/HumanResource/Schedules/Schedules.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Compensaciones", disabled: true },
            { text: "Horarios", href: "/recursoshumanos/compensaciones/horarios" },
        ],
    },
});

children.push({
    path: "/recursoshumanos/t-registro/bajas",
    name: "HMN_CASUALTYLIST",
    component: () =>
        import ("../views/HumanResource/CasualtyList.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "T-Registro", disabled: true },
            { text: "Bajas", href: "/recursoshumano/t-registro/bajas" },
        ],
    },
});

//asignacion de trabajadores a equipo biometrico
children.push({
  path: "/recursoshumanos/AsignacionBiometrico",
  name: "HMN_BIOMETRIC_WORKER",
  component: () =>
      import ("../views/HumanResource/AccessRestriction/BiometricWorker.vue"),
  meta: {
      authenticated: true,
      breadcrumb: [
          { text: "Inicio", href: "/#/" },
          { text: "Recursos Humanos", disabled: true },
          { text: "Adm. Personal", disabled: true },
          { text: "Asignación de Trabajador", href: "/recursoshumano/AsignacionBiometrico" },
      ],
  },
});
//fin asignacion de trabajadores a equipo biometrico
children.push({
    path: "/recursoshumanos/cambiarturnomasivo",
    name: "HMN_CHANGE_SHIFT",
    component: () =>
        import ("../views/HumanResource/ChangeShiftMass.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Cambiar Turno Masivo", href: "/recursoshumanos/cambiarturnomasivo" },
        ],
    },
});
children.push({
    path: "/recursoshumanos/sst/config_epps",
    name: "SST_CONFGEPPS",
    component: () =>
        import ("../views/Management/Instruction/config_epps.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Config. Epps", disabled: true },
            {
                text: "Config. Epps",
                href: "/recursoshumanos/sst/config_epps",
            },
        ],
    },
});
children.push({
    path: "/recursoshumanos/compensaciones/regularizacion",
    name: "HMN_MARKING",
    component: () =>
        import ("../views/HumanResource/Marking.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Compensaciones", disabled: true },
            {
                text: "Regularización",
                href: "/recursoshumanos/compensaciones/regularizacion",
            },
        ],
    },
});
children.push({
    path: "/recursoshumanos/t-registro/altas",
    name: "HMN_GENERATIONTXT",
    component: () =>
        import ("../views/HumanResource/GenerationTxt.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "T-Registro", disabled: true },
            { text: "Altas", href: "/recursoshumanos/t-registro/altas" },
        ],
    },
});
children.push({
    path: "/recursoshumanos/usrIP",
    name: "HMNUSRIP",
    component: () =>
        import ("../views/HumanResource/UserIP.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "UsrIP", disabled: true },
            { text: "UsrIP", href: "/recursoshumanos/usrIP", },
        ],
    },
});
children.push({
    path: "/recursoshumanos/compensaciones/horario-trabajador",
    name: "HMN_SCHEDULE_WORKER",
    component: () =>
        import ("../views/HumanResource/Compensation/ScheduleWorker.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Compensaciones", disabled: true },
            { text: "Horario-Trabajador", href: "/recursoshumanos/compensaciones/horario-trabajador" },
        ],
    },
});
children.push({
    path: "/recursoshumanos/compensaciones/asistencia-remota",
    name: "HMN_ATTENDANCE_REMOTE",
    component: () =>
        import ("../views/HumanResource/Compensation/AttendanceRemote.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Compensaciones", disabled: true },
            { text: "Asistencia Remota", href: "/recursoshumanos/compensaciones/asistencia-remota" },
        ],
    },
});
children.push({
    path: "/recursoshumanos/fichatrabajador",
    name: "HMN_WORKER_TAB",
    component: () =>
        import ("../views/HumanResource/WorkerTab/WorkerTab.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Ficha de Trabajador", href: "/recursoshumano/fichatrabajador" },
        ],
    },
});
children.push({
    path: "/recursoshumanos/sst/peligros-sst",
    name: "SST_REGISTER_DANGER",
    component: () =>
        import ("../views/HumanResource/Sst/DangerSST/DangerSST.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "SST", disabled: true },
            {
                text: "Peligros SST",
                href: "/recursoshumanos/sst/peligros-sst",
            },
        ],
    },
});
//-----------Remote work-----------//
children.push({
    path: "/recursoshumanos/trabajo-remoto",
    name: "HMN_REMOTE_WORK",
    component: () =>
        import ("../views/HumanResource/Compensation/RemoteWork.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Compensaciones", disabled: true },
            { text: "Trabajo Remoto", href: "/recursoshumanos/trabajo-remoto" },
        ],
    },
});
//-----------Massive print-----------//
children.push({
    path: "/recursoshumanos/impresion-masiva",
    name: "HMN_PRINT_MASIVE",
    component: () =>
        import ("../views/HumanResource/PersonalManagement/MasivePrint.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Adm. Personal", disabled: true },
            { text: "Impresión Masiva", href: "/recursoshumanos/impresion-masiva"},
        ],
    },
});
children.push({
    path: "/recursoshumanos/compensaciones/asistencia-masiva-gang",
    name: "HMN_MASSIVE_ASSISTANCE_GANG",
    component: () =>
        import ("../views/HumanResource/Compensation/MassiveAssistanceGang.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Recursos Humanos", disabled: true },
            { text: "Compensaciones", disabled: true },
            { text: "Asistencia Masiva de Cuadrilla", href: "/recursoshumanos/compensaciones/asistencia-masiva-gang"},
        ],
    },
});
export { children }