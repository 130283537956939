<template>
  <div>
    <v-dialog v-model="dialogAdd" v-if="dialogAdd" width="80%">
      <v-card>
        <v-container>
          <order-attention-purchase v-if="orderLocal.TypeOrder == 1" @selected="selectedAttention($event)"
            :model="true"></order-attention-purchase>
          <order-attention-service v-if="orderLocal.TypeOrder == 3" @selected="selectedAttention($event)"
            :model="true"></order-attention-service>
        </v-container>
      </v-card>
    </v-dialog>

    <s-processing v-model="processing" :message="message"></s-processing>
    <!--CIRCUITO DE APRBACIONES MODAL-->
    <div v-if="dialogCircuit">
      <v-dialog v-model="dialogCircuit" width="80%">
        <s-toolbar color="primary" dark label="Circuitos Asociados a la Operación" @close="dialogCircuit = false"
          close></s-toolbar>
        <v-card>
          <v-container>
            <v-row>
              <v-col lg="7">
                <v-data-table dense item-key="AccID" :items="circuits" :items-per-page="-1" :headers="[
                  { value: 'AccID', text: 'ID' },
                  { value: 'AccName', text: 'Circuito' },
                ]" @dblclick:row="createCircuit" v-model="selectedCircuit" @click:row="rowClickCircuit" />
              </v-col>
              <v-col lg="5">
                <s-crud v-if="this.filterLevels.AccID != null" no-toolbar :config="configLevels" :filter="filterLevels"
                  title="Niveles" />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </div>
    <div>
      <s-toolbar :download="orderLocal.OrdStatus == 5" @download="downloadPdf()"
        :load="orderLocal.OrdStatus == 1 && viewLoad" close :save="value == null || orderLocal.OrdStatus == 3"
        @save="save()" :label="this.value
            ? (orderLocal.OrdDocumentNumber ? orderLocal.OrdDocumentNumber : '') + ' - ' + (orderLocal.NtpFullName ? orderLocal.NtpFullName : '')
            : 'Orden de Compra'
          " @close="$emit('onClose')" :transactionApproved="transactionApproved" @load="loadExternal()" />
      <v-card :loading="loadingCard">
        <v-col>
          <v-row>
            <v-col lg="9" style="padding-right: 0px" cols="12">
              <v-col style="">
                <v-row>
                  <v-col class="s-col-text">
                    <s-text v-if="orderLocal.OrdStatus == 5" disabled label="Proveedor" v-model="orderLocal.SupName" />
                    <s-supplier v-else :readonly="!isEditable" label="Proveedor" v-model="Supplier" />
                  </v-col>
                  <v-col lg="3" cols="12" class="s-col-text">
                    <s-contact-supplier :disabled="!isEditable" label="Contacto" :SupID="SupID"
                      v-model="orderLocal.ConID" />
                  </v-col>
                  <v-col lg="2" cols="6" class="s-col-text">
                    <s-select-definition :disabled="!isEditable" :def="1129" label="Moneda"
                      v-model="orderLocal.TypeCurrency" />
                  </v-col>
                  <v-col lg="2" class="s-col-text">
                    <s-date label="Fecha" v-model="dateOrder" :disabled="!isEditable"></s-date>
                  </v-col>
                </v-row>
              </v-col>
              <v-row>
                <v-col>
                  <div class="mt-2">
                    <s-toolbar label="Detalle" :add="isEditable" @add="dialogAdd = true" :remove="isEditable"
                      @removed="removeItem()" v-if="orderLocal.OrdStatus == 3"></s-toolbar>
                  </div>
                  <v-data-table dense @click:row="rowClick" :headers="headersSAP" :items-per-page="-1"
                    v-model="selectedSAP" :items="itemsSAP" item-key="Line" :loading="loading"
                    :height="$vuetify.breakpoint.xs ? 'auto' : '300'" hide-default-footer>
                    <!-- Duplicar fila  v-if="orderLocal.TypeOrder != 1"-->
                    <template v-slot:item.action='{ item }'>
                      <!-- <v-btn @click="addItem(item)" 
                       v-if="isEditable"  
                       :hidden="!isEditable"
                       x-small class="v-btn v-btn--text theme--light elevation-0 v-size--small">
                        <v-icon v-if="item.duplicateLine" small class="v-icon notranslate mdi mdi-minus-circle-outline theme--light"></v-icon>
                        <v-icon v-else small class="v-icon notranslate mdi mdi-plus-circle-outline theme--light"></v-icon>
                      </v-btn> -->
                      <v-menu v-if="isEditable" :hidden="!isEditable">
                        <template v-slot:activator="{ on }">
                          <v-btn :color="item.duplicateLine ? 'warning' : ''" icon small v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <!-- v-for="(item, i) in items" -->
                        <v-list>
                          <v-list-item key="ocDuplicar" @click="addItem(item)"
                            v-if="orderLocal.TypeOrder != 1 && !item.duplicateLine">
                            <v-list-item-title> Duplicar </v-list-item-title>
                          </v-list-item>
                          <v-list-item key="ocdeleteLinea" @click="deleteitem(item)">
                            <v-list-item-title> Eliminar Linea </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </template>
                    <template v-slot:item.TypeCostName="props">
                      <div>
                        <v-edit-dialog v-if="isEditable" large @click="selectedSAP = [props.item]"
                          cancel-text="Cancelar" save-text="Guardar" @save="changeItem(props.item)">
                          <v-row @click="selectedSAP = [props.item]">
                            <v-col>
                              <div>{{ props.item.TypeCostName }}</div>
                            </v-col>
                          </v-row>
                          <template v-slot:input>
                            <div class="mt-4 title mb-4">Tipo de Costo</div>
                            <s-select-definition :def="1045" return-object v-model="props.item.TypeCostObject" />
                          </template>
                        </v-edit-dialog>
                        <div v-else>
                          <div>{{ props.item.TypeCostName }}</div>
                        </div>
                      </div>
                    </template>
                    <template v-slot:item.BslName="props">
                      <div>
                        <v-edit-dialog v-if="isEditable" large @click="selectedSAP = [props.item]"
                          cancel-text="Cancelar" save-text="Guardar" @save="changeItem(props.item)">
                          <v-row @click="selectedSAP = [props.item]">
                            <v-col>
                              <div>{{ props.item.BslName }}</div>
                            </v-col>
                          </v-row>
                          <template v-slot:input>
                            <div class="mt-4 title mb-4">Linea de Negocio</div>
                            <!--props.item.CceID-->
                            <s-select-business-line :cceID='props.item.CceID == null ? 0 : props.item.CceID' return-object
                              v-model="props.item.BslObject" />
                          </template>
                        </v-edit-dialog>
                        <div v-else>
                          <div>{{ props.item.BslName }}</div>
                        </div>
                      </div>
                    </template>
                    <template v-slot:item.CceName="props">
                      <div>
                        <v-edit-dialog v-if="isEditable" large @click="selectedSAP = [props.item]"
                          cancel-text="Cancelar" save-text="Guardar" @save="changeItem(props.item)">
                          <v-row @click="selectedSAP = [props.item]">
                            <v-col>
                              <div>{{ props.item.CceName }}</div>
                            </v-col>
                          </v-row>
                          <template v-slot:input>
                            <div class="mt-4 title mb-4">Centro de Costo</div>
                            <s-select-area centercost return-object v-model="props.item.CceObject" />
                          </template>
                        </v-edit-dialog>
                        <div v-else>
                          <div>{{ props.item.CceName }}</div>
                        </div>
                      </div>
                    </template>
                    <template v-slot:item.TaxCode="props">
                      <div>
                        <v-edit-dialog v-if="isEditable" large @cancel="dialogTax = false" @close="dialogTax = false"
                          @open="dialogTax = true" @click="selectedSAP = [props.item]" cancel-text="Cancelar"
                          save-text="Guardar" @save="
                            dialogTax = false;
                          changeTax(props.item);
                          ">
                          <v-row @click="selectedSAP = [props.item]">
                            <v-col>
                              <div>{{ props.item.TaxCode }}</div>
                            </v-col>
                          </v-row>
                          <template v-slot:input v-if="dialogTax">
                            <div class="mt-4 title mb-4">
                              Selecciona Impuesto
                            </div>
                            <s-select-definition id="DedAbbreviation" return-object :def="1143" v-model="tax" />
                          </template>
                        </v-edit-dialog>
                        <div v-else>
                          <div>{{ props.item.TaxCode }}</div>
                        </div>
                      </div>
                    </template>

                    <template v-slot:item.OddUnitPrice="props">
                      <s-text style="width: 75px" decimal @input="changeItem(props.item)" :disabled="!isEditable"
                        @paste="pastePrice($event)" v-model="props.item.OddUnitPrice">
                        Precio Unitario
                      </s-text>
                    </template>

                    <!-- modificando de descripcion si es en requerimiento de servicio -->
                    <template v-slot:item.OddDescription="props" v-if="orderLocal.TypeOrder != 1">
                      <s-text style="width: 400px" @input="changeItem(props.item)" :disabled="!isEditable"
                         :maxlength="100" v-model="props.item.OddDescription">
                        Descripcion</s-text>
                    </template>
                    <template v-slot:item.OddQuantity="props">
                      <s-text style="width: 80px" @blur="blurQuantity($event, props.item)" :disabled="!isEditable"
                        decimal @paste="pasteQuantity($event)" v-model="props.item.OddQuantity">
                        Cantidad Atendida
                      </s-text>
                    </template>
                    <template v-slot:item.AcoName="props">
                      <v-edit-dialog large v-if="isEditable" @save="saveAccount(props.item)" cancel-text="Cancelar"
                        save-text="Guardar">
                        <div>{{ props.item.AcoName }}</div>
                        <template v-slot:input>
                          <div class="mt-4 mb-4">
                            Concepto - Cuenta Contable
                          </div>
                          <s-select item-value="ConName" clearable item-text="ConName" label="Concepto Servicio"
                            v-model="ConName" :items="itemsConcept" />
                          <s-select label="Cuenta Contable" clearable item-value="CsaID" return-object
                            item-text="CsaDescription" v-model="AcoID" :items="itemsAccount" />
                        </template>
                      </v-edit-dialog>
                      <div v-else>
                        <div>{{ props.item.AcoName }}</div>
                      </div>
                    </template>
                    <template v-slot:item.OddObservation="props">
                      <s-text label="" v-if="props.item.ReqID == null" v-model="props.item.OddObservation"></s-text>
                      <div v-else>
                        <s-text label="" v-model="props.item.OddObservation"></s-text>

                      </div>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-col style="padding-top: 24px">
                <v-row>
                  <v-col lg="3" cols="12">
                    <v-row>
                      <v-col class="s-col-form">
                        <s-select-definition :def="1145" label="Encargado de Compras" :disabled="!isEditable"
                          v-model="orderLocal.OfficePurchase" />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <s-select-paymentterms v-model="PytID" label="Condición de Pago" />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col lg="3">
                    <v-row>
                      <v-col lg="6" class="s-col-form"><v-label>SUB-TOTAL</v-label></v-col>
                      <v-col lg="6" class="s-col-form">
                        <h4 class="text-right">
                          <!-- {{
                            orderLocal.OrdSubTotal >= 1000
                              ? orderLocal.OrdSubTotal.toLocaleString("es-MX")
                              : orderLocal.OrdSubTotal.toFixed(4)
                              
                          }} -->
                          <!-- // -->
                          {{ orderLocal.OrdSubTotal | numberDefault }}
                        </h4>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="s-col-form"><v-label>IMPUESTO</v-label></v-col>
                      <v-col class="s-col-form">
                        <h4 class="text-right">
                          <!-- {{
                            orderLocal.OrdTaxAmount >= 1000
                              ? orderLocal.OrdTaxAmount.toLocaleString("es-MX")
                              : orderLocal.OrdTaxAmount.toFixed(4)
                              
                          }} -->
                          <!-- // -->
                          {{ orderLocal.OrdTaxAmount | numberDefault }}
                        </h4>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="s-col-form">
                        <v-label>TOTAL</v-label>
                      </v-col>
                      <v-col class="s-col-form">
                        <h4 class="text-right">
                          <!-- {{
                            orderLocal.OrdTotal >= 1000
                              ? orderLocal.OrdTotal.toLocaleString("es-MX")
                              : orderLocal.OrdTotal.toFixed(4)
                          }} -->
                          <!-- // -->
                          {{ orderLocal.OrdTotal | numberDefault }}
                        </h4>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-col>
            <v-col lg="3">
              <v-card height="520">
                <v-col>
                  <v-row>
                    <v-col class="s-col-text">
                      <s-select-Campaign :disabled="!isEditable" label="Campaña" v-model="orderLocal.CmpID" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="s-col-text">
                      <s-text :maxlength="20" label="N° Cotización" :disabled="!isEditable"
                        v-model="orderLocal.QuoteNumber" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="s-col-text">
                      <s-select-definition :disabled="!isEditable" label="Clase de Operación"
                        v-model="orderLocal.ClassOperationPurchase" :def="1147" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="s-col-text">
                      <s-select-definition :disabled="!isEditable" label="Tipo Compra" :def="1146"
                        v-model="orderLocal.TypePurchase" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col lg="12" class="s-col-form" cols="12">
                      <s-select :disabled="!isEditable" clearable label="Bienes y Servicios" :items="goodServiceList"
                        item-text="DedDescription" item-value="DedValue" v-model="orderLocal.OrdGoodService" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="s-col-text">
                      <s-textarea :disabled="!isEditable" label="Comentarios" v-model="orderLocal.OrdObservation" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col lg="12" class="s-col-text" cols="12">
                      <v-row>
                        <v-col lg="10" cols="10">
                          <v-file-input :disabled="!isEditable" label="Anexo 01" v-model="Attach1" hide-details />
                        </v-col>
                        <v-col>
                          <v-btn color="success" class="mt-5" :disabled="false" icon x-small @click="openFile(Attach1)">
                            <v-icon>mdi-download</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col lg="12" class="s-col-text" cols="12">
                      <v-row>
                        <v-col lg="10" cols="10">
                          <v-file-input :disabled="!isEditable" label="Anexo 02" v-model="Attach2" hide-details />
                        </v-col>
                        <v-col>
                          <v-btn color="success" class="mt-5" :disabled="false" icon x-small @click="openFile(Attach2)">
                            <v-icon>mdi-download</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col lg="12" class="s-col-text" cols="12">
                      <v-row>
                        <v-col lg="10" cols="10">
                          <v-file-input :disabled="!isEditable" label="Anexo 03" v-model="Attach3" hide-details />
                        </v-col>
                        <v-col>
                          <v-btn color="success" class="mt-5" :disabled="false" icon x-small @click="openFile(Attach3)">
                            <v-icon>mdi-download</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col lg="12" class="s-col-text" cols="12">
                      <v-row>
                        <v-col lg="10" cols="10">
                          <v-file-input :disabled="!isEditable" label="Anexo 04" v-model="Attach4" hide-details />
                        </v-col>
                        <v-col>
                          <v-btn color="success" class="mt-5" :disabled="false" icon x-small @click="openFile(Attach4)">
                            <v-icon>mdi-download</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </div>
  </div>
</template>
<!-- </s-crud></v-col> </v-row>
 </div>
</template> -->
<script>

import OrderAttentionPurchase from "@/views/Logistics/OrderStore/OrderPurcharseAttentionAdd.vue";
import OrderAttentionService from "@/views/Logistics/Requirement/ServiceAttentionAdd.vue";
import _sApprovalTransaction from "../../../services/Security/ApprovalTransactionService";
import SContactSupplier from "../../../components/Utils/Logistics/SContactSupplier.vue";
import SSupplier from "../../../components/Utils/Logistics/SSupplier.vue";
import _sRequirementDetail from "../../../services/Logistics/RequirementDetailService";
import _sRequirement from "../../../services/Logistics/RequirementService";
import _sGeneral from "@/services/HelperService.js";
import _sOrderPurchase from "../../../services/Logistics/OrderPurchaseService"; // servicio tabla
import SCircuitApproval from "../../../components/Utils/Security/SCircuitApproval.vue";
import _sApprovalLevel from "@/services/Security/ApprovalLevelsService";
import SSelectBusinessLine from "../../../components/Utils/SSelectBusinessLine.vue";
import SSelectPaymentterms from "../../../components/logistics/SSelectPaymentterms.vue";
import _sApproval from "@/services/Logistics/OrderPurchaseApproval";
import _sGenParam from "../../../services/General/ParameterService";

export default {
  name: "OrderPurchaseEdit",
  components: {
    SSelectPaymentterms,
    SSupplier,
    SContactSupplier,
    SCircuitApproval,
    SSelectBusinessLine,
    OrderAttentionPurchase,
    OrderAttentionService,
  },
  props: {
    order: {},
    value: null,
    typeOrder: null,
    viewLoad: { type: Boolean, default: true },


  },
  data: () => ({
    urlWhatsApp1: "",
    urlWhatsApp2: "",
    numberWhatsAppAPI: "",
    tokenWhatsAppAPI: "",

    formSubmitted: false,
    itemsDataTotal: [],
    dialogTax: false,
    selectedTax: false,
    selectedrow: [],
    circuits: [],
    itemsCom: [{ OddDescription: "ss" }],
    message: "",
    processing: false,
    PytID: null,
    selectedCircuit: [],
    validarFila: { type: Boolean, default: true },
    filterLevels: {},
    configLevels: {
      model: {
        AccID: "int",
        AlvID: "ID",
        SecStatus: "boleam",
        PstID: "int",
        PstName: "string",
        OddAmount: "",
        OddUnitPrice: "",
        TaxCode: "",
      },
      service: _sApprovalLevel,
      headers: [
        { text: "Cargo", value: "PstName" },
        { text: "Nivel", value: "TypeLevelText" },
      ],
    },
    loadingCard: false,
    loading:false,
    optionApproved: false,
    dateOrder: null,
    selectedSAP: [],
    transactionApproved: null,
    tax: null,
    dialogCircuit: false,
    orderLocal: {},
    dialogAdd: false,
    itemsConcept: [],
    itemsAccount: [],
    AcoID: null,
    ConID: null,
    ConName: null,
    SupID: "",
    Supplier: null,
    itemsSAP: [],
    itemsRemove: [],
    dialogLoad: false,
    Attach1: null,
    Attach2: null,
    Attach3: null,
    Attach4: null,
    goodServiceList: [],
    headersSAP: [
      {
        text: "",
        value: "action",
        sortable: false,
        width: 40,
      },
      {
        text: "Código",
        value: "ArtCode",
        sortable: false,
        width: 70,
      },
      {
        text: "Descripción",
        value: "OddDescription",
        sortable: false,
        width: 540,
      },
      { text: "Prc. U.", value: "OddUnitPrice",  width: 90 },
      { text: "Impuesto", value: "TaxCode", sortable: false, width: 60 },
      { text: "(%)Imp", value: "OddTaxPercentage", sortable: false, width: 70 },
      {
        text: "tax",
        value: "OddTaxAmount",
        sortable: false,
        width: 70,
        align: "right",
      },
      {
        text: "SubTotal",
        value: "OddAmount",
        sortable: false,
        width: 70,
        align: "right",
      },
      { text: "Centro Costo", value: "CceName", sortable: false, width: 300 },
      {
        text: "Linea de Negocio",
        value: "BslName",
        sortable: false,
        width: 300,
      },
      {
        text: "Tipo Costo",
        value: "TypeCostName",
        sortable: false,
        width: 100,
      },
      { text: "Proyecto", value: "PrjName", sortable: false, width: 300 },
      {
        text: "Comentario",
        value: "OddObservation",
        sortable: false,
        width: 400,
      },
      { text: "Fecha de solicitud", value: "SecCreateReq", sortable: false, width: 180 },
      { text: "Area solicitante", value: "AreNameReq", sortable: false, width: 180 },
    ],
  }),
  computed: {

    isEditable() {
      
     
      return (
        (this.orderLocal.OrdStatus == 3 &&
          this.$fun.getUserID() == this.orderLocal.UsrCreateID) ||
        this.orderLocal.OrdID == null
      );

    },

    // orderCreateEditable () {
    //   return (
    //     (this.orderLocal.OrdStatus== 3 &&
    //       this.$fun.getUserID() == this.orderLocal.UsrCreateID)
    //   );
    // },

  },
  methods: {
    getGoodServiceList() {
      _sRequirement
        .goodservicelist(this.$fun.getUserID())
        .then(resp => {
          if (resp.status == 200) {
            console.log("Data q traeeee",resp.data);
            this.goodServiceList = resp.data;
          }
        })
    },


    downloadPdf() {

      this.$emit("download", this.orderLocal);
    },

    addItem(item) {

      if (item.duplicateLine) {

        let lineDelete = item.Line - 1;
        this.itemsSAP.splice(lineDelete, 1)

        for (let i = 0; i < this.itemsSAP.length; i++) {
          this.itemsSAP[i].Line = i + 1;
        }

      } else {

        let newItem = Object.assign({}, item);
        newItem.duplicateLine = true;
        newItem.Line = this.itemsSAP.length + 1;
        newItem.OddID = null;
        this.itemsSAP.push(newItem);

        
        // this.validarFila = false
      }
      this.changeTotal();
    },
    deleteitem( item ) {
      console.log("eliminar item", item);
      let lineDelete = item.Line - 1;
      this.itemsSAP.splice(lineDelete, 1)

      for (let i = 0; i < this.itemsSAP.length; i++) {
        this.itemsSAP[i].Line = i + 1;
      }
      if ( item.OddID > 0){
        item.OddStatus = 0;
        this.itemsRemove.push( item );
      }
       
      this.changeTotal();
    },
    openFile(url) {
      console.log("dow",url.name);
      _sGeneral.download(url.name, this.$fun.getUserID()).then((r) => {
        this.$fun.download(r.data, url.name);
      });
    },
    selectedAttention(items) {
      if (items.length > 0) {
        items[0].OddID = 0;
      }
      for (let i = 0; i < items.length; i++) {
        items[i].OddDescription = items[i].DerDescription;
        items[i].OddQuantity = items[i].DerQuantity;
        if (this.orderLocal.TypeOrder == 3) {
          items[i].OddQuantity = 1;
          items[i].OddAccount = items[i].ReqAccount;
        }
        items[i].OddObservation = items[i].DerObservation;
        items[i].Line = i + this.itemsSAP.length + 1;
      }
      this.itemsSAP.push(...items);
      this.dialogAdd = false;
    },
    removeItem() {
      
      if (this.selectedSAP.length == 1) {
        if (this.itemsSAP.length <= 1) {
          this.$fun.alert(
            "No se permite eliminar el ultimo item de la orden",
            "warning"
          );
          return;
        }
        this.itemsSAP = this.itemsSAP.filter(
          (x) => x.OddID != this.selectedSAP[0].OddID
        );
        this.selectedSAP[0].OddStatus = 0;

        if (this.selectedSAP[0].OddID > 0)
          this.itemsRemove.push(this.selectedSAP[0]);
        this.changeTotal();
      }
    }, ////
    changeTotal() {
       
      this.orderLocal.OrdSubTotal = 0;
      this.orderLocal.OrdTaxAmount = 0;
      this.itemsSAP.forEach((element) => {
        let unitPrice = element.OddUnitPrice == null ? 0 : element.OddUnitPrice;
        this.orderLocal.OrdSubTotal =
          parseFloat(this.orderLocal.OrdSubTotal) +
          parseFloat(unitPrice) *
          (element.OddQuantity == null ? 1 : parseFloat(element.OddQuantity));
        this.orderLocal.OrdTaxAmount =
          parseFloat(this.orderLocal.OrdTaxAmount) +
          unitPrice *
          parseFloat(element.OddQuantity) *
          (element.OddTaxPercentage == null
            ? 0
            : parseFloat(element.OddTaxPercentage) / 100);
      });
      this.orderLocal.OrdSubTotal = parseFloat(
        parseFloat(this.orderLocal.OrdSubTotal).toFixed(4)
      );
      this.orderLocal.OrdTaxAmount = parseFloat(
        parseFloat(this.orderLocal.OrdTaxAmount).toFixed(4)
      );
      this.orderLocal.OrdTotal =
        this.orderLocal.OrdSubTotal + this.orderLocal.OrdTaxAmount;
    },
    blurQuantity(event, item) {
      if (item.ReqID != null) {
        if (item.DerQuantity < item.OddQuantity || item.OddQuantity <= 0) {
          this.$fun.alert("Cantidad Incorrecta", "warning");
          item.OddQuantity = item.DerQuantity;

          //this.itemsSAP.splice(this.itemsSAP.indexOf(item),1,item);
        }
      }
      this.changeItem(item);
    },

    pasteQuantity(event) {
      event.preventDefault();
      let quantitys = [];
      let i = 0;
      let isValid = true;
      event.clipboardData
        .getData("text/plain")
        .split("\n")
        .forEach((element) => {
          if (element.trim() != "") {
            quantitys[i] = parseFloat(element.trim());
            if (Number.isNaN(quantitys[i])) {
              this.$fun.alert("Datos incorrectos", "warning");
              isValid = false;
            }
            i++;
          }
        });
      if (isValid == true)
        for (i = 0; i < this.itemsSAP.length && i < quantitys.length; i++) {
          this.itemsSAP[i].OddQuantity = quantitys[i];
          this.changeItem(this.itemsSAP[i]);
        }
    },
    handlePaste(event)
    {
      event.preventDefault();
      const pasteData = (event.clipboardData || window.clipboardData).getData('text');
      const numericPasteData = pasteData.replace(/[^0-9.]/g, ''); 
      const parts = numericPasteData.split('.');
      if (parts.length > 2) {
        document.execCommand('insertText', false, parts[0] + '.' + parts.slice(1).join(''));
      } else {
        document.execCommand('insertText', false, numericPasteData);
      }
    },
    pastePrice(event) {
      
      event.preventDefault();
      let quantitys = [];
      let i = 0;
      let isValid = true;
      event.clipboardData
        .getData("text/plain")
        .split("\n")
        .forEach((element) => {
          if (element.trim() != "") {
            quantitys[i] = parseFloat(element.trim());
            if (Number.isNaN(quantitys[i])) {
              this.$fun.alert("Datos incorrectos", "warning");
              isValid = false;
            }
            i++;
          }
        });
      if (isValid == true)
        for (i = 0; i < this.itemsSAP.length && i < quantitys.length; i++) {
          this.itemsSAP[i].OddUnitPrice = quantitys[i];
          this.changeItem(this.itemsSAP[i]);
        }
    },

    loadExternal() {
      this.$fun.alert("Seguro de Cargar Orden?", "question")
      .then( async (r) => {
        if (r.value) {
          // this.processing = true;
          this.orderLocal.PytID = this.PytID;

          console.log("this.orderLocal:",this.orderLocal);

          const UsrID = this.orderLocal.UsrID;
          const itemRespuesta = [];
          await _sApproval.listPerson(UsrID, this.$fun.getUserID())
            .then((r) => {
                if (r.status == 200) {
                  itemRespuesta.push(r.data[0]);
                }
            });

          const NtpFullNameSupplier = itemRespuesta[0].NtpFullNameSupplier;
          const message = `Estimado(a) ${itemRespuesta[0].NtpFullName}, se le informa que su requerimiento que pertenece a la orden de compra : ${this.orderLocal.OrdDocumentNumber} al proveedor ${this.orderLocal.SupName} por el monto de ${this.orderLocal.TypeCurrencyName} ${this.orderLocal.OrdTotal} ha sido cargada a SAP por ${NtpFullNameSupplier}.`;
          const PpnNumberPhone = itemRespuesta[0].PpnNumberPhone;

          await this.$fun.sendWhatsAppMessage(this.urlWhatsApp1, this.numberWhatsAppAPI, this.urlWhatsApp2, this.tokenWhatsAppAPI, message, PpnNumberPhone);

          _sOrderPurchase
            .loadExternal(
              this.orderLocal,
              this.$fun.getUserID(),
              this.$fun.getUserID()
            )
            .then(
              (r) => {
                this.processing = false;
                this.$fun.alert("Cargado Correctamente", "success");
                this.$emit("onClose");
              },
              (e) => {
                this.message = e.response.data.Message;
                this.processing = true;
                let messageInclude = 'Orden de Compra ya fue cargada en SAP'
                let includeMessageLoadSap = this.message.includes(messageInclude);
                if (includeMessageLoadSap) {
                  /* Con esto actualizamos el estado a subido a sap */
                  // this.orderLocal.OrdStatus = 100;
                  this.orderLocal.OrdStatus = 5;
                  _sOrderPurchase.setStatus(this.orderLocal, this.$fun.getUserID()).then((r) => {
                    this.$refs.crud.refresh();
                  });
                }
              }
            );
        }
      });
    },
    rowClickCircuit: function (item, row) {
      this.selectedCircuit = [item];
      this.filterLevels = { AccID: item.AccID };
    },
    createCircuit(item) {
      this.$fun
        .alert("Guardar con " + this.selectedCircuit[0].AccName, "question")
        .then((r) => {
          if (r.value) {
            this.orderLocal.UsrUpdateID = this.selectedCircuit[0].AccID;
            this.dialogCircuit = false;
            this.orderLocal.Detail = this.itemsSAP;
            _sOrderPurchase
              .save(
                this.orderLocal,
                this.$fun.getUserID(),
                this.selectedCircuit[0].AccID
              )
              .then((r) => {
                this.$emit("onCloseClear");
                this.$fun.alert("Agregado Correctamente", "success");
                this.$emit("close");
                this.$emit("onClose");
              });
          }
        });
    },
    changeItem(item) {
      

      this.orderLocal.OrdSubTotal = 0;
      this.orderLocal.OrdTaxAmount = 0;
      let edit = Object.assign(
        {},
        this.itemsSAP.find((x) => x.Line == item.Line)
      );
      let indexEdit = this.itemsSAP.indexOf(item);
      if (edit.CceObject) {
        let cce = edit.CceID;
        edit.CceID = edit.CceObject.GenID;
        edit.CceName = edit.CceObject.GenDescription;
        if (edit.CceObject.GenID != cce) {
          edit.BslID = null;
          edit.BslName = "";
        } else {
          if (edit.BslObject) {
            edit.BslID = edit.BslObject.GenID;
            edit.BslName = edit.BslObject.GenDescription;
          }
        }
      }
      if (edit.TypeCostObject) {
        edit.TypeCost = edit.TypeCostObject.DedValue;
        edit.TypeCostName = edit.TypeCostObject.DedDescription;
      }
      //   edit.OddUnitPrice = edit.OddUnitPrice == null ? 0 :parseFloat( edit.OddUnitPrice);
      // if(this.value)
      edit.OddTaxAmount =
        parseFloat(((edit.OddTaxPercentage == null ? 0 : edit.OddTaxPercentage) / 100) *
        edit.OddUnitPrice *
        edit.OddQuantity).toFixed(6);
      edit.OddAmount =
        parseFloat(parseFloat(edit.OddUnitPrice * edit.OddQuantity) +
        parseFloat(edit.OddTaxAmount == null ? 0 : edit.OddTaxAmount)).toFixed(6);

      this.itemsSAP.splice(indexEdit, 1, edit);

      let sOrdTotal = 0;
      let sOrdTaxAmount = 0;

      this.itemsSAP.forEach((element) => {
        let unitPrice =
          element.OddUnitPrice == null || element.OddUnitPrice == ""
            ? 0
            : element.OddUnitPrice;

        // this.orderLocal.OrdSubTotal =  
          // this.orderLocal.OrdSubTotal +
          // parseFloat(unitPrice) *
          // (element.OddQuantity == null ? 1 : parseFloat(element.OddQuantity));
          sOrdTotal = parseFloat(sOrdTotal) + (element.OddAmount == null ? 0 : parseFloat(element.OddAmount));

        // this.orderLocal.OrdTaxAmount +=  
          // parseFloat(this.orderLocal.OrdTaxAmount) +
          // unitPrice *
          // parseFloat(element.OddQuantity) *
          // (element.OddTaxPercentage == null
          //   ? 0
          //   : parseFloat(element.OddTaxPercentage) / 100);
          sOrdTaxAmount = parseFloat(sOrdTaxAmount) + (element.OddTaxAmount == null ? 0 : parseFloat(element.OddTaxAmount)); 
      });
      
      
      this.orderLocal.OrdSubTotal = sOrdTotal - sOrdTaxAmount;
      this.orderLocal.OrdTaxAmount = sOrdTaxAmount ;

      this.orderLocal.OrdTotal = sOrdTotal;
      // this.orderLocal.OrdTotal =
      //   this.orderLocal.OrdSubTotal + this.orderLocal.OrdTaxAmount;
    },
    rowClick: function (item, row) {
      this.selectedSAP = [item];
      
      //this.selectedrow=item;
      //this.selectedrow.Line=this.selectedrow.Line+1;
      

    },
    saveAccount(item) {
      if (this.AcoID != null) {
        let edit = this.itemsSAP.find((x) => x.Line == item.Line);
        let indexEdit = this.itemsSAP.indexOf(item);
        edit.AcoName = this.AcoID.CsaDescription;
        edit.OddAccount = this.AcoID.CsaAccount;
        edit.OddAccountName = this.AcoID.CsaAccountName;
        this.itemsSAP.splice(indexEdit, 1, edit);
      }
    },
    changeTax(item) {
      if (this.tax != null)
        if (this.selectedTax) {
          item.OddTaxPercentage = parseFloat(this.tax.DedHelper).toFixed(4);
          item.TaxCode = this.tax.DedAbbreviation;
          item.OddTaxAmount =
            parseFloat(((item.OddUnitPrice == null
              ? 0
              : item.OddUnitPrice * item.OddQuantity) *
              item.OddTaxPercentage) /
            100).toFixed(6);
          this.changeItem(item);
          this.tax = null;
        } else {
          this.itemsSAP.forEach((e) => {
            e.OddTaxPercentage = parseFloat(this.tax.DedHelper).toFixed(4);
            e.TaxCode = this.tax.DedAbbreviation;
            e.OddTaxAmount =
              parseFloat(((e.OddUnitPrice == null ? 0 : e.OddUnitPrice * e.OddQuantity) *
                e.OddTaxPercentage) /
              100).toFixed(6);
            this.changeItem(e);
          });
          this.selectedTax = true;
        }
    },
    dblClick(item) { },
    save() {

      this.itemsDataTotal = [];

      if (this.orderLocal.OrdGoodService != null || this.orderLocal.OrdGoodService != undefined) {
        if (this.orderLocal.OrdGoodService == undefined || this.orderLocal.OrdGoodService == 0) {
          this.$fun.alert("Debe agregar el tipo de bien y servicio", "warning");
          return;
        }
      }


      if (this.Supplier == null) {
        this.$fun.alert("Falta proveedor", "warning");
        return;
      }
      if (this.orderLocal.TypeCurrency == null) {
        this.$fun.alert("Falta Tipo de Moneda", "warning");
        return;
      }
      if (this.Attach1 == null) {
        this.$fun.alert("Falta adjuntar archivo", "warning");
        return;
      }
      this.order.detail = [];
      for (let i = 0; i < this.itemsSAP.length; i++) {
        if (
          this.itemsSAP[i].OddUnitPrice == null ||
          this.itemsSAP[i].OddUnitPrice == 0
        ) {
          this.$fun.alert(
            "Precio inválido, " + this.itemsSAP[i].OddDescription,
            "warning"
          );
          return;
        }
        if (this.itemsSAP[i].CceID == null) {
          this.$fun.alert(
            "Falta Centro de Costo en, " + this.itemsSAP[i].OddDescription,
            "warning"
          );
          return;
        }
        if (this.itemsSAP[i].BslID == null) {
          this.$fun.alert(
            "Falta Linea de Negocio en, " + this.itemsSAP[i].OddDescription,
            "warning"
          );
          return;
        }
        if (this.itemsSAP[i].TypeCost == null) {
          this.$fun.alert(
            "Falta Tipo de Costo, " + this.itemsSAP[i].OddDescription,
            "warning"
          );
          return;
        }
        if (this.itemsSAP[i].TaxCode == null) {
          this.$fun.alert(
            "Agrege Impuesto a, " + this.itemsSAP[i].OddDescription,
            "warning"
          );
          return;
        }
        if (
          this.itemsSAP[i].OddUnitPrice == null ||
          this.itemsSAP[i].OddUnitPrice == 0
        ) {
          this.$fun.alert(
            "Precio inválido, " + this.itemsSAP[i].OddDescription,
            "warning"
          );
          return;
        }
        if (this.typeOrder == 3) {
          if (this.itemsSAP[i].OddAccount == null) {
            this.$fun.alert(
              "Agrege Concepto a, " + this.itemsSAP[i].OddDescription,
              "warning"
            );
            return;
          }
          this.itemsSAP[i].OddQuantity = 1;
        }
        this.itemsSAP[i].TypeCurrency = this.orderLocal.TypeCurrency;
        this.itemsSAP[i].SisCode = this.itemsSAP[i].SisID;
        this.itemsSAP[i].SitCode = this.itemsSAP[i].SitID;
        this.itemsSAP[i].UsrUpdateID = this.$fun.getUserID();
      }
      //this.orderLocal.Detail = this.itemsSAP;
      this.itemsDataTotal.push(...this.itemsSAP);
      this.itemsDataTotal.push(...this.itemsRemove);
      this.orderLocal.PytID = this.PytID;
      this.orderLocal.SupCode = this.Supplier.SupID;
      this.orderLocal.SupName = this.Supplier.SupName;
      this.orderLocal.OrdAttach1 =
        this.Attach1 != null ? this.Attach1.name : "";
      this.orderLocal.OrdAttach2 =
        this.Attach2 != null ? this.Attach2.name : "";
      this.orderLocal.OrdAttach3 =
        this.Attach3 != null ? this.Attach3.name : "";
      this.orderLocal.OrdAttach4 =
        this.Attach4 != null ? this.Attach4.name : "";
      this.orderLocal.CardName = this.Supplier.SupName;
      this.orderLocal.OrdDate = this.dateOrder;
      this.orderLocal.UsrUpdateID = this.$fun.getUserID();

      
      const attributesToCompare = ['DerDescription','TypeCostName','BslName','PrjName'];
      let validate = this.findRepeatedObjects(this.itemsDataTotal, attributesToCompare);
      // if(validate)
      // {
      //   this.$fun.alert(
      //       "Existe detalles que se repiten. ",
      //       "warning"
      //     );
      //     return;
      // }
      //console.log(this.orderLocal);
      this.$fun.alert("Seguro de guardar?", "question").then((r) => {
        this.$emit("clearItems");
        if (r.value) {

          //this.orderLocal.Detail.push(...this.itemsRemove);
          //detailsOrderLocal.push(this.itemsRemove);
          

          this.orderLocal.Detail = this.itemsDataTotal;
          //   this.orderLocal.OrdSubTotal=parseFloat(this.orderLocal.OrdSubTotal);
          //validamos que no se repita la peticion
          if (this.formSubmitted) return;
          this.formSubmitted = true;

          _sOrderPurchase
            .save(this.orderLocal, this.$fun.getUserID(), 0)
            .then((r) => {
              if (r.data.length > 1) {
                this.formSubmitted = false;
                this.circuits = r.data;
                this.dialogCircuit = true;
              } else {
                this.$fun.alert("Guardado Correctamente", "success");
                this.$emit("close");
                this.$emit("onCloseClear");
                this.$emit("onClose");
                if (this.typeOrder == 3) {
                  this.$emit("clearItemsCard");
                } else if(this.typeOrder == 1){
                  this.$emit("clearItemsCard");
                }
              }
            });
        }
      });
    },
    filterAttributes(obj, attrs)
    {
      const filteredObj = {};
      attrs.forEach(attr => {
        if(obj.hasOwnProperty(attr)){
          filteredObj[attr] = obj[attr];
        }
      })
      return filteredObj;
    },
    findRepeatedObjects(items, attrs)
    {
      let verfifica = false;
      for(let i = 0; i<items.length;i++)
      {
        for(let j = i + 1; j< items.length; j++)
        {
          const filteredObj1 = this.filterAttributes(items[i], attrs);
          const filteredObj2 = this.filterAttributes(items[j], attrs);
          const commontCount = this.countCommonAttributes(filteredObj1, filteredObj2, attrs);
          if(commontCount >= 4)
          {
            verfifica = true;
          }
        }
      }
      return verfifica;
    },
    countCommonAttributes(obj1, obj2, attrs)
    {
      let count = 0;
      attrs.forEach(attr => {
        if(obj1[attr] === obj2[attr])
        {
          count++;    
        }
      });
      return count;
    },
  },
  
  created() {
    _sGenParam.search({ PrmName: "urlWhatsApp1" },this.$fun.getUserID(),)
    .then(resp => {
        this.urlWhatsApp1 = resp.data.PrmValue;
    });

    _sGenParam.search({ PrmName: "urlWhatsApp2" },this.$fun.getUserID(),)
    .then(resp => {
        this.urlWhatsApp2 = resp.data.PrmValue;
    });

    _sGenParam.search({ PrmName: "numberWhatsAppAPI" },this.$fun.getUserID(),)
    .then(resp => {
        this.numberWhatsAppAPI = resp.data.PrmValue;
    });

    _sGenParam.search({ PrmName: "tokenWhatsAppAPI" },this.$fun.getUserID(),)
    .then(resp => {
        this.tokenWhatsAppAPI = resp.data.PrmValue;
    });

    this.loading= true;
    this.getGoodServiceList();
    if (this.orderLocal.OrdID == null) {
      this.orderLocal.ClassOperationPurchase = this.typeOrder == 1 ? 2 : 4;
    }
    this.orderLocal.ManagerPurchase = this.$fun.getUserSAPInfo().UssWorkerID;
    if (this.value != null) {
      _sOrderPurchase
        .byID({ OrdID: this.value }, this.$fun.getUserID())
        .then((r) => {
          
          this.orderLocal = r.data;
          this.PytID = this.orderLocal.PytID;
          let sup = {
            SupID: this.orderLocal.SupCode,
            SupName: this.orderLocal.SupName,
          };
          this.dateOrder = this.orderLocal.OrdDate;
          if (this.orderLocal.TypeOrder == 3)
            this.headersSAP.splice(2, 0, {
              text: "Cuenta Mayor",
              value: "AcoName",
              width: 220,
              sortable: false,
            });
          else {
            this.headersSAP.splice(
              2,
              0,
              { text: "Cant", value: "DerQuantity", sortable: false },
              { text: "Atendido", value: "OddQuantity", sortable: false },
              { text: "Und", value: "UnitMeasurement", sortable: false }
            );
          }
          this.Attach1 = { name: r.data.OrdAttach1 };
          this.Attach2 = { name: r.data.OrdAttach2 };
          this.Attach3 = { name: r.data.OrdAttach3 };
          this.Attach4 = { name: r.data.OrdAttach4 };
          this.Supplier = sup;
          this.OrdStatus = r.data.OrdStatus;
          this.itemsSAP = r.data.Detail;
          this.loading=false;
          this.loadingCard = false;
          this.orderLocal.Transaction.OrdStatus = this.orderLocal.OrdStatus;
          this.transactionApproved = this.orderLocal.Transaction;
          if (this.orderLocal.Transaction != null)
            if (this.orderLocal.Transaction.UsersApproved.length > 0) {
              if (
                this.orderLocal.Transaction.UsersApproved.find(
                  (x) => x.UsrID == this.$fun.getUserID()
                )
              ) {
                if (this.orderLocal.OrdStatus == 4) {
                  this.optionApproved = true;
                } else this.optionApproved = false;
              }
            }
        });
    } else {
      this.orderLocal.TypeOrder = this.typeOrder;
      this.itemsSAP = this.order.detail;
      this.loading=false;
      this.loadingCard = false;
      if (this.itemsSAP)
        for (let i = 0; i < this.itemsSAP.length; i++)
          this.itemsSAP[i].Line = i + 1;
      if (this.typeOrder == 3)
        this.headersSAP.splice(2, 0, {
          text: "Cuenta Mayor",
          value: "AcoName",
          width: 220,
          sortable: false,
        });
      else if ((this.typeOrder = 1))
        this.headersSAP.splice(
          2,
          0,
          { text: "N° Articulo", value: "ArtCode", sortable: false },
          { text: "Cant", value: "DerQuantity", sortable: false },
          { text: "Atendido", value: "OddQuantity", sortable: false },
          { text: "Und", value: "UnitMeasurement", sortable: false }
        );

     
    }

    _sRequirement.ConceptService(this.$fun.getUserID()).then((r) => {
      this.itemsConcept = r.data;
      if (this.itemsConcept.length > 0)
        this.ConName = this.itemsConcept[0].ConName;
    });

    this.changeTotal();
  },
  watch: {
    ConName() {
      _sRequirement
        .AccountConceptService(this.ConName, this.$fun.getUserID())
        .then((ar) => {
          this.itemsAccount = ar.data;
          if (this.itemsAccount.length > 0) this.AcoID = this.itemsAccount[0];
        });
    },
    Supplier() {
      if (this.Supplier != null)
        if (this.Supplier.SupID != null) {
          this.SupID = this.Supplier.SupID;

          if (!this.value) {
            
            if (this.Supplier.PytID) {
              this.PytID = this.Supplier.PytID;

            }
          }
        }
    },

    "orderLocal.OrdStatus"(old, nuevo) {
      
    }
  },

  mounted() {
   this.loadingCard = true;
  },
};
</script>
