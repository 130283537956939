<template>
  <div style="position: relative; width: 100%; height: 100%;">
    <canvas
      ref="signatureCanvas"
      @touchstart="startDrawing"
      @touchmove="draw"
      @touchend="stopDrawing"
      @mousedown="startDrawing"
      @mousemove="draw"
      @mouseup="stopDrawing"
      @mouseleave="stopDrawing"
      style="border: 1px solid #000; touch-action: none; border-radius: 5px; width: 100%; height: 100%;"
    ></canvas>
    <button style="background-color: #DC3545; position: absolute; top: 10px; right: 10px; border-radius: 100%; padding: 4px;" @click="clearCanvas">
      <v-icon style="color: #fff;">mdi-delete</v-icon>
    </button>
    <button style="background-color: #28A745; position: absolute; bottom: 15px; right: 10px; border-radius: 100%; padding: 4px;" @click="saveSignature">
      <v-icon style="color: #fff;">mdi-content-save</v-icon>
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isDrawing: false,
      ctx: null,
    };
  },
  mounted() {
    this.setupCanvas();
    window.addEventListener("resize", this.resizeCanvas);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeCanvas);
  },
  methods: {
    setupCanvas() {
      const canvas = this.$refs.signatureCanvas;
      this.ctx = canvas.getContext("2d");
      this.resizeCanvas();
      this.ctx.lineWidth = 2;
      this.ctx.strokeStyle = "#000";
    },
    resizeCanvas() {
      const canvas = this.$refs.signatureCanvas;
      const container = canvas.parentElement;
      canvas.width = container.clientWidth;
      canvas.height = container.clientHeight;
    },
    startDrawing(event) {
      this.isDrawing = true;
      const { offsetX, offsetY } = this.getEventCoordinates(event);
      this.ctx.beginPath();
      this.ctx.moveTo(offsetX, offsetY);
    },
    draw(event) {
      if (!this.isDrawing) return;
      const { offsetX, offsetY } = this.getEventCoordinates(event);
      this.ctx.lineTo(offsetX, offsetY);
      this.ctx.stroke();
    },
    stopDrawing() {
      this.isDrawing = false;
      this.ctx.closePath();
    },
    clearCanvas() {
      const canvas = this.$refs.signatureCanvas;
      this.ctx.clearRect(0, 0, canvas.width, canvas.height);
    },
    saveSignature() {
      const canvas = this.$refs.signatureCanvas;
      const ctx = canvas.getContext("2d");
      const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;

      let top = canvas.height, bottom = 0, left = canvas.width, right = 0;

      for (let y = 0; y < canvas.height; y++) {
        for (let x = 0; x < canvas.width; x++) {
          const alpha = imgData[(y * canvas.width + x) * 4 + 3]; // Obtener canal alfa
          if (alpha > 0) { // Si hay un trazo
            if (x < left) left = x;
            if (x > right) right = x;
            if (y < top) top = y;
            if (y > bottom) bottom = y;
          }
        }
      }

      if (right - left <= 0 || bottom - top <= 0) {
        // No hay firma, devolver un valor vacío o manejar el caso
        this.$emit("save-signature", null);
        return;
      }

      // Crear un nuevo canvas con el tamaño exacto de la firma
      const croppedCanvas = document.createElement("canvas");
      const croppedCtx = croppedCanvas.getContext("2d");

      const width = right - left;
      const height = bottom - top;

      croppedCanvas.width = width;
      croppedCanvas.height = height;

      // Copiar solo la parte de la firma
      croppedCtx.drawImage(canvas, left, top, width, height, 0, 0, width, height);

      // Convertir a imagen
      const image = croppedCanvas.toDataURL("image/png");
      this.$emit("save-signature", image);
    },
    getEventCoordinates(event) {
      const canvas = this.$refs.signatureCanvas;
      const rect = canvas.getBoundingClientRect();
      let offsetX, offsetY;

      if (event.touches && event.touches.length > 0) {
        const touch = event.touches[0];
        offsetX = touch.clientX - rect.left;
        offsetY = touch.clientY - rect.top;
      } else {
        offsetX = event.clientX - rect.left;
        offsetY = event.clientY - rect.top;
      }

      return { offsetX, offsetY };
    },
  },
};
</script>

<style scoped>
canvas {
  background-color: #f9f9f9;
}
</style>