let children = [];
children.push({
    path: "/logistica/ordenes",
    name: "LGS_ORDER",
    component: () =>
        import ("../views/Logistics/OrderPurchase/Orders.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Orden de Compra", href: "/logistica/ordenes" },
        ],
    },
});
children.push({
    path: "/logistica/GestorGrupoArticulo",
    name: "LGSGESGRPART",
    component: () =>
        import ("../views/Logistics/ItemsGroupArticle.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Gestor por grupo articulo", href: "/logistica/GestorGrupoArticulo" },
        ],
    },
});
children.push({
    path: "/logistica/consultas/ubicaciones",
    name: "LGS_LOCATIONS",
    component: () =>
        import ("../views/Logistics/ConsultingSAP/Locations.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Consultas SAP", disabled: true },
            { text: "Ubicaciones", href: "/logistica/consultas/ubicaciones" },
        ],
    },
});
children.push({
    path: "/logistica/Requerimientos/Servicios",
    name: "LGS_SERVICE_REQUIREMENT",
    component: () =>
        import ("../views/Logistics/Requirement/RequirementServices.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Requerimientos", disabled: true },
            { text: "Servicios", href: "/logistica/Requerimientos/Servicios" },
        ],
    },
});
children.push({
    path: "/logistica/Requerimientos/Compras",
    name: "LGS_PURCHASE_REQUIREMENT",
    component: () =>
        import ("../views/Logistics/Requirement/RequirementPurchase.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Requerimientos", disabled: true },
            { text: "Compras", href: "/logistica/Requerimientos/Compras" },
        ],
    },
});
children.push({
    path: "/logistica/Requerimientos/Pedido",
    name: "LGS_WAREHOUSE_REQUIREMENT",
    component: () =>
        import ("../views/Logistics/Requirement/RequirementWareHouse.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Requerimientos", disabled: true },
            { text: "Pedido", href: "/logistica/Requerimientos/Pedido" },
        ],
    },
});
children.push({
    path: "/logistica/orderTraceability",
    name: "LGS_ORDERTRACEABILITY",
    component: () =>
        import ("../views/Logistics/OrderTraceability/OrderTraceability.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Trazabilidad Pedido", href: "/logistica/orderTraceability" },
        ],
    },
});
children.push({
    path: "/logistica/Requerimientos_Servicios/Atencion",
    name: "LGS_SERVICE_ATTENTION",
    component: () =>
        import ("../views/Logistics/Requirement/ServiceAttention.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Requerimientos", disabled: true },
            { text: "Servicios", href: "/logistica/Requerimientos_Servicios/Atencion" },
        ],
    },
});
children.push({
    path: "/logistica/consultas/ubicaciones",
    name: "LGS_REQUIREMENT_ARTICLE",
    component: () =>
        import ("../views/Logistics/ConsultingSAP/Locations.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Consultas SAP", disabled: true },
            { text: "Ubicaciones", href: "/logistica/consultas/ubicaciones" },
        ],
    },
});
children.push({
    path: "/logistica/consultas/ubicaciones",
    name: "LGS_REQUIREMENT_ORDER",
    component: () =>
        import ("../views/Logistics/ConsultingSAP/Locations.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Consultas SAP", disabled: true },
            { text: "Ubicaciones", href: "/logistica/consultas/ubicaciones" },
        ],
    },
});
children.push({
    path: "/logistica/ordenpedido/atencionpedido",
    name: "LGS_ORDER_STORE_ATTENTION",
    component: () =>
        import ("../views/Logistics/OrderStore/OrderStoreAttention.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Ordenes Pedido", disabled: true },
            { text: "Atencion", href: "/logistica/ordenpedido/atencionpedido" },
        ],
    },
});
children.push({
    path: "/logistica/permisos",
    name: "LGS_PERMIT",
    component: () =>
        import ("../views/Logistics/Permits/Permit.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Permisos", href: "/logistica/permisos" },
        ],
    },
});
children.push({
    path: "/logistica/ordenpedido/solicitudpedido",
    name: "LGS_ORDER_STORE_REQUEST",
    component: () =>
        import ("../views/Logistics/OrderStore/OrderStoreRequest.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Ordenes Pedido", disabled: true },
            { text: "Solicitud de Pedido", href: "/logistica/ordenpedido/solicitudpedido" },
        ],
    },
});
children.push({
    path: "/logistica/ordenpedido/atencioncompra",
    name: "LGS_PURCHASE_ORDER_ATTENTION",
    component: () =>
        import ("../views/Logistics/OrderStore/OrderPurcharseAttention.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Ordenes Pedido", disabled: true },
            { text: "Atencion de Compra", href: "/logistica/ordenpedido/atencioncompra" },
        ],
    },
});
children.push({
    path: "/logistica/cotizaciones",
    name: "LGS_QUOTE",
    component: () =>
        import ("../views/Logistics/Quote/Quote.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Cotizaciones", href: "/logistica/cotizaciones" },
        ],
    },
});
children.push({
    path: "/logistica/articulos/asignacion",
    name: "LGS_VARTICLES",
    component: () =>
        import ("../views/Logistics/Articles/VArticles.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Articulos", href: "/logistica/articulos/asignacion" },
        ],
    },
});
children.push({
    path: "/logistica/MantenimientoArticulos",
    name: "LGS_MAINTENANCEVARTICLES",
    component: () =>
        import ("../views/Logistics/Articles/MaintenanceArticles.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Articulos", href: "/logistica/MantenimientoArticulos" },
        ],
    },
});

children.push({
    path: "/logistica/liberacionarticulo",
    name: "LGS_ARTICLE_FREE",
    component: () =>
        import ("../views/Logistics/Articles/ArticleFree.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Liberación", href: "/logistica/liberacionarticulo" },
        ],
    },
});

children.push({
    path: "/logistica/guiaremision",
    name: "LGS_REFERRAL_GUIDE",
    component: () =>
        import ("../views/Logistics/ReferralGuide/LgsReferralGuide.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Guías de Remisión", disabled: true },
            { text: "Emitir Guía", href: "/logistica/guiaremision" },
        ],
    },
});
children.push({
    path: "/logistica/consultas/requermientoFlujograma",
    name: "REQFLUJOGR",
    component: () =>
        import ("../views/Logistics/Requirement/RequirementFlowChart.vue"), 
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Consultas SAP", disabled: true },
            { text: "requermientoFlujograma", href: "/logistica/consultas/requermientoFlujograma" },
        ],
    },
});
export { children }