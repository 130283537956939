export default {
    // /----manejo de manera local//
    // BASEURL: "http://localhost:5005/api", //5005
    // URL: "http://localhost:5005/", //5005
    ///----entorno de pruebas -- LOCAL demo//
    // BASEURL: "https://172.16.1.9:8189/api",
    // URL: "https://172.16.1.9:8189/",
    ///----entorno de pruebas -- LOCAL demo cambiado//
    // BASEURL: "http://172.16.1.9:8181/api",
    // URL: "http://172.16.1.9:8181/",
    ///----entorno de servicio web//
    BASEURL: "https://190.116.178.179:8189/api",
    URL: "https://190.116.178.179:8189/",
    ///----entorno PRODUCTIVO//
    // BASEURL: "http://172.16.1.9:8184/api",
    // URL: "http://172.16.1.9:8184/",
     ///----entorno de servicio web BITEL//
    // BASEURL: "https://181.176.215.139:8189/api",
    // URL: "https://181.176.215.139:8189/",
    ID_AREA_MANTENIMIENTO: 24, //32 sistemasdss
    _cKey : "@cl4V3*/",
    _cVec : "@cl4V3*/",
    FormatDateDB: "YYYY-MM-DD",
    FormatDateTimeDB: "YYYY-MM-DD HH:mm",
    FormatDateTimeShortDB: "YYYY-MM-DD HH:mm:ss",
    FormaTimeDB: "YYYY-MM-DD HH:mm",
    FormatDateView: "DD/MM/YYYY",
    FormatDateTimeView: "DD/MM/YYYY HH:mm",
    FormatDateTimeShortView: "DD/MM/YYYY HH:mm:ss",
    FormatDateTimeShort12HView: "DD/MM/YYYY hh:mm:ss A",
    FormatTimeDB: " HH:mm",
    FormatTimeView: " HH:mm",
    FormatTimeShortView: "HH:mm:ss",
    FormatDateMask: "##/##/####",
    FormatDateTimeMask: "##/##/#### ##:##",
    TypeMeal: 1065,
    TypeLevel: {
        Administrador: 1,
        Asistente: 2,
        Jefatura: 3,
        Gerencia: 4,
        PorPlanilla: 5,
        PorArea: 6,
    },
    SreStatus: {
        EnCreacion: 1,
        EnAprobacion: 2,
        PendienteRRHH: 3,
        Observado: 4,
        Aprobado: 5,
        Atendido: 6,
        Anulado: 7,
    },
    SraStatus: {
        NoAsignado: 1,
        Pendiente: 2,
        Rechazado: 3,
        Aprobado: 4,
    },
    TypeStaffRequirement: {
        Simple: 1,
        Campaña: 2,
        Referencia: 3,
    },
    PosStatus: {
        Registrado: 1,
        Seleccionado: 2,
        Contratado: 3,
    },
    TypeDocument: {
        RequerimientoPersonal: 1,
        FichaMedica: 2,
    },
    TypeQualification: {
        Apto: 1,
        AptoCR: 2,
        NoApto: 3,
        Observado: 4,
        Pendiente: 5,
    },
    TypeTurn: {
        Diurno: 1,
        Nocturno: 2,
        Indistinto: 3,
    },
    MevStatus: {
        EnCreacion: 1,
        EnAprobacion: 2,
        Aprobado: 3,
        Anulado: 4,
        Observada: 5,
        Pendiente: 9,
    },
    CrdStatus: {
        Registrado: 1,
        Asignado: 2,
        Liberado: 3,
        Anulado: 4,
    },
    TypeCard: {
        Comedor: 4,
        Visita: 5,
        Trabajador: 6,
        Especial: 7,
    },
    SecStatus: {
        Activo: 1,
        Baja: 2,
    },
    TypePerson: {
        PersonaNatural: 1, // Todas las personas naturales
        PersonaJuridica: 2,
        Trabajadores: 3,
    },
    TypePersonDocument: {
        DNI: 1, // Todas las personas naturales
        CE: 2,
        RUC: 3,
        PAS: 4,
        PNAC: 5,
    },
    TypeRelation: { Child: 1, Spouse: 2 },
    DocumentNumberGeneric: {
        Company: "20329725431",
    },

    Months: [
        { value: 1, text: "Enero" },
        { value: 2, text: "Febrero" },
        { value: 3, text: "Marzo" },
        { value: 4, text: "Abril" },
        { value: 5, text: "Mayo" },
        { value: 6, text: "Junio" },
        { value: 7, text: "Julio" },
        { value: 8, text: "Agosto" },
        { value: 9, text: "Septiembre" },
        { value: 10, text: "Octubre" },
        { value: 11, text: "Noviembre" },
        { value: 12, text: "Diciembre" },
    ],
    TypeFile: {
        EXCEL: { ContentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", Extension: "xlsx" },
        PDF: { ContentType: "application/pdf", Extension: "pdf" },
        ZIP: { ContentType: "application/pdf", Extension: "zip" },
    },
};