<template>
    <div style="padding-top:-2px;margin-top:-2px;">
      <v-label><b>{{label}}</b></v-label>
      <v-select
        :return-object="returnObject"
        :item-value="itemvalue"
        :item-text="itemtext"
        :disabled="disabled"
        :readonly="readonly"
        :items="items"
        :clearable="clearable"
        dense
        outlined
        v-model="localValue" 
        @input="updateValue"
        hide-details="false"
      />
    </div>
  </template>
  <!-- Usa v-model aquí -->
<script>
  import CreationPeriods from '../../services/HumanResource/CreationPeriods';
  export default {
    props: {
      value: null,
      year: {
        type: Number,
        default: 0,
      },
      type: {
        type: Number,
        default: 0,
      },
      label: {
        type: String,
        default: "Periodo",
      },
      itemtext: {
        type: String,
        default: "PdsName",
      },
      itemvalue: {
        type: String,
        default: "PdsID",
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      returnObject: {
        type: Boolean,
        default: false,
      },
      clearable: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        localValue: this.value,
        items: [],
      };
    },
    watch: {
      value(newValue) {
        this.localValue = newValue;
      },
      year(newValue) {
        this.load();
      },
      type(newValue) {
        this.load();
      }
    },
    mounted() {
      this.load();
    },
    methods: {
      updateValue(value) {
        this.localValue = value;
        this.$emit("input", value);
      },
      load() {
        CreationPeriods.list(this.type, this.year, this.$fun.getUserID())
            .then((r) => {
                this.items = r.data;
                this.localValue = this.value;
            })
      },
    },
  };
  </script>
  