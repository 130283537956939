import Service from "../Service";
const resource = "GenUploadFiles/";

export default {
    async upload(file, nameFile, genParameter, overwrite = false) {
        const formData = new FormData();

        if (typeof file === "string" && file.startsWith("data:")) {
            // Es base64, convertimos a Blob
            const { blob, extension } = dataURLtoBlob(file);
            const finalFileName = nameFile.includes(".") ? nameFile : `${nameFile}.${extension}`;
            formData.append("file", blob, finalFileName);
        } else {
            // Es un archivo normal
            formData.append("file", file);
        }

        formData.append("nameFile", nameFile);
        formData.append("genParameter", genParameter);
        formData.append("overwrite", overwrite.toString()); // Enviamos el valor como string

        return Service.post(resource + "upload", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            params: { },
        });
    },
    
    async getFile(genParameter, nameFile, isImg = false) {
        if(!genParameter || !nameFile) return null;
        return Service.get(resource + "getFile", {
            params: { genParameter, nameFile, isImg },
            responseType: isImg ? "json" : "blob",
        }).then(response => {
            if (isImg) {
                return response.data.base64;
            } else {
                const contentType = response.headers["content-type"];
                const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
                const a = document.createElement("a");
                a.href = fileURL;
                a.download = nameFile;
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(fileURL);
            }
        });
    },

    async getCountFiles(genParameter) {
        if (!genParameter) return null;
    
        return Service.get(resource + "getCountFiles", {
            params: { genParameter },
            responseType: "json",
        }).then(response => {
            return response;
        }).catch(error => {
            console.error("Error obteniendo archivos:", error);
            return null;
        });
    }    
};

function dataURLtoBlob(dataURL) {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    const extension = mime.split("/")[1];
    return { blob: new Blob([u8arr], { type: mime }), extension };
}
