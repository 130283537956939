<template>
    <!-- <div style="padding-top:-2px;margin-top:-2px;">
      <v-label>
        <b>{{ label }} </b>
        </v-label>  
    </div> -->
    <div > 
        <v-label>
        <b>{{ label }} </b>
        </v-label> 
        <v-text-field
            ref="field"
            v-model="formattedValue"
            outlined
            dense
            hide-details="auto"
            :readonly="readonly"
            @input="updateValue"
            :disabled="disabled"
        />
    </div>
</template>

<script>
import AutoNumeric from "autonumeric";

export default {
  name: "SMoneyInput",
  props: {
    value: { type: [String, Number], default: "" }, // Compatible con número y string
    label: { type: String, default: '' },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {
        autoNumericInstance: null
    };
  },
  computed: {
    formattedValue: {
      get() {
        return this.value?.toString() || "";
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    updateValue(event) {
      const rawValue = this.autoNumericInstance?.getNumber() || event;
      this.$emit("input", rawValue);
    }
  },
  mounted() {
    this.autoNumericInstance = new AutoNumeric(this.$refs.field.$refs.input, {
      decimalCharacter: ".",
      digitGroupSeparator: ",",
      minimumValue: "0",
      modifyValueOnWheel: false
    });
   //new AutoNumeric(this.$refs.field.$refs.input);
  },
  
  
};
</script>