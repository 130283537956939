<template>

  <v-card dense>    
    
    <v-card-title class="text-h7" style="font-weight: bold;">
      <v-row>
        <v-col>
          {{ title == 0 ? 'EDITAR' : 'NUEVA' }} CUENTA BANCARIA 
        </v-col>
        <div style="text-align: end;" v-if="editedIndex!=-1">                              
            <v-btn
              class="mx-2"
              fab
              dark
              small
              @click="cancelItemDetail(0)"
              color="error"
            >
              <v-icon dark>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        <div style="text-align: end;">
            
          <v-btn
            class="mx-2"
            fab
            dark
            small
            @click="addItemDetail()"
            color="success"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </div>
      </v-row>
    </v-card-title>
    <v-card-text> 
      <v-row>
        <!-- Contenedor para SCTR Pensión -->
        <v-col class="s-col-form" sm="12" md="12" lg="12">      
            <v-row>
              <v-col class="s-col-form" sm="4" md="4" lg="4">
                <s-select-definition
                  :def="1487"
                  label="Tipo de Cuenta"
                  v-model="item.TypePay"
                  ref="TypePayRef"
                  :add="$fun.isAdmin()"
                />
              </v-col>
              <v-col class="s-col-form" sm="4" md="4" lg="4">
                <s-select-definition
                  :def="1129"
                  label="Moneda"
                  v-model="item.TypeMoney"
                  ref="TypeMoneyRef"
                  :add="$fun.isAdmin()"
                />
              </v-col>
              <v-col class="s-col-form" sm="4" md="4" lg="4">
                <s-select-definition
                  :def="1316"
                  label="Entidad"
                  v-model="item.TypeEntityBank"
                  ref="TypeEntityBankRef"
                  :add="$fun.isAdmin()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="s-col-form" sm="4" md="4" lg="4">
                <s-text
                label="N° Cuenta "
                v-model="item.BatAccountNumber"
                number
                :maxlength="maxlength"
                :showCounter="true"
                @keyupEnter="addItemDetail()"               
                :rules="[ 
                    //value => !!value || 'aaa',
                    value =>  (value && value.length >=  maxlength )  || 'El N° de Cuenta debe de tener  '+(maxlength)+' digitos',
                  ]"
                  ref="BatAccountNumberRef"
                >

                </s-text>
              </v-col>
              <v-col class="s-col-form" sm="4" md="4" lg="4">
                <s-text 
                label="N° de Cuenta Interbancaria (CCI)"
                v-model="item.BatCCI"                
                number
                :maxlength="20"
                :counter="20"
                @keyupEnter="addItemDetail()"
                :showCounter="true"
                :rules="[ 
                    //value => !!value || '',
                    value =>  (value && value.length >= 20)  || 'EL CCI debe de tener 20 digitos',
                  ]"
                ref="BatCCIRef"
                >

                </s-text>
             
              </v-col>
              <v-col class="s-col-form" sm="4" md="4" lg="4">
                <s-select-definition :def="1508" label="Situación de Cuenta" v-model="item.BatStatus" ref="BatStatusRef" :add="$fun.isAdmin()" />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="s-col-form " sm="3" md="3" lg="12">
                <s-switch
                  label="Generar N° de Cuenta Correlativo"
                  v-model="NumCorrelative"
                ></s-switch>
              </v-col>
              <v-col class="s-col-form" cols="12"
              sm="6">
              </v-col>
              <v-col class="s-col-form" sm="6" md="6" lg="6">
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <template>
                  <v-data-table
                    :headers="bankAccountHeaders"
                    class="elevation-1"
                    :items="bankAccountDetail"
                    :sort-by="['SecStatus','TypePay','BatID']" 
                    :sort-desc="[true]"
                  >
                    <template v-slot:[`item.BatIsDefault`]="{ item }">                    
                      {{ item.BatIsDefault == 1 ? 'Cuenta Principal' : 'Cuenta Opcional' }}                     
                    </template>

                    <template v-slot:[`item.SecStatus`]="{ item }">
                      <v-chip 
                        small 
                        :color="item.SecStatus ? 'success' : ''" 
                        :text-color="item.SecStatus ? 'white' : ''" 
                        >
                        {{ item.SecStatus  ? 'Activo' : 'Inactivo' }}
                      </v-chip>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon small class="mr-2" @click="editItem(item, 0)">
                        mdi-pencil
                      </v-icon>
                      <v-icon small @click="deleteItem(item, 0)">
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
                </template>
              </v-col>
            </v-row>
         
        </v-col>
      </v-row>
     </v-card-text> 
   
  </v-card> 
</template>

<script>
import SText from "../SText.vue";
import _sWorkertService from '@/services/HumanResource/WorkerService.js';

export default {
  name: "sDatosBancarios",
  components: { SText },
  props: {
    visibleTitle: {
      type: Boolean,
      default: true,
    },
    full: {
      type: Boolean,
      default: false,
    },
    typeperson: {
      type: Number,
      default: 1,
    },
    addTypeSex: {
      type: Boolean,
      default: false,
    },
    addBirthDate: {
      type: Boolean,
      default: false,
    },
    addDriver: {
      type: Boolean,
      default: false,
    },
    datawork: { type: Object },
  },
  data() {
    return {
      dataWor: {},
      editing: false,
      item: {
        TypePay: 1,
        TypeAccount: 3,
        TypeMoney: 1,
        TypeEntityBank: 2,
        BatAccountNumber: "",
        BatCCI:"",
        BatIsDefault: 1,
        BatStatus:1,
        UsrCreateID: this.$fun.getUserID()
      },
      editedIndex: -1,
      itemDefault: {
        TypePay: 1,
        TypeAccount: 3,
        TypeMoney: 1,
        TypeEntityBank: 2,
        BatAccountNumber: "",
        BatCCI:"",
        BatIsDefault: 1,
        BatStatus: 1,
        UsrCreateID: this.$fun.getUserID()
      },
      title: 1,
      selectedCenterCost: 0,
      NumCorrelative: null,
      selectedBusinessLine: null,
      bankAccountHeaders: [
        { text: "ID"                          , value: "BatID"                  , sortable: false, width: 20  },
        { text: "Tipo de Cuenta"              , value: "TypePayName"            , sortable: false, width: 50  },
        { text: "Moneda"                      , value: "TypeMoneyName"          , sortable: false, width: 20  },
        { text: "Entidad Bancaria"            , value: "TypeEntityBankName"     , sortable: false, width: 30  },
        { text: "N° de Cuenta"                , value: "BatAccountNumber"       , sortable: false, width: 100 },
        { text: "N° de Cuenta Interbancaria"  , value: "BatCCI"                 , sortable: false, width: 100 },
        { text: "Categoria"                   , value: "BatIsDefault"           , sortable: false, width: 80  },
        { text: "Situación de la Cuenta"      , value: "BatStatusName"          , sortable: false, width: 80  },
        { text: "Estado"                      , value: "SecStatus"              , sortable: false, width: 30  },
        { text: "Acciones"                    , value: "actions"                , sortable: false, width: 30  },
      ],
      bankAccountDetail: [],
      bankAccountDetailDelete: [],
      menu3: false,
      config: {},
      dialog: false,
      maxlength: 14,
    };
  },
  methods: {
    initialize() {

      //this.bankAccountDetail = this.datawork.BankAccountsCE;

      this.datawork.BankAccountsCE.forEach(element => {
        element.UsrUpdateID = this.$fun.getUserID(),
        this.bankAccountDetail.push(element);
      });


     // console.log("Cuentas Banccarias", this.bankAccountDetail);
      
    },
    deleteItem(item) {
      //console.log("deleteItem", item);
      
      if(item.BatID!=0){
        item.SecStatus = 0;
        item.BatIsDefault=2;
        item.UsrUpdateID = this.$fun.getUserID();
        //this.bankAccountDetailDelete.push(item);
        //console.log("items", this.bankAccountDetailDelete);
      }else{
        this.editedIndex = this.bankAccountDetail.indexOf(item);
        this.bankAccountDetail.splice(this.editedIndex, 1);
      }
      this.editedIndex = -1;
      //console.log("Items",this.bankAccountDetail);
    },

    editItem(item) {
      //console.log("Direcciones", item);
      this.isInitializing = true;
      this.editedIndex = this.bankAccountDetail.indexOf(item);
    
      this.item = {
        ...item
      };
      this.item.BatAccountNumber= item.BatAccountNumber;
      this.title = 0;     
      //console.log("Edit", this.editedIndex);
    },

    cancelItemDetail(){
      this.editedIndex = -1;   
      this.title= -1 ;
      this.item = {
        ...this.itemDefault
      }
      this.clearDetail();
    },

    addItemDetail() {
      //console.log("Editindex", this.editedIndex);
      //console.log("cuenta", this.item);
      
      if(!this.validateData()) return;

      let bankAccount = {
        BatID: this.item.BatID || 0,
        WkrID: this.datawork.WkrID,
        TypePay: this.item.TypePay,
        TypePayName: this.$refs.TypePayRef.items.find(
          (x) => x.DedValue === this.item.TypePay
        ).DedDescription,
        TypeMoney: this.item.TypeMoney,
        TypeMoneyName: this.$refs.TypeMoneyRef.items.find(
          (x) => x.DedValue === this.item.TypeMoney
        ).DedDescription,
        TypeEntityBank: this.item.TypeEntityBank,
        TypeEntityBankName: this.$refs.TypeEntityBankRef.items.find(
          (x) => x.DedValue === this.item.TypeEntityBank
        ).DedDescription,
        BatAccountNumber: this.item.BatAccountNumber.replace(/\s+/g, ""),
        BatCCI: this.item.BatCCI.replace(/\s+/g, ""),
        BatIsDefault: this.item.TypePay == 1 ? 1 : 2, //this.item.BatIsDefault,
        BatStatus: this.item.BatStatus,
        BatStatusName: this.$refs.BatStatusRef.items.find(
          (x) => x.DedValue === this.item.BatStatus
        ).DedDescription,
        SecStatus: 1,
        UsrCreateID: this.item.BatID ? this.item.UsrCreateID :this.$fun.getUserID(),
        UsrUpdateID: this.item.BatID ? this.$fun.getUserID() : 0,

      };

      const itemBankAccount = this.bankAccountDetail.find(element => element.TypePay === this.item.TypePay && element.SecStatus ==1 && this.bankAccountDetail.indexOf(element) != this.editedIndex)

      if(itemBankAccount){
        let indexItemBankAccount = this.bankAccountDetail.indexOf(itemBankAccount); 

        if(itemBankAccount.BatID > 0){
          itemBankAccount.SecStatus = 0;
          itemBankAccount.BatIsDefault = 2;
          //Object.assign(this.bankAccountDetail[indexItemBankAccount], itemBankAccount);
        }else{               
          this.bankAccountDetail.splice(indexItemBankAccount, 1);                 
        }
      }

      if (this.editedIndex > -1) {
        Object.assign(this.bankAccountDetail[this.editedIndex], bankAccount);
        this.editedIndex = -1;
      } else {       
        this.bankAccountDetail.push(bankAccount);        
      }
      //console.log("Itemss",this.bankAccountDetail);
      this.title = -1;  
      this.$refs.TypePayRef.focus();
      this.clearDetail();
    },

    clearDetail() {
      //console.log("Item default111", this.itemDefault);
      this.item = { ...this.itemDefault}

      this.NumCorrelative= null;
      // this.item = {
      //   TypePay: 1,
      //   TypeAccount: 2,
      //   TypeMoney: 1,
      //   TypeEntityBank: 2,
      //   BatAccountNumber: "",
      //   BatCCI: "",
      //   BatIsDefault: 2,
      // };
    
      this.$refs.BatAccountNumberRef.touched=false;
      this.$refs.BatCCIRef.touched=false;
      this.$refs.BatCCIRef.hasError=false;
      this.$refs.BatCCIRef.errorMessage="";
      //console.log("Item default2222", this.item);
      
    },

    close() {
      this.$refs.document.focus();
      this.$emit("toclose", 2);
      this.clear();
    },
    save() {
      //this.item.AccountNumber= this.item.AccountNumber;
    },
    getData() {
      if (this.validateDetail()) {
        return [
            ...this.bankAccountDetailDelete,
            ...this.bankAccountDetail            
          ];
      }
      else{ return false; }
    },

    validateDetail(){
      let valid = this.bankAccountDetail.length > 0;
      
      if(!valid){  this.$fun.alert("Al menos una Cuenta Bancaria es obligatorio", "warning"); return valid;}

      valid = this.bankAccountDetail.some(element => element.BatIsDefault == 1);

      if(!valid){  this.$fun.alert("Una Cuenta Bancaria Principal es obligatorio", "warning"); }

      return valid;
    },

    validateData() {
      let valid = true;
      //console.log("Data Bancarios", this.item);

      this.item.BatAccountNumber = this.item.BatAccountNumber.replace(/\s+/g, "");

      // if(this.bankAccountDetail.some(element => element.BatIsDefault === 1 && this.bankAccountDetail.indexOf(element) != this.editedIndex  && element.TypePay === this.item.TypePay )) {
      //   this.$fun.alert("Solo puede haber una Cuenta Bancaria Principal del tipo de Pago ->"+this.$refs.TypePayRef.items.find(
      //   (x) => x.DedValue === this.item.TypePay
      // ).DedDescription , "warning");
      //   valid = false;
      // }
      


      if (this.item.BatAccountNumber.length === 0 || this.item.BatAccountNumber.length < this.maxlength) {
        //this.maxlength =  this.item.TypeAccount == 4 ? 20 :  this.maxlength;
        let message ="El N° de Cuenta debe de tener  " + (this.maxlength)   + " dígitos"; 
        this.$fun.alert(message, "warning");
        valid = false;
      }

      if(this.item.BatCCI.length>0 && this.item.BatCCI.length < 20){
        this.$fun.alert("El CCI debe de tener  20 dígitos", "warning");
        valid = false;
      }

    
      return valid;
    },

  },
  watch: {
    'item.TypeEntityBank'() {
      // console.log("entidad111111", this.$refs.TypeEntityBankRef.items.find(
      //     (x) => x.DedValue === this.item.TypeEntityBank
      //   ));
      this.maxlength = parseInt(this.$refs.TypeEntityBankRef.items.find(
          (x) => x.DedValue === this.item.TypeEntityBank
        ).DedHelper);
    },
    'NumCorrelative'(){
      //console.log("entidad1111");
      if(this.NumCorrelative){
        _sWorkertService.BankAccountCorrelative(this.$fun.getUserID()).then(r=>{
          if (r.status == 200) {
            //console.log("NumCorrelative", r.data);
            this.item.BatAccountNumber= r.data;        
          }
        })
       
      }else{
        this.item.BatAccountNumber= "";        
      }
    }
  },
  created() {
    if (this.datawork) {
        this.initialize();
    } else {
        console.warn("datawork aún no está definido. Esperando su asignación.");
    }
  },
  computed: {
      
  }
};
</script>
