import Service from "../Service";
const resource = "HmnCreationPeriods/";

export default {
    save(cmp, requestID) {
        return Service.post(resource + "save", cmp, {
            params: { requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    list(type, year, requestID) {
        return Service.post(resource + "list", {}, {
            params: { type: type, year: year ,requestID: requestID },
        });
    },
};